import { checkScore, sumPenalties, sumPutts } from "../../scoreV2/scoring/module/scoreCalculation";
import { stablefordCalculation, system36Calculation } from "../../scoreV2/component/scoreInput/scoreCalculation";

export function gameModeSelection(scoreList, roundData, statList) {
    switch (roundData.gameMode) {
        case 'Stroke Play':
        case 'Match Play':
            return strokePlaySelected(scoreList, roundData, statList)

        case 'Stableford':
            return stablefordSelected(scoreList, roundData, statList)

        // case 'Match Play':
        //     return matchPlaySelected(scoreList, roundData, statList)

        default:
            console.warn('Incrroect game mode used "Stroke Play"');
            return strokePlaySelected(scoreList, roundData, statList)
    }
}

function strokePlaySelected(scoreList, roundData, statList) {
    switch (roundData.scoring) {
        case 'Gross':
        case 'Gross-WHS':
        case 'Gross-S36':
            return grossCalculation(scoreList, roundData, statList);

        case 'To Par':
        case 'To Par-WHS':
        case 'To Par-S36':
            return toparCalculation(scoreList, roundData, statList);

        default:
            console.warn('Incrroect scoring used "Gross"');
            return grossCalculation(scoreList, roundData, statList);
    }
}

function stablefordSelected(scoreList, roundData, statList) {
    switch (roundData.scoring) {
        case 'Gross':
            return grossStbCalculation(scoreList, roundData);

        case 'Gross-WHS':
            return grossStbCalculation(scoreList, roundData, statList);

        case 'Gross-S36':
            return grossStbCalculation(scoreList, roundData);

        default:
            console.warn('Incrroect scoring used "Gross"');
            return grossStbCalculation(scoreList, roundData)
    }
}

function matchPlaySelected(scoreList, roundData) {
    switch (roundData.scoring) {
        case 'Gross':
            return grossCalculation(scoreList, roundData)
        case 'To Par':
            return toparCalculation(scoreList, roundData)

        default:
            console.warn('Incrroect scoring used "Gross"');
            return grossCalculation(scoreList, roundData)
    }
}



function grossCalculation(scoreList, roundData, statList) {

    switch (roundData.handicap) {

        case 'scratch':
            return grossScratch(scoreList, roundData)

        case 'world_handicap_system':
        case 'whs':
            return grossWhs(scoreList, roundData, statList)

        case 's36':
        case 'system36':
            return grossS36(scoreList, roundData)

        default:
            console.warn('Incorrect handicap used "Scratch"');
            return grossScratch(scoreList)
    }
}

function grossStbCalculation(scoreList, roundData, statList) {

    switch (roundData.handicap) {

        case 'scratch':
            return grossStbScratch(scoreList, roundData)

        case 'world_handicap_system':
        case 'whs':
            return grossStbWhs(scoreList, roundData, statList)

        case 's36':
        case 'system36':
            return grossStbS36(scoreList, roundData)

        default:
            console.warn('Incorrect handicap used "Scratch"');
            return grossScratch(scoreList, roundData)
    }
}

function toparCalculation(scoreList, roundData, statList) {
    switch (roundData.handicap) {
        case 'scratch':
            return toparScratch(scoreList, roundData);

        case 'world_handicap_system':
        case 'whs':
            return toparWhs(scoreList, roundData, statList);

        case 's36':
        case 'system36':
            return toparS36(scoreList, roundData);

        default:
            console.warn('Incorrect handicap used "Scratch"');
            return toparScratch(scoreList, roundData);
    }
}


// gross ===========================================================================
function grossScratch(scoreList, roundData) {

    // Process each score to calculate sumScore
    const updatedScoreList = scoreList.map(score => {
        let sumScore = 0;

        // Filter keys matching "hole" pattern (e.g., hole1, hole2, ...)
        const holeFiltered = Object.keys(score).filter(key => /hole\d+/.test(key));

        // Extract the hole values
        const holeValues = holeFiltered.map(key => score[key]);

        // Check if all hole scores are "-"
        const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

        // If all scores are "-", set sumScore to "-"
        if (allScoresAreDash) {
            return { ...score, sumScore: '-' };
        }

        // Calculate the sum of valid numeric scores
        holeValues.forEach(hole => {
            const holescore = hole?.score;
            if (holescore) {
                const numHolescore = Number(holescore);
                if (!isNaN(numHolescore)) {
                    sumScore += numHolescore;
                }
            }
        });
        const sumPutt = sumPutts(score);
        const sumPenal = sumPenalties(score)

        // Return the updated score object
        return { ...score, sumScore, sumPutt, sumPenal };
    });

    return sortScoreList(updatedScoreList, roundData.gameMode);
}

function grossWhs(scoreList, roundData, statList) {

    const holeDet = roundData.courseHoleData.holeDetails;

    // handicap assignment
    const holeHandicapList = sortHoleByHandicap(statList, holeDet)

    // calculation with handicap
    const updatedScoreList = scoreList.map(score => {
        let sumScore = 0;

        const userId = score.userId;

        // Filter keys matching "hole" pattern (e.g., hole1, hole2, ...)
        const holeFiltered = Object.keys(score).filter(key => /hole\d+/.test(key));

        // Extract the hole values
        const holeValues = holeFiltered.map(key => score[key]);

        // Check if all hole scores are "-"
        const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

        // If all scores are "-", set sumScore to "-"
        if (allScoresAreDash) {
            return { ...score, sumScore: '-' };
        }

        // Calculate the sum of valid numeric scores
        holeValues.forEach(hole => {
            const holescore = hole?.score;
            const foundHole = holeHandicapList
                .find(item => item.userId === userId)
                ?.sortedCourse.find(course => course.holeNumber === Number(hole.holeNumber));

            const foundPlayerHandicap = holeHandicapList.find(item => item.userId === score.userId);
            // console.log(foundPlayerHandicap);

            if (holescore && foundHole && foundPlayerHandicap) {
                const score = Number(holescore);
                const handicap = foundHole.handicap;
                const isNegative = foundPlayerHandicap.isNegative;

                if (!isNaN(score)) {
                    let adjustScore = 0
                    if (isNegative) {
                        adjustScore = score + handicap;
                    } else {
                        adjustScore = score - handicap;
                    }
                    // console.log(adjustScore);

                    sumScore += adjustScore;
                }
            }
        });

        const sumPutt = sumPutts(score);
        const sumPenal = sumPenalties(score)

        // Return the updated score object
        return { ...score, sumScore, sumPutt, sumPenal };
    });

    return sortScoreList(updatedScoreList, roundData.gameMode);
}

function grossS36(scoreList, roundData) {

    const holeDet = roundData.courseHoleData.holeDetails;

    // Process each score to calculate sumScore
    const updatedScoreList = scoreList.map(score => {
        let sumScore = 0;

        // Filter keys matching "hole" pattern (e.g., hole1, hole2, ...)
        const holeFiltered = Object.keys(score).filter(key => /hole\d+/.test(key));

        // Extract the hole values
        const holeValues = holeFiltered.map(key => score[key]);

        // Check if all hole scores are "-"
        const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

        // If all scores are "-", set sumScore to "-"
        if (allScoresAreDash) {
            return { ...score, sumScore: '-' };
        }

        // calculate total s36 score
        // let s36Sum = 0;
        // if (checkScore(score)) {

        //     holeValues.forEach(hole => {
        //         const playerHoleNumber = Number(hole?.holeNumber);
        //         const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);
        //         if (holeDetail) {
        //             const par = Number(holeDetail?.par);
        //             const score = Number(hole?.score);
        //             // Only compute if both score and par exist and score is a valid number
        //             if (!isNaN(score) && !isNaN(par)) {
        //                 const s36Score = system36Calculation(score, par);
        //                 s36Sum += s36Score
        //             }
        //         }
        //     });

        //     if (holeDet.length > 9) {
        //         s36Sum = 36 - s36Sum;
        //     } else {
        //         s36Sum = 18 - s36Sum;
        //     }
        // }

        // Calculate the sum of valid numeric scores
        holeValues.forEach((holeValue, index) => {
            const playerHoleNumber = Number(holeValue?.holeNumber);
            const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

            if (holeDetail) {
                const par = Number(holeDetail?.par);
                const score = Number(holeValue?.score);

                // Only compute if both score and par exist and score is a valid number
                if (!isNaN(score) && !isNaN(par)) {
                    let adjustScore = 0;
                    const s36Score = system36Calculation(score, par);
                    adjustScore = (36 / 18) - s36Score;
                    adjustScore = score - adjustScore
                    // Calculate the score relative to par and add it to the sumScore
                    sumScore += adjustScore;
                }
            }
        });
        const sumPutt = sumPutts(score);
        const sumPenal = sumPenalties(score)

        // Return the updated score object
        return { ...score, sumScore: sumScore, sumPutt, sumPenal };
    });

    return sortScoreList(updatedScoreList, roundData.gameMode);
}

// to par =========================================================================
function toparScratch(scoreList, roundData) {
    if (!scoreList || !roundData.courseHoleData) return;

    const holeDet = roundData.courseHoleData.holeDetails;

    // Process each score in the scoreList
    const updatedScoreList = scoreList.map(score => {
        let sumScore = 0;

        // Filter keys to find holes
        const playerHoleFiltered = Object.keys(score).filter(key => /hole\d+/.test(key));

        // Extract hole values
        const holeValues = playerHoleFiltered.map(key => score[key]);

        // Check if all hole scores are "-"
        const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

        if (allScoresAreDash) {
            // If all scores are "-", set toPar as "-" and return updated object
            return { ...score, sumScore: '-' };
        }

        // Calculate total score relative to par
        holeValues.forEach(holeValue => {
            const playerHoleNumber = Number(holeValue?.holeNumber);
            const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

            if (holeDetail) {
                const par = Number(holeDetail?.par);
                const score = Number(holeValue?.score);

                // Only compute if both score and par exist and score is a valid number
                if (!isNaN(score) && !isNaN(par)) {
                    // Calculate the score relative to par and add to sumScore
                    sumScore += (score - par);
                }
            }
        });

        const sumPutt = sumPutts(score);
        const sumPenal = sumPenalties(score)

        // Return the updated score object
        return { ...score, sumScore, sumPutt, sumPenal };
    });


    return sortScoreList(updatedScoreList, roundData.gameMode);
}

function toparWhs(scoreList, roundData, statList) {
    if (!scoreList || !roundData.courseHoleData) return;

    const holeDet = roundData.courseHoleData.holeDetails;

    // handicap assignment
    const holeHandicapList = sortHoleByHandicap(statList, holeDet)

    // Process each score in the scoreList
    const updatedScoreList = scoreList.map(score => {
        let sumScore = 0;
        const userId = score.userId;
        // Filter keys to find holes
        const playerHoleFiltered = Object.keys(score).filter(key => /hole\d+/.test(key));

        // Extract hole values
        const holeValues = playerHoleFiltered.map(key => score[key]);

        // Check if all hole scores are "-"
        const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

        if (allScoresAreDash) {
            // If all scores are "-", set toPar as "-" and return updated object
            return { ...score, sumScore: '-' };
        }

        // Calculate total score relative to par
        holeValues.forEach(holeValue => {
            const playerHoleNumber = Number(holeValue?.holeNumber);
            const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

            if (holeDetail) {
                const foundHole = holeHandicapList
                    .find(item => item.userId === userId)
                    ?.sortedCourse.find(course => course.holeNumber === Number(holeDetail.holeNumber));

                const foundPlayerHandicap = holeHandicapList.find(item => item.userId === score.userId);

                const par = Number(holeDetail?.par);
                const scoreNum = Number(holeValue?.score);

                // Only compute if both score and par exist and score is a valid number
                if (!isNaN(scoreNum) && !isNaN(par) && foundHole && foundPlayerHandicap) {
                    const handicap = foundHole.handicap;
                    const isNegative = foundPlayerHandicap.isNegative;

                    // Calculate the score relative to par and add to sumScore
                    let adjustScore = 0;
                    if (isNegative) {
                        adjustScore = (scoreNum + handicap) - par;
                    } else {
                        adjustScore = (scoreNum - handicap) - par;
                    }
                    sumScore += adjustScore
                }
            }
        });

        const sumPutt = sumPutts(score);
        const sumPenal = sumPenalties(score)

        // Return the updated score object
        return { ...score, sumScore, sumPutt, sumPenal };
    });


    return sortScoreList(updatedScoreList, roundData.gameMode);
}

function toparS36(scoreList, roundData) {
    const holeDet = roundData.courseHoleData.holeDetails;

    // Process each score to calculate sumScore
    const updatedScoreList = scoreList.map(score => {
        let sumScore = 0;

        // Filter keys matching "hole" pattern (e.g., hole1, hole2, ...)
        const holeFiltered = Object.keys(score).filter(key => /hole\d+/.test(key));

        // Extract the hole values
        const holeValues = holeFiltered.map(key => score[key]);

        // Check if all hole scores are "-"
        const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

        // If all scores are "-", set sumScore to "-"
        if (allScoresAreDash) {
            return { ...score, sumScore: '-' };
        }

        // calculate total s36 score
        // let s36Sum = 0;
        // if (checkScore(score)) {

        //     holeValues.forEach(hole => {
        //         const playerHoleNumber = Number(hole?.holeNumber);
        //         const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);
        //         if (holeDetail) {
        //             const par = Number(holeDetail?.par);
        //             const score = Number(hole?.score);
        //             // Only compute if both score and par exist and score is a valid number
        //             if (!isNaN(score) && !isNaN(par)) {
        //                 const s36Score = system36Calculation(score, par);
        //                 s36Sum += s36Score
        //             }
        //         }
        //     });

        //     if (holeDet.length > 9) {
        //         s36Sum = 36 - s36Sum;
        //     } else {
        //         s36Sum = 18 - s36Sum;
        //     }
        // }

        // Calculate total score relative to par
        holeValues.forEach(holeValue => {
            const playerHoleNumber = Number(holeValue?.holeNumber);
            const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

            if (holeDetail) {
                const par = Number(holeDetail?.par);
                const score = Number(holeValue?.score);

                // Only compute if both score and par exist and score is a valid number
                if (!isNaN(score) && !isNaN(par)) {
                    // Calculate the score relative to par and add to sumScore
                    let adjustScore = 0;
                    const s36Score = system36Calculation(score, par);
                    adjustScore = (36 / 18) - s36Score;
                    adjustScore = score - adjustScore
                    const s36ToPar = adjustScore - par;
                    sumScore += s36ToPar
                }
            }
        });
        const sumPutt = sumPutts(score);
        const sumPenal = sumPenalties(score)

        // Return the updated score object
        return { ...score, sumScore: sumScore, sumPutt, sumPenal };
    });

    return sortScoreList(updatedScoreList, roundData.gameMode);
}

// stableford ===============================================================
function grossStbScratch(scoreList, roundData) {

    const holeDet = roundData.courseHoleData.holeDetails;

    const updatedScoreList = scoreList.map(score => {
        let sumScore = 0;

        const playerHoleFiltered = Object.keys(score).filter(key => /hole\d+/.test(key));
        const holeValues = playerHoleFiltered.map(key => score[key]);

        // Check if all hole scores are "-"
        const allScoresAreDash = holeValues.every(hole => hole?.score === "-");
        if (allScoresAreDash) {
            return { ...score, sumScore: '-' };
        }

        // Calculate the total Stableford points
        holeValues.forEach((holeValue) => {
            const playerHoleNumber = Number(holeValue?.holeNumber);
            const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

            if (holeDetail) {
                const par = Number(holeDetail?.par);
                const score = Number(holeValue?.score);

                // Only compute if both score and par exist and score is a valid number
                if (!isNaN(score) && !isNaN(par)) {
                    // Calculate the score difference
                    const scoreDifference = score - par;
                    // Get Stableford points based on the score difference
                    const stablefordPoints = stablefordCalculation(scoreDifference);
                    // Accumulate Stableford points
                    sumScore += stablefordPoints;
                }
            }
        });
        const sumPutt = sumPutts(score);
        const sumPenal = sumPenalties(score)

        return { ...score, sumScore, sumPutt, sumPenal };
    })
    return sortScoreList(updatedScoreList, roundData.gameMode)
}

function grossStbWhs(scoreList, roundData, statList) {
    const holeDet = roundData.courseHoleData.holeDetails;
    const holeHandicapList = sortHoleByHandicap(statList, holeDet);

    const updatedScoreList = scoreList.map(score => {
        let sumScore = 0;
        const userId = score.userId;

        const playerHoleFiltered = Object.keys(score).filter(key => /hole\d+/.test(key));
        const holeValues = playerHoleFiltered.map(key => score[key]);

        // Check if all hole scores are "-"
        const allScoresAreDash = holeValues.every(hole => hole?.score === "-");
        if (allScoresAreDash) {
            return { ...score, sumScore: '-' };
        }

        // Calculate the total Stableford points
        holeValues.forEach((holeValue) => {
            const playerHoleNumber = Number(holeValue?.holeNumber);
            const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

            if (holeDetail) {
                const foundHole = holeHandicapList
                    .find(item => item.userId === userId)
                    ?.sortedCourse.find(course => course.holeNumber === Number(holeDetail.holeNumber));

                const foundPlayerHandicap = holeHandicapList.find(item => item.userId === score.userId);

                const par = Number(holeDetail?.par);
                const scoreNum = Number(holeValue?.score);

                // Only compute if both score and par exist and score is a valid number
                if (!isNaN(scoreNum) && !isNaN(par) && foundHole && foundPlayerHandicap) {
                    const handicap = foundHole.handicap;
                    const isNegative = foundPlayerHandicap.isNegative;

                    // Calculate the score difference
                    let scoreDifference = 0;
                    if (isNegative) {
                        scoreDifference = (scoreNum + handicap) - par;
                    } else {
                        scoreDifference = (scoreNum - handicap) - par;
                    }
                    // Get Stableford points based on the score difference
                    const stablefordPoints = stablefordCalculation(scoreDifference);
                    // Accumulate Stableford points
                    sumScore += stablefordPoints;
                }
            }
        });
        const sumPutt = sumPutts(score);
        const sumPenal = sumPenalties(score)

        return { ...score, sumScore, sumPutt, sumPenal };
    })
    return sortScoreList(updatedScoreList, roundData.gameMode)
}

function grossStbS36(scoreList, roundData) {
    const holeDet = roundData.courseHoleData.holeDetails;

    const updatedScoreList = scoreList.map(score => {
        let sumScore = 0;

        const playerHoleFiltered = Object.keys(score).filter(key => /hole\d+/.test(key));
        const holeValues = playerHoleFiltered.map(key => score[key]);

        // Check if all hole scores are "-"
        const allScoresAreDash = holeValues.every(hole => hole?.score === "-");
        if (allScoresAreDash) {
            return { ...score, sumScore: '-' };
        }

        // calculate total s36 score
        let s36Sum = 0;
        if (checkScore(score)) {

            holeValues.forEach(hole => {
                const playerHoleNumber = Number(hole?.holeNumber);
                const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);
                if (holeDetail) {
                    const par = Number(holeDetail?.par);
                    const score = Number(hole?.score);
                    // Only compute if both score and par exist and score is a valid number
                    if (!isNaN(score) && !isNaN(par)) {
                        const s36Score = system36Calculation(score, par);
                        s36Sum += s36Score
                    }
                }
            });

            if (holeDet.length > 9) {
                s36Sum = 36 - s36Sum;
            } else {
                s36Sum = 18 - s36Sum;
            }
        }

        // Calculate the total Stableford points
        holeValues.forEach((holeValue) => {
            const playerHoleNumber = Number(holeValue?.holeNumber);
            const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

            if (holeDetail) {
                const par = Number(holeDetail?.par);
                const score = Number(holeValue?.score);

                // Only compute if both score and par exist and score is a valid number
                if (!isNaN(score) && !isNaN(par)) {

                    let adjustScore = 0;
                    const s36Score = system36Calculation(score, par);
                    adjustScore = (36 / 18) - s36Score;
                    adjustScore = score - adjustScore
                    const scoreDifference = adjustScore - par;

                    // Get Stableford points based on the score difference
                    const stablefordPoints = stablefordCalculation(scoreDifference);
                    // Accumulate Stableford points
                    sumScore += stablefordPoints;
                }
            }
        });
        const sumPutt = sumPutts(score);
        const sumPenal = sumPenalties(score)

        return {
            ...score, sumScore: sumScore - s36Sum, sumPutt, sumPenal
        };
    })
    return sortScoreList(updatedScoreList, roundData.gameMode)
}
// stableford ===============================================================

function sortScoreList(scoreList, gameMode) {
    return scoreList.sort((a, b) => {
        const scoreA = a.sumScore;
        const scoreB = b.sumScore;

        // Place "-" (non-numeric) at the end
        if (scoreA === "-") return 1;
        if (scoreB === "-") return -1;

        // Compare numeric scores
        const comparison = scoreA - scoreB;

        // Reverse order for Stableford (higher score wins)
        return gameMode === "Stableford" ? -comparison : comparison;
    });
}




// Function to handle assigning handicap strokes
const assignHandicap = (course, handicap, totalHoles) => {
    const absHandicap = Math.round(handicap);

    // Initialize the `handicap` field for each hole to 0
    course.forEach(hole => hole.handicap = 0);

    for (let i = 0; i < absHandicap; i++) {
        const modded = i % totalHoles; // Cycle through holes
        if (course[modded]) { // Ensure the index exists
            // Prevent assigning more than 3 strokes to a hole
            if (course[modded].handicap < 3) {
                course[modded].handicap += 1;
            }
        }
    }

    return course;
};

function sortHoleByHandicap(statList, holeDet) {
    const holeHandicapList = []
    for (const stat of statList) {
        let sortedCourse = null
        let isNegative = false;

        if (stat?.handicap?.startsWith('+')) {
            // negative index
            isNegative = true
            sortedCourse = JSON.parse(JSON.stringify(holeDet)).sort((a, b) => b.index - a.index);
        } else {
            // positive index
            isNegative = false
            sortedCourse = JSON.parse(JSON.stringify(holeDet)).sort((a, b) => a.index - b.index);
        }
        const handicapNum = Math.round(stat?.handicap || 0);

        sortedCourse = assignHandicap(sortedCourse, handicapNum, holeDet.length)
        holeHandicapList.push({ ...stat, sortedCourse, isNegative });
    }
    return holeHandicapList
}

