import React, { useRef, useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import CourseDetailCourseHoleImageModal from "./CourseDetailCourseHoleImageModal";

function CourseDetailCourseHoleDetail({
  selectCourseHole,
  setSelectCourseHole,
}) {
  const [showModal, setShowModal] = useState(false);
  const [selectedHole, setSelectedHole] = useState(null);

  const tableContainerStyle = {
    maxWidth: "100%",
    // margin: "20px auto",
    borderRadius: "5px",
    overflow: "hidden",
    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    display: "grid",
    gridTemplateColumns: "repeat(8, 1fr)",
    gap: "5px",
    // backgroundColor: "#f7f8fa",
  };

  const headerStyle = {
    display: "contents",
    // backgroundColor: "#f0f0f0",
    fontWeight: "bold",
    color: "#333",
  };

  const cellStyle = {
    padding: "12px",
    textAlign: "center",
    borderBottom: "1px solid #ddd",
    fontSize: "14px",
  };

  const colorStyles = {
    green: { backgroundColor: "#75C36F", borderRadius: "6px" },
    greenLight: { backgroundColor: "#E9F6E9", borderRadius: "6px" },
    yellow: { backgroundColor: "#EAE400", borderRadius: "6px" },
    yellowLight: { backgroundColor: "#FFFED5", borderRadius: "6px" },
    red: { backgroundColor: "#FF4343", borderRadius: "6px" },
    redLight: { backgroundColor: "#FFD5D5", borderRadius: "6px" },
    blue: { backgroundColor: "#566AFC", borderRadius: "6px" },
    blueLight: { backgroundColor: "#CAD0FE", borderRadius: "6px" },
    white: { backgroundColor: "#ffffff", borderRadius: "6px" },
    black: { backgroundColor: "#000000", color: "#fff", borderRadius: "6px" },
  };

  // แปลงข้อมูลจาก selectCourseHole
  const transformedData =
    selectCourseHole?.holeDetails.map((hole) => ({
      no: hole.holeNumber,
      par: hole.par,
      index: hole.index,
      yellow: hole.yellowTee,
      red: hole.redTee,
      blue: hole.blueTee,
      white: hole.whiteTee,
      black: hole.blackTee,
    })) || [];

  // คำนวณจำนวน Par รวม
  const totalPar = transformedData.reduce((sum, row) => sum + row.par, 0);

  const handleCourseImage = (row) => {
    setSelectedHole(row);
    setShowModal(true);
  };

  return (
    <>
      <div className="card d-block border-0 rounded-3 overflow-hidden p-2 shadow-xss">
        <div
          className="card d-block border-0 rounded-3 overflow-hidden p-2 shadow-xss"
          style={{ backgroundColor: "#F5F5F5" }}
        >
          <div className="row">
            <div className="col-xl-12 mb-3">
              <div className="card shadow-xss w-100 d-block d-flex border-0 p-3">
                <div className="d-flex w-100 flex-column flex-md-row ">
                  <div className="d-flex align-items-center">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setSelectCourseHole(null)}
                      className="ms-2 rounded-3 d-flex align-items-center justify-content-center"
                    >
                      <i className="ti-arrow-left font-sm"></i>
                    </div>
                    <h4 className="fw-600 ms-3 mt-0 m-0">
                      {selectCourseHole?.courseHoleName}
                    </h4>
                  </div>

                  <div className="d-flex align-items-center ms-md-auto mt-2 mt-md-0 w-md-auto">
                    <h6 className="fw-600 text-muted m-0 ms-3 mt-0">
                      {selectCourseHole?.numberOfHole == "9holes" ? "9" : "18"}{" "}
                      Holes
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-12">
              <div style={{ ...tableContainerStyle, overflowX: "auto", whiteSpace: "nowrap" }}>
                <div style={headerStyle}>
                  <div style={cellStyle}>NO.</div>
                  <div style={{ ...cellStyle, ...colorStyles.green }}>
                    <h4 className="fw-600 m-0">Par</h4>
                    <h6 className="m-0">{totalPar}</h6>
                  </div>
                  <div style={{ ...cellStyle, ...colorStyles.green }}>
                    <h4 className="fw-600 m-0">Index</h4>
                  </div>
                  <div style={{ ...cellStyle, ...colorStyles.yellow }}>
                    <h4 className="fw-600 m-0">Yellow</h4>
                    <h6 className="m-0">
                      {selectCourseHole?.yellowTeeDetails?.courseRating} /{" "}
                      {selectCourseHole?.yellowTeeDetails?.slopeRating}
                    </h6>
                  </div>
                  <div style={{ ...cellStyle, ...colorStyles.red }}>
                    <h4 className="fw-600 m-0">Red</h4>
                    <h6 className="m-0">
                      {selectCourseHole?.redTeeDetails?.courseRating} /{" "}
                      {selectCourseHole?.redTeeDetails?.slopeRating}
                    </h6>
                  </div>
                  <div style={{ ...cellStyle, ...colorStyles.blue }}>
                    <h4 className="fw-600 m-0">Blue</h4>
                    <h6 className="m-0">
                      {selectCourseHole?.blueTeeDetails?.courseRating} /{" "}
                      {selectCourseHole?.blueTeeDetails?.slopeRating}
                    </h6>
                  </div>
                  <div style={{ ...cellStyle, ...colorStyles.white }}>
                    <h4 className="fw-600 m-0">White</h4>
                    <h6 className="m-0">
                      {selectCourseHole?.whiteTeeDetails?.courseRating} /{" "}
                      {selectCourseHole?.whiteTeeDetails?.slopeRating}
                    </h6>
                  </div>
                  <div style={{ ...cellStyle, ...colorStyles.black }}>
                    <h4 className="fw-600 m-0" style={colorStyles.black}>
                      Black
                    </h4>
                    <h6 className="m-0" style={colorStyles.black}>
                      {selectCourseHole?.blackTeeDetails?.courseRating} /{" "}
                      {selectCourseHole?.blackTeeDetails?.slopeRating}
                    </h6>
                  </div>
                </div>
                {transformedData.map((row, index) => (
                  <div
                    key={index}
                    style={{ display: "contents", cursor: "pointer" }}
                    onClick={() => handleCourseImage(row)}
                  >
                    <div style={cellStyle}>
                      <h4 className="fw-600 m-0">{row.no}</h4>
                    </div>
                    <div style={{ ...cellStyle, ...colorStyles.greenLight }}>
                      <h4 className="fw-600 m-0">{row.par}</h4>
                    </div>
                    <div style={{ ...cellStyle, ...colorStyles.greenLight }}>
                      <h4 className="fw-600 m-0">{row.index}</h4>
                    </div>
                    <div style={{ ...cellStyle, ...colorStyles.yellowLight }}>
                      <h4 className="fw-600 m-0">{row.yellow}</h4>
                    </div>
                    <div style={{ ...cellStyle, ...colorStyles.redLight }}>
                      <h4 className="fw-600 m-0">{row.red}</h4>
                    </div>
                    <div style={{ ...cellStyle, ...colorStyles.blueLight }}>
                      <h4 className="fw-600 m-0">{row.blue}</h4>
                    </div>
                    <div style={{ ...cellStyle, ...colorStyles.white }}>
                      <h4 className="fw-600 m-0">{row.white}</h4>
                    </div>
                    <div style={{ ...cellStyle, ...colorStyles.black }}>
                      <h4 className="fw-600 m-0" style={colorStyles.black}>
                        {row.black}
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <CourseDetailCourseHoleImageModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedHole={selectedHole}
        selectCourseHole={selectCourseHole}
      ></CourseDetailCourseHoleImageModal>
    </>
  );
}

export default CourseDetailCourseHoleDetail;
