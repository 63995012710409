import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../../../../components/firebase/FirebaseConfig";
import { epochToDateTime } from "../../../component/wizard/module/covertTime";

export const getDataStartRound = async (
  userData,
  setListRoundTest,
  setLoading
) => {
  if (!userData) throw new Error("userData is required");

  const roundActivityQuery = query(
    collection(firestore, "roundActivity"),
    where("userList", "array-contains", userData.uid)
  );

  onSnapshot(roundActivityQuery, (roundActivitySnapshot) => {
    const roundId = roundActivitySnapshot.docs.map((doc) => doc.data().roundId);

    if (roundId.length > 0) {
      const chunkSize = 30;
      const chunks = [];
      for (let i = 0; i < roundId.length; i += chunkSize) {
        chunks.push(roundId.slice(i, i + chunkSize));
      }

      chunks.forEach((chunk) => {
        const roundQuery = query(
          collection(firestore, "round"),
          where("roundId", "in", chunk),
          where("teeTime", "==", "now")
        );

        onSnapshot(roundQuery, async (roundSnapshot) => {
          const roundData = roundSnapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((round) => round.status !== "deleted"); // Filter out "deleted" status

          const getDataCourseRound = await addCourseAndHolesData(roundData);
          const getDataUser = await fetchUserDetails(getDataCourseRound);

          const filteredRoundIsPlaying = getDataUser.filter((round) =>
            round.userData.some(
              (user) => user.uid === userData.uid && user.isJoin === true
            )
          );

          const checkRoundStart = await checkPlayingRounds(
            filteredRoundIsPlaying
          );

          const filteredRoundStart = checkRoundStart.filter((user) => {
            const isMatch = user.userList.some((userItem) => {
              const isPlayerMatch =
                userItem.player?.uid === userData.uid &&
                (userItem.player?.status === "playing" ||
                  userItem.player?.status === null);

              const isCaddieMatch =
                userItem.caddie?.uid === userData.uid &&
                (userItem.caddie?.status === "playing" ||
                  userItem.caddie?.status === null);

              return isPlayerMatch || isCaddieMatch;
            });

            return isMatch;
          });

          // Merge new data into listRoundTest
          setListRoundTest((prevList) => {
            const newList = [...prevList, ...filteredRoundStart];
            // Remove duplicate rounds based on their id
            return newList.filter(
              (item, index, self) =>
                index === self.findIndex((round) => round.id === item.id)
            );
          });

          setLoading(false);
        });
      });
    } else {
      setListRoundTest([]);
      setLoading(false);
    }
  });
};

const checkPlayingRounds = async (filteredRounds) => {
  const promises = filteredRounds.map(async (round) => {
    try {
      // อัปเดต userList โดยเพิ่ม status ของ player และ caddie
      const updatedUserList = await Promise.all(
        round.userList.map(async (user) => {
          const queries = [];
          let playerStatus = null;
          let caddieStatus = null;

          // สร้าง query สำหรับ caddie ถ้ามี caddieId
          if (user.caddie?.uid) {
            const caddieQuery = query(
              collection(firestore, "scoreCards"),
              where("caddieId", "==", user.caddie.uid),
              where("roundId", "==", round.roundId)
            );
            const caddieSnapshot = await getDocs(caddieQuery);
            caddieStatus =
              caddieSnapshot.docs.length > 0
                ? caddieSnapshot.docs[0].data().status
                : null;
          }

          // สร้าง query สำหรับ player ถ้ามี userId
          if (user.player?.uid) {
            const playerQuery = query(
              collection(firestore, "scoreCards"),
              where("userId", "==", user.player.uid),
              where("roundId", "==", round.roundId)
            );
            const playerSnapshot = await getDocs(playerQuery);
            playerStatus =
              playerSnapshot.docs.length > 0
                ? playerSnapshot.docs[0].data().status
                : null;
          }

          // ถ้าไม่มี status ของ player แต่มี caddieStatus ให้ใช้ caddieStatus
          if (playerStatus === null && caddieStatus !== null) {
            playerStatus = caddieStatus;
          }

          // อัปเดตข้อมูล user
          const updatedUser = {
            ...user,
            caddie: user.caddie
              ? {
                  ...user.caddie,
                  status: caddieStatus,
                }
              : null,
            player: user.player
              ? {
                  ...user.player,
                  status: playerStatus,
                }
              : null,
          };

          return updatedUser;
        })
      );

      // อัปเดต userList ใน round
      return { ...round, userList: updatedUserList };
    } catch (error) {
      console.error(`Error checking round ${round.roundId}:`, error);
      return round; // ส่ง round เดิมกลับถ้าเกิดข้อผิดพลาด
    }
  });

  const results = await Promise.all(promises);
  return results;
};

const fetchUserDetails = async (checkFinishedRound) => {
  const promises = checkFinishedRound.map(async (round) => {
    const userDetails = [];

    round.userList.forEach((slot) => {
      if (slot?.player?.uid) {
        userDetails.push({
          uid: slot.player.uid,
          isCreator: slot.player.isCreator || false,
          isJoin: slot.player.isJoin || false,
        });
      }
      if (slot?.caddie?.uid) {
        userDetails.push({
          uid: slot.caddie.uid,
          isCreator: slot.caddie.isCreator || false,
          isJoin: slot.caddie.isJoin || false,
        });
      }
    });

    // Remove duplicates based on UID
    const uniqueUserDetails = [
      ...new Map(userDetails.map((user) => [user.uid, user])).values(),
    ];

    const userDataPromises = uniqueUserDetails.map(async (userDetail) => {
      const userQuery = query(
        collection(firestore, "users"),
        where("uid", "==", userDetail.uid)
      );

      const userSnapshot = await getDocs(userQuery);

      if (!userSnapshot.empty) {
        const user = userSnapshot.docs[0].data();
        // Merge both isCreator, isJoin and the user data
        return { ...user, ...userDetail };
      }
      return null;
    });

    const userData = (await Promise.all(userDataPromises)).filter(
      (user) => user !== null
    );

    return { ...round, userData };
  });

  const roundsWithUserData = await Promise.all(promises);
  return roundsWithUserData;
};

const addCourseAndHolesData = async (rounds) => {
  const promises = rounds.map(async (round) => {
    let holesCourse = "";

    if (round?.holesCourse) {
      const courseHolesRef = doc(firestore, "courseHoles", round.holesCourse);
      const courseHolesSnapshot = await getDoc(courseHolesRef);
      holesCourse = courseHolesSnapshot.exists()
        ? courseHolesSnapshot.data().courseHoleName
        : "";
    }

    const courseSnapshot = await getDocs(
      query(collection(firestore, "courses"), where("id", "==", round.course))
    );

    const courseData = courseSnapshot.empty
      ? null
      : courseSnapshot.docs[0].data();

    const { time, day, month } = epochToDateTime(round.scheduleTime);

    return {
      ...round,
      holesCourse,
      courseInfo: courseData,
      time,
      day,
      month,
    };
  });

  return Promise.all(promises);
};
