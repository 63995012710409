import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../../../../components/firebase/FirebaseConfig";
import { epochToDateTime } from "../../../component/wizard/module/covertTime";

export const getDataUpcomingRound = async (
  userData,
  setListRoundTest,
  setLoading
) => {
  if (!userData) throw new Error("userData is required");

  const roundActivityQuery = query(
    collection(firestore, "roundActivity"),
    where("userList", "array-contains", userData.uid)
  );

  onSnapshot(roundActivityQuery, (roundActivitySnapshot) => {
    const roundId = roundActivitySnapshot.docs.map((doc) => doc.data().roundId);

    if (roundId.length > 0) {
      // Divide roundId into chunks
      const chunkSize = 30;
      const chunks = [];
      for (let i = 0; i < roundId.length; i += chunkSize) {
        chunks.push(roundId.slice(i, i + chunkSize));
      }

      // Query for each chunk
      const promises = chunks.map((chunk) => {
        const roundQuery = query(
          collection(firestore, "round"),
          where("roundId", "in", chunk),
          where("teeTime", "==", "schedule")
        );

        return new Promise((resolve) => {
          onSnapshot(roundQuery, (roundSnapshot) => {
            const roundData = roundSnapshot.docs
              .map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
              .filter((round) => round.status !== "deleted"); // Filter out "deleted" status
            resolve(roundData);
          });
        });
      });

      // Gather all results
      Promise.all(promises).then(async (results) => {
        const allRounds = results.flat(); // Flatten all chunk results
        const roundsWithDetails = await addCourseAndHolesData(allRounds);
        const roundIsPlaying = await fetchUserDetails(roundsWithDetails);
        const filteredRoundIsPlaying = roundIsPlaying.filter((round) =>
          round.userData.some(
            (user) => user.uid === userData.uid && user.isJoin === true
          )
        );
        const filteredRound = filteredRoundIsPlaying.sort(
          (a, b) => new Date(b.scheduleTime) - new Date(a.scheduleTime)
        );
        setListRoundTest(filteredRound);
        setLoading(false);
      });
    } else {
      setListRoundTest([]); // If no data exists
      setLoading(false);
    }
  });
};

const fetchUserDetails = async (checkFinishedRound) => {
  const promises = checkFinishedRound.map(async (round) => {
    const userDetails = [];

    round.userList.forEach((slot) => {
      if (slot?.player?.uid) {
        userDetails.push({
          uid: slot.player.uid,
          isCreator: slot.player.isCreator || false,
          isJoin: slot.player.isJoin || false,
        });
      }
      if (slot?.caddie?.uid) {
        userDetails.push({
          uid: slot.caddie.uid,
          isCreator: slot.caddie.isCreator || false,
          isJoin: slot.caddie.isJoin || false,
        });
      }
    });

    // Remove duplicates based on UID
    const uniqueUserDetails = [
      ...new Map(userDetails.map((user) => [user.uid, user])).values(),
    ];

    const userDataPromises = uniqueUserDetails.map(async (userDetail) => {
      const userQuery = query(
        collection(firestore, "users"),
        where("uid", "==", userDetail.uid)
      );

      const userSnapshot = await getDocs(userQuery);

      if (!userSnapshot.empty) {
        const user = userSnapshot.docs[0].data();
        // Merge both isCreator, isJoin and the user data
        return { ...user, ...userDetail };
      }
      return null;
    });

    const userData = (await Promise.all(userDataPromises)).filter(
      (user) => user !== null
    );

    return { ...round, userData };
  });

  const roundsWithUserData = await Promise.all(promises);
  return roundsWithUserData;
};

const addCourseAndHolesData = async (rounds) => {
  const promises = rounds.map(async (round) => {
    let holesCourse = "";

    if (round?.holesCourse) {
      const courseHolesRef = doc(firestore, "courseHoles", round.holesCourse);
      const courseHolesSnapshot = await getDoc(courseHolesRef);
      holesCourse = courseHolesSnapshot.exists()
        ? courseHolesSnapshot.data().courseHoleName
        : "";
    }

    const courseSnapshot = await getDocs(
      query(collection(firestore, "courses"), where("id", "==", round.course))
    );

    const courseData = courseSnapshot.empty
      ? null
      : courseSnapshot.docs[0].data();

    const { time, day, month } = epochToDateTime(round.scheduleTime);

    return {
      ...round,
      holesCourse,
      courseInfo: courseData,
      time,
      day,
      month,
    };
  });

  return Promise.all(promises);
};
