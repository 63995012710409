import React, { useEffect, useState, useRef } from "react";
import Header from "../../../components/Header";
import Leftnav from "../../../components/Leftnav";
import Rightchat from "../../../components/Rightchat";
import Appfooter from "../../../components/Appfooter";
import Popupchat from "../../../components/Popupchat";
import CreateRound from "../component/CreateRoundCard";
import RoundsCount from "./RoundsCount";
import RoundFinished from "./finishedround/RoundFinished";
// import RoundUpcoming from "./upcominground/RoundUpcoming";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import {
  collection,
  query,
  onSnapshot,
  where,
  getDocs,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import RoundStart from "../roundactivity/startround/RoundStart";
import RoundRequest from "../roundactivity/requestround/RoundRequest";
import FriendRequestTest from "../roundactivity/requestround/AllRoundRequest";
import AllFriendActivity from "../roundactivity/friendactivity/AllFriendActivity";
import FriendActivity from "../roundactivity/friendactivity/FriendActivity";
import { Link, useNavigate, useLocation } from "react-router-dom";
import UpcomingRound from "./upcominground/UpcomingRound";
import { getDataStartRound } from "./startround/module/getData";
import { getDataRequestRound } from "./requestround/module/getData";
import { getDataIsPlayer } from "./friendactivity/module/getData";
import { getDataIsCaddie } from "./friendactivity/module/getData";

function RoundActivity() {
  const [activeSectionRound, setActiveSectionRound] = useState("upcoming");
  const [listRound, setListRound] = useState([]);
  const userData = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  const location = useLocation();
  const friendRequestRef = useRef(null);
  const friendActivityRef = useRef(null);
  const [listRoundTest, setListRoundTest] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listRoundRequest, setListRoundRequest] = useState([]);
  const [listRoundFriendActivity, setListRoundFriendActivity] = useState([]);

  // useEffect(() => {
  //   let unsubscribe = () => {};

  //   let fetchData = async () => {
  //     if (!userData) return;

  //     try {
  //       const fetchScoreDetails = async (roundId, playerUids) => {
  //         if (playerUids.length === 0) return [];

  //         const userQuery = query(
  //           collection(firestore, "scoreCards"),
  //           where("userId", "in", playerUids),
  //           where("roundId", "==", roundId)
  //         );
  //         const caddieQuery = query(
  //           collection(firestore, "scoreCards"),
  //           where("caddieId", "in", playerUids),
  //           where("roundId", "==", roundId)
  //         );

  //         const [userSnapshot, caddieSnapshot] = await Promise.all([
  //           getDocs(userQuery),
  //           getDocs(caddieQuery),
  //         ]);

  //         let scoreDetailsArray = [];

  //         userSnapshot.forEach((doc) => {
  //           scoreDetailsArray.push(doc.data());
  //         });

  //         caddieSnapshot.forEach((doc) => {
  //           scoreDetailsArray.push(doc.data());
  //         });

  //         return scoreDetailsArray;
  //       };

  //       const userQuery = query(
  //         collection(firestore, "round"),
  //         where("teeTime", "==", "now")
  //       );

  //       // Replace getDocs with onSnapshot for real-time updates
  //       unsubscribe = onSnapshot(userQuery, async (userSnapshot) => {
  //         let roundDataArray = [];

  //         const roundPromises = userSnapshot.docs.map(async (roundDoc) => {
  //           const roundData = roundDoc.data();
  //           const isCreator = roundData?.creator === userData?.uid;
  //           const isUserInList = roundData.userList.some(
  //             (user) =>
  //               (user.player?.uid === userData.uid &&
  //                 user.player?.isJoin === true) ||
  //               (user.caddie?.uid === userData.uid &&
  //                 user.caddie?.isJoin === true)
  //           );

  //           if (isCreator || isUserInList) {
  //             const playerUids = roundData.userList
  //               .flatMap((user) => [user.player?.uid, user.caddie?.uid]) // รวม uid ของ player และ caddie
  //               .filter((uid) => uid !== undefined); // กรอง undefined ออก

  //             if (playerUids.length > 0) {
  //               const scoreDetailsArray = await fetchScoreDetails(
  //                 roundData.roundId,
  //                 playerUids
  //               );

  //               roundData.userList = roundData.userList.map((user) => {
  //                 const playerUid = user.player?.uid;
  //                 const caddieUid = user.caddie?.uid;

  //                 const playerScore = scoreDetailsArray.find(
  //                   (details) =>
  //                     details.userId === playerUid ||
  //                     details.caddieId === caddieUid
  //                 );

  //                 const status = playerScore ? playerScore.status : undefined;

  //                 return {
  //                   ...user,
  //                   player: { ...user.player, status },
  //                   caddie: { ...user.caddie, status },
  //                 };
  //               });

  //               const isRoundStatus = roundData.userList.some(
  //                 (user) =>
  //                   (user.player?.uid === userData.uid &&
  //                     ((user.player?.status !== "finished" &&
  //                       user.player?.status !== "unfinished") ||
  //                       user.player?.status === undefined)) ||
  //                   (user.caddie?.uid === userData.uid &&
  //                     ((user.caddie?.status !== "finished" &&
  //                       user.caddie?.status !== "unfinished") ||
  //                       user.caddie?.status === undefined))
  //               );

  //               if (isRoundStatus && roundData.status !== "deleted") {
  //                 roundDataArray.push({
  //                   ...roundData,
  //                   roundId: roundDoc.id,
  //                 });
  //               }
  //             }
  //           }
  //         });

  //         await Promise.all(roundPromises); // Ensure all data is processed
  //         setListRound(roundDataArray); // Update state with real-time data
  //       });
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   if (userData) {
  //     fetchData();
  //   }

  //   return () => unsubscribe();
  // }, [userData]);
  useEffect(() => {
    if (!userData) return;

    getDataStartRound(userData, setListRoundTest, setLoading);
    getDataRequestRound(userData, setListRoundRequest, setLoading);

    if (userData.role === "player") {
      getDataIsPlayer(userData, setListRoundFriendActivity);
    } else {
      getDataIsCaddie(userData, setListRoundFriendActivity);
    }
  }, [userData]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedOptionFromQuery = searchParams.get("roundRequest");
    if (selectedOptionFromQuery === "allRoundRequest") {
      setActiveSectionRound("FriendRequest"); // Show the friend request section
      navigate("/round-activity", { replace: true }); // Clear query parameters to avoid duplication
      setTimeout(() => {
        friendRequestRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 0);
    }

    if (selectedOptionFromQuery === "friendRequest") {
      setActiveSectionRound("FriendActivity"); // Show the friend request section
      navigate("/round-activity", { replace: true }); // Clear query parameters to avoid duplication
      setTimeout(() => {
        friendActivityRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 0);
    }
  }, [location.search, navigate]);

  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row feed-body mb-1">
              <div className="col-xl-8 col-xxl-9 col-lg-8 px-md-3 feed-home">
                {listRoundTest.length > 0 ? <RoundStart /> : <CreateRound listRoundTest={listRoundTest}/>}
                {/* Mobile layout */}
                <div className="d-lg-none d-block col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                  {listRoundRequest.length > 0 && <RoundRequest />}
                  {listRoundFriendActivity.length > 0 && <FriendActivity />}
                </div>
                <RoundsCount
                  activeSectionRound={activeSectionRound}
                  setActiveSectionRound={setActiveSectionRound}
                />
                {/* <div ref={friendRequestRef}> */}
                {/* {activeSectionRound === "upcoming" && <RoundUpcoming />}
                 */}
                {activeSectionRound === "upcoming" && <UpcomingRound />}
                {activeSectionRound === "finished" && <RoundFinished />}
                <div ref={friendRequestRef}>
                  {activeSectionRound === "FriendRequest" && (
                    <FriendRequestTest />
                  )}
                </div>

                <div ref={friendActivityRef}>
                  {activeSectionRound === "FriendActivity" && (
                    <AllFriendActivity />
                  )}
                </div>
                {/* </div> */}
              </div>
              <div className="d-none d-lg-block col-xl-4 col-xxl-3 col-lg-4 ps-lg-0 right-scroll-bar">
                <RoundRequest />
                <FriendActivity />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </>
  );
}

export default RoundActivity;
