import React, { useState, useEffect } from 'react'
import styles from '../../../scorestyle.module.scss'
import { useSelector } from 'react-redux'
import useModalWithHistory from '../../../../../components/ModalPopState'
import Scorebox from '../../Scorebox'
import RenderRow3 from '../RenderRow3Comp'
import { isGreenInRegulation, getScorecardClassName, backgroundCalculation } from '../scoreCalculation'
import Swal from 'sweetalert2'
import clsx from 'clsx'

const ScoreInputGrossWHS = ({ score }) => {
    const userData = useSelector((state) => state.user.data);
    const { isModalOpen, openModal, closeModal } = useModalWithHistory();
    const [bg, setBg] = useState("");
    const [gir, setGir] = useState(false);

    const scorecard = getScorecardClassName(bg);

    // console.log(score);

    useEffect(() => {
        const holescore = score.holeScore?.scoreHandicap;
        const holeputts = score.holeScore?.putts;
        const holepar = score.holeDetail?.par;

        if (holescore && holepar && holeputts) {
            const numHolescore = Number(holescore);
            const numHolesputts = Number(holeputts);
            const numHolepar = Number(holepar);

            if (!isNaN(numHolescore) && !isNaN(numHolepar)) {
                // par bg calculation
                const scoreDifference = numHolescore - numHolepar;
                setBg(backgroundCalculation(scoreDifference))

                // GIR calculation
                if (!isNaN(numHolesputts)) {
                    setGir(isGreenInRegulation(numHolepar, numHolescore, numHolesputts))
                }

            } else {
                setBg(""); // Reset background if scores are invalid numbers
                setGir(false);
            }
        } else {
            setBg(""); // Reset background if holescore or holepar is null
            setGir(false);
        }

    }, [score]);

    const handleOpenModal = (currentScore) => {
        if (currentScore.holeScore) {
            openModal();
        } else {
            Swal.fire({
                icon: 'warning',
                text: 'Please wait, Your player not join round yet.',
                confirmButtonColor: '#ee3d7f'
            })
        }
    }
    // console.log(score);


    if ((score.userId === userData.uid) || (score.userId && score.caddieId === userData.uid)) {
        return (
            <>
                <div className={`scorecard ${scorecard} pointer`} style={{ border: '1px solid grey' }} onClick={() => handleOpenModal(score)}>
                    <div className={`${styles.scorecardrow1} d-flex justify-content-between`}>
                        <div className={clsx('d-flex align-items-center gap-1', {
                            'text-danger': score?.holeScore?.isNegativeIndex
                        })}>
                            {(() => {
                                // Ensure that handicapStrokes exists and is a number
                                if (!score?.holeScore?.handicapStrokes) return null;

                                // Create an array of icons for the handicapStrokes count
                                const handicapIcons = [];
                                for (let index = 0; index < score.holeScore.handicapStrokes; index++) {
                                    handicapIcons.push(<i key={index} className='bi-circle-fill' style={{ fontSize: 10 }}></i>);
                                }

                                // Return the array of icons
                                return handicapIcons;
                            })()}
                        </div>

                        <span className='fw-600 fs-5'>{isNaN(score?.holeScore?.putts) || score?.holeScore?.putts === "-" ? "" : score?.holeScore?.putts}</span>
                    </div>
                    <div className={`${styles.scorecardrow2}`}>
                        <span className='col-4'></span>
                        <span className='fw-600 fs-1 col-4 text-center'>{score?.holeScore?.scoreHandicap || "-"}</span>
                        <span className='fw-600 fs-6 col-4 text-end'>{isNaN(score?.holeScore?.drivingRange) || score?.holeScore?.drivingRange === "-" ? "" : score?.holeScore?.drivingRange}</span>
                    </div>
                    <RenderRow3
                        score={score}
                        gir={gir}
                    />
                </div>
                <Scorebox
                    isModalOpen={isModalOpen}
                    openModal={openModal}
                    handleClose={closeModal}
                    selectedHole={score.holeScore}
                    holeData={score.holeDetail}
                />
            </>
        )
    } else {
        return (
            <>
                <div className={`${styles.scorecard} ${styles['scorecard--otherbg']}`}>
                    <div className={`${styles.scorecardrow1} d-flex justify-content-between`}>
                        <div className={clsx('d-flex align-items-center gap-1', {
                            'text-danger': score?.holeScore?.isNegativeIndex
                        })}>
                            {(() => {
                                // Ensure that handicapStrokes exists and is a number
                                if (!score?.holeScore?.handicapStrokes) return null;

                                // Create an array of icons for the handicapStrokes count
                                const handicapIcons = [];
                                for (let index = 0; index < score.holeScore.handicapStrokes; index++) {
                                    handicapIcons.push(<i key={index} className='bi-circle-fill opacity-50' style={{ fontSize: 10 }}></i>);
                                }

                                // Return the array of icons
                                return handicapIcons;
                            })()}
                        </div>
                        <span className='fw-600 fs-5'>{isNaN(score?.holeScore?.putts) || score?.holeScore?.putts === "-" ? "" : score?.holeScore?.putts}</span>
                    </div>
                    <div className={`${styles.scorecardrow2}`}>
                        <span className='col-4'></span>
                        <span className='fw-600 fs-1 col-4 text-center'>{score?.holeScore?.scoreHandicap || "-"}</span>
                        <span className='fw-600 fs-6 col-4 text-end'>{isNaN(score?.holeScore?.drivingRange) || score?.holeScore?.drivingRange === "-" ? "" : score?.holeScore?.drivingRange}</span>
                    </div>
                    <RenderRow3
                        score={score}
                        gir={gir}
                    />
                </div>
            </>
        )
    }
}

export default ScoreInputGrossWHS
