import React, { useRef } from 'react'
import { useSelector } from 'react-redux';
import useModalWithHistory from '../../../../components/ModalPopState';
import { useNavigate } from 'react-router-dom';
import { useScore } from '../../ScoreContext/ScoreContext';
import {
    checkScore,
    matchplayScore,
    matchplayScoreSystem36,
    matchplayScoreWHS,
    sumPenalties,
    sumPutts,
    sumScores,
    sumScoresS36,
    sumScoreStableford,
    sumScoreStablefordS36,
    sumScoreStablefordWHS,
    sumScoresToPar,
    sumScoresToParS36,
    sumScoresToParWHS,
    sumScoresWHS
} from '../../scoring/module/scoreCalculation';
import { finishRound } from '../../module/finishRound';
import PlayerBag from '../PlayerBag';
import DetailScore from '../DetailScore';
import { ReactSVG } from 'react-svg';

function UserHead({ user, mappedUser }) {
    const userData = useSelector((state) => state.user.data);
    const { isModalOpen, openModal, closeModal } = useModalWithHistory();
    const navigate = useNavigate();
    const { score, courseHoles, roundData, playerGameMode } = useScore();

    // console.log(userList);
    const playerDataRef = useRef(null);

    const navFinishRound = () => {
        navigate(`/finish-round?round=${roundData.roundId}`);
    }

    const handlePlayerModal = (player) => {
        playerDataRef.current = player;
        openModal();
    }

    const handleCaddieClick = (user, score) => {
        const foundCaddie = score.find(item => item.caddieId === user.caddie.uid);
        if (foundCaddie) {
            const status = foundCaddie.status;
            if (status === 'finished' && foundCaddie.userId === userData.uid) {
                navigate(`/review-round?round=${roundData.roundId}`);
            } else {
                if (foundCaddie.caddieId === userData.uid) {
                    navigate(`/userPage`);
                } else {
                    navigate(`/user?uid=${foundCaddie.caddieId}`);
                }
            }
        } else {
            navigate(`/user?uid=${user.caddie.uid}`);
        }
    }

    // console.log(mappedUser);

    return (
        <>
            {(() => {
                if (user?.player?.uid === userData.uid || user?.caddie?.uid === userData.uid) {
                    const foundMyScore = score.find(item => item?.userId === userData.uid || item?.caddieId === userData.uid);
                    const foundRivalScore = score.find(item => item?.userId === user?.player?.rival);

                    return (
                        <>
                            <div className='d-flex flex-column justify-content-between align-items-center p-2 gap-2 bg-light position-relative'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    {user.player && user.player.isJoin ?
                                        <img src={user.player.profileImage} alt="player" style={{ width: 40, height: 40, zIndex: 1 }} className='rounded-circle player-color-outline'
                                            onClick={() => handlePlayerModal(user)} />
                                        :
                                        null
                                    }
                                    {user.caddie && user.caddie.isJoin ?
                                        <img src={user.caddie.profileImage} alt="caddie" style={{ width: 40, height: 40 }} className='rounded-circle caddie-color-outline'
                                            onClick={() => handleCaddieClick(user, score)} />
                                        :
                                        null
                                    }
                                </div>
                                <PlayerStatus score={foundMyScore} courseHoles={courseHoles} navFinishRound={navFinishRound} />
                                <PlayerSumScore
                                    score={foundMyScore}
                                    roundData={playerGameMode ?? roundData}
                                    courseHoles={courseHoles}
                                    isRival={false}
                                    isPlayer={true}
                                    playerScore={foundMyScore}
                                    rivalScore={foundRivalScore}
                                    handicap={{ handicap: user?.player?.handicap, isNegative: user?.player?.isNegativeIndex }}
                                />
                                {foundRivalScore &&
                                    <ReactSVG
                                        src='assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_vs.svg'
                                        wrapper='svg'
                                        width={25}
                                        height={25}
                                        className='position-absolute'
                                        style={{ top: '20%', right: 0, transform: 'translateY(-50%)' }}
                                    />
                                }
                            </div>
                        </>
                    )
                } else {
                    const foundScore = score.find(item => item?.userId === user?.player?.uid);
                    if (!user.player && !user.caddie) {
                        return (
                            <div className='h-100 w-100 d-flex justify-content-center align-items-center' style={{ backgroundColor: '#a6a6a6', borderRadius: 10 }}>
                                <h3 className='fw-600'>No player</h3>
                            </div>
                        )
                    } else if (!user.player && user.caddie) {
                        return (
                            <div className='d-flex flex-column justify-content-between align-items-center p-2 gap-2' style={{ backgroundColor: '#a6a6a6', borderRadius: 10 }}>
                                <div className='d-flex justify-content-center align-items-center' >
                                    {user.player ?
                                        <img src={user.player.profileImage} alt="player" style={{ width: 40, height: 40, zIndex: 1 }} className='rounded-circle player-color-outline'
                                            onClick={() => handlePlayerModal(user)}
                                        />
                                        :
                                        null
                                    }
                                    {user.caddie ?
                                        <img src={user.caddie.profileImage} alt="caddie" style={{ width: 40, height: 40 }} className='rounded-circle caddie-color-outline opacity-50'
                                            onClick={() => handleCaddieClick(user, score)} />
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        )
                    } else {
                        const foundPlayer = mappedUser.find(item => (item.player && item.player.uid === userData.uid) || (item.caddie && item.caddie.uid === userData.uid))
                        //    console.log(mappedUser);
                        let playerScore, rivalScore, isPlayerRival = false;

                        if (foundPlayer && foundPlayer.player.rival === user?.player?.uid) {
                            playerScore = score.find(item => item?.userId === foundPlayer?.player?.uid || item?.caddieId === foundPlayer?.caddie?.uid)
                            rivalScore = score.find(item => item?.userId === foundPlayer?.player?.rival)
                            isPlayerRival = true
                        } else {
                            playerScore = score.find(item => item.userId === userData?.uid || item?.caddieId === userData?.uid)
                            rivalScore = score.find(item => item.userId === user?.player?.uid)
                        }

                        return (
                            <div className='d-flex flex-column justify-content-between align-items-center p-2 gap-2' style={{ backgroundColor: '#a6a6a6', borderRadius: 10 }}>
                                <div className='d-flex justify-content-center align-items-center' >
                                    {user.player ?
                                        <img src={user.player.profileImage} alt="player" style={{ width: 40, height: 40, zIndex: 1 }} className='rounded-circle player-color-outline'
                                            onClick={() => handlePlayerModal(user)}
                                        />
                                        :
                                        null
                                    }
                                    {user.caddie && user.caddie.isJoin ?
                                        <img src={user.caddie.profileImage} alt="caddie" style={{ width: 40, height: 40 }} className='rounded-circle caddie-color-outline'
                                            onClick={() => handleCaddieClick(user, score)} />
                                        :
                                        null
                                    }
                                </div>
                                <PlayerStatus score={foundScore} courseHoles={courseHoles} navFinishRound={navFinishRound} />
                                <PlayerSumScore
                                    score={foundScore}
                                    roundData={playerGameMode ?? roundData}
                                    courseHoles={courseHoles}
                                    isPlayer={false}
                                    isRival={isPlayerRival}
                                    playerScore={playerScore}
                                    rivalScore={rivalScore}
                                    handicap={{ handicap: user?.player?.handicap, isNegative: user?.player?.isNegativeIndex }}
                                />
                            </div>
                        )
                    }
                }
            })()}
            {/* <PlayerBag
                user={playerDataRef.current}
                isOpen={isModalOpen}
                closeModal={closeModal}
            /> */}
            <DetailScore
                user={playerDataRef.current}
                isOpen={isModalOpen}
                closeModal={closeModal}
            />
        </>
    )
}

export default UserHead

function PlayerSumScore({ score, roundData, courseHoles, isPlayer, isRival, playerScore, rivalScore, handicap }) {
    const gameMode = roundData.gameMode;
    const scoring = roundData.scoring;
    const { mappedScore } = useScore();

    switch (gameMode) {
        case 'Stroke Play':
            switch (scoring) {
                case 'gross':
                case 'Gross':
                    return (
                        <div className='d-flex justify-content-around align-items-baseline w-100'>
                            <h5 className='fw-600 text-danger'>{sumPenalties(score)}</h5>
                            <h2 className='fw-600'>{sumScores(score)}</h2>
                            <h5 className='fw-600'>{sumPutts(score)}</h5>
                        </div>
                    )

                case 'gross-whs':
                case 'Gross-WHS':
                    return (
                        <div className='d-flex justify-content-around align-items-end w-100 text-center'>
                            <h5 className='fw-600 text-danger col-4'>{sumPenalties(score)}</h5>
                            <h2 className='fw-600 col-4'>{sumScoresWHS(score, mappedScore)}</h2>
                            <span className='d-flex flex-column align-items-center col-4'>
                                <h5 className='fw-600'>{score ? handicap.isNegative ? `+${handicap.handicap}` : `${handicap.handicap}` : ''}</h5>
                                <h5 className='fw-600'>{sumPutts(score)}</h5>
                            </span>
                        </div>
                    )

                case 'to par':
                case 'To Par':
                    return (
                        <div className='d-flex justify-content-around align-items-baseline w-100'>
                            <h5 className='fw-600 text-danger'>{sumPenalties(score)}</h5>
                            <h2 className='fw-600'>{sumScoresToPar(score, courseHoles)}</h2>
                            <h5 className='fw-600'>{sumPutts(score)}</h5>
                        </div>
                    )

                case 'to par-whs':
                case 'To Par-WHS':
                    return (
                        <div className='d-flex justify-content-around align-items-end w-100 text-center'>
                            <h5 className='fw-600 text-danger col-4'>{sumPenalties(score)}</h5>
                            <h2 className='fw-600 col-4'>{sumScoresToParWHS(score, courseHoles, mappedScore)}</h2>
                            <span className='d-flex flex-column align-items-center col-4'>
                                <h5 className='fw-600'>{score ? handicap.isNegative ? `+${handicap.handicap}` : `${handicap.handicap}` : ''}</h5>
                                <h5 className='fw-600'>{sumPutts(score)}</h5>
                            </span>
                        </div>
                    )

                case 'gross-s36':
                case 'Gross-S36':
                    return (
                        <div className='d-flex justify-content-around align-items-baseline w-100'>
                            <h5 className='fw-600 text-danger'>{sumPenalties(score)}</h5>
                            <h2 className='fw-600'>{sumScoresS36(score, courseHoles)}</h2>
                            <h5 className='fw-600'>{sumPutts(score)}</h5>
                        </div>
                    )

                case 'to par-s36':
                case 'To Par-S36':
                    return (
                        <div className='d-flex justify-content-around align-items-baseline w-100'>
                            <h5 className='fw-600 text-danger'>{sumPenalties(score)}</h5>
                            <h2 className='fw-600'>{sumScoresToParS36(score, courseHoles)}</h2>
                            <h5 className='fw-600'>{sumPutts(score)}</h5>
                        </div>
                    )

                default:
                    console.warn('Invalid scoring : ', scoring);
                    return (
                        <div className='d-flex justify-content-around align-items-baseline w-100'>
                            <h5 className='fw-600 text-danger'>{sumPenalties(score)}</h5>
                            <h2 className='fw-600'>{sumScores(score)}</h2>
                            <h5 className='fw-600'>{sumPutts(score)}</h5>
                        </div>
                    )
            }

        case 'Stableford':
            switch (scoring) {
                case 'gross':
                case 'Gross':
                    return (
                        <div className='d-flex justify-content-around align-items-baseline w-100'>
                            <h5 className='fw-600 text-danger'>{sumPenalties(score)}</h5>
                            <h2 className='fw-600'>{sumScoreStableford(score, courseHoles)}</h2>
                            <h5 className='fw-600'>{sumPutts(score)}</h5>
                        </div>
                    )
                case 'gross-whs':
                case 'Gross-WHS':
                    return (
                        <div className='d-flex justify-content-around align-items-end w-100 text-center'>
                            <h5 className='fw-600 text-danger col-4'>{sumPenalties(score)}</h5>
                            <h2 className='fw-600 col-4'>{sumScoreStablefordWHS(score, courseHoles, mappedScore)}</h2>
                            <span className='d-flex flex-column align-items-center col-4'>
                                <h5 className='fw-600'>{score ? handicap.isNegative ? `+${handicap.handicap}` : `${handicap.handicap}` : ''}</h5>
                                <h5 className='fw-600'>{sumPutts(score)}</h5>
                            </span>
                        </div>
                    )

                case 'gross-s36':
                case 'Gross-S36':
                    return (
                        <div className='d-flex justify-content-around align-items-baseline w-100'>
                            <h5 className='fw-600 text-danger'>{sumPenalties(score)}</h5>
                            <h2 className='fw-600'>{sumScoreStablefordS36(score, courseHoles)}</h2>
                            <h5 className='fw-600'>{sumPutts(score)}</h5>
                        </div>
                    )

                default:
                    console.warn('Invalid scoring : ', scoring);
                    return (
                        <div>gross</div>
                    )
            }

        case 'Match Play':
            switch (scoring) {
                case 'gross':
                case 'Gross':
                    return (
                        <MatchPlayScoreDisplay
                            score={score}
                            isPlayer={isPlayer}
                            isRival={isRival}
                            playerScore={playerScore}
                            rivalScore={rivalScore}
                            scoring={scoring}
                            courseHoles={courseHoles} />
                    )

                case 'gross-whs':
                case 'Gross-WHS':
                    return (
                        <MatchPlayScoreWHSDisplay
                            score={score}
                            isPlayer={isPlayer}
                            isRival={isRival}
                            playerScore={playerScore}
                            rivalScore={rivalScore}
                            scoring={scoring}
                            courseHoles={courseHoles}
                            handicap={handicap} />
                    )
                case 'to par':
                case 'To Par':
                    return (
                        <MatchPlayScoreDisplay
                            score={score}
                            isPlayer={isPlayer}
                            isRival={isRival}
                            playerScore={playerScore}
                            rivalScore={rivalScore}
                            scoring={scoring}
                            courseHoles={courseHoles} />
                    )
                case 'to par-whs':
                case 'To Par-WHS':
                    return (
                        <MatchPlayScoreWHSDisplay
                            score={score}
                            isPlayer={isPlayer}
                            isRival={isRival}
                            playerScore={playerScore}
                            rivalScore={rivalScore}
                            scoring={scoring}
                            courseHoles={courseHoles}
                            handicap={handicap} />
                    )

                case 'gross-s36':
                case 'Gross-S36':
                    return (
                        <MatchPlayScoreS36Display
                            score={score}
                            isPlayer={isPlayer}
                            isRival={isRival}
                            playerScore={playerScore}
                            rivalScore={rivalScore}
                            scoring={scoring}
                            courseHoles={courseHoles} />
                    )

                case 'to par-s36':
                case 'To Par-S36':
                    return (
                        <MatchPlayScoreS36Display
                            score={score}
                            isPlayer={isPlayer}
                            isRival={isRival}
                            playerScore={playerScore}
                            rivalScore={rivalScore}
                            scoring={scoring}
                            courseHoles={courseHoles} />
                    )

                default:
                    console.warn('Invalid scoring : ', scoring);
                    return (
                        <div>gross</div>
                    )
            }

        default:
            return (
                <div className='d-flex justify-content-around align-items-baseline w-100'>
                    <h5 className='fw-600 text-danger'>{sumPenalties(score)}</h5>
                    <h2 className='fw-600'>{sumScores(score)}</h2>
                    <h5 className='fw-600'>{sumPutts(score)}</h5>
                </div>
            )
    }
}

function PlayerStatus({ score, courseHoles, navFinishRound }) {
    const userData = useSelector((state) => state.user.data);
    return (
        <>
            {checkScore(score) && score?.status === 'playing' && (score.userId === userData.uid || score.caddieId === userData.uid) ?
                <div className="bg-current theme-dark-bg px-3 py-1 rounded-pill d-flex justify-content-center align-items-center pointer" onClick={() => finishRound(score, courseHoles, navFinishRound)}>
                    <h5 className="fw-600 text-white">Finish Round</h5>
                </div>
                :
                <>
                    {score?.status ?
                        <div className="bg-white theme-dark-bg px-3 py-1 rounded-pill d-flex justify-content-center align-items-center">
                            <h5 className="fw-600 text-capitalize">{score?.status}</h5>
                        </div>
                        :
                        null
                    }
                </>
            }
        </>
    )
}

function MatchPlayScoreDisplay({ score, isPlayer, isRival, playerScore, rivalScore, scoring, courseHoles }) {
    return (
        <div className='d-flex justify-content-around align-items-center w-100'>
            <h5 className='fw-600 text-danger'>{sumPenalties(score)}</h5>
            {(() => {
                if (isPlayer) {
                    const { result, winner, playerWins, rivalWins, dormiePlayer, playerUp, rivalUp } = matchplayScore(playerScore, rivalScore);

                    if (rivalScore) {
                        return (
                            <h2 className="fw-600">
                                {winner === 'player'
                                    ? result
                                    : dormiePlayer
                                        ? "DM"
                                        : winner === 'rival'
                                            ? `-`
                                            : playerUp
                                                ? result
                                                : !playerUp && !rivalUp
                                                    ? result //all square
                                                    : `-`
                                }
                            </h2>
                        )
                    } else {
                        if (scoring === 'Gross' || scoring === 'gross') {
                            return (
                                <h2 className="fw-600">-</h2>
                            )
                        } else {
                            return (
                                <h2 className="fw-600">-</h2>
                            )
                        }
                    }
                } else if (!isPlayer && isRival) {
                    const { result, playerWins, rivalWins, winner, dormieRival, playerUp, rivalUp } = matchplayScore(playerScore, rivalScore);
                    return (
                        <h2 className="fw-600">
                            {winner === 'rival'
                                ? result
                                : dormieRival
                                    ? "DM"
                                    : winner === 'player'
                                        ? `${isNaN(playerWins - rivalWins) ? '' : `(${playerWins - rivalWins})`}`
                                        : rivalUp
                                            ? result
                                            : !playerUp && !rivalUp
                                                ? result //all square
                                                : `${isNaN(rivalWins - playerWins) ? '' : `(${rivalWins - playerWins})`}`
                            }
                        </h2>
                    )
                } else {
                    const { playerWins, rivalWins } = matchplayScore(playerScore, rivalScore);
                    return (
                        <h2 className="fw-600">{isNaN(rivalWins - playerWins) ? '' : `(${rivalWins - playerWins})`}</h2>
                    )
                }
            })()}

            <h5 className='fw-600'>{sumPutts(score)}</h5>
        </div>
    )
}

function MatchPlayScoreWHSDisplay({ score, isPlayer, isRival, playerScore, rivalScore, scoring, courseHoles, handicap }) {
    const { mappedScore } = useScore();
    return (
        <div className='d-flex justify-content-around align-items-center w-100 text-center'>
            <h5 className='fw-600 text-danger col-4'>{sumPenalties(score)}</h5>
            {(() => {
                if (isPlayer) {
                    const { result, winner, playerWins, rivalWins, dormiePlayer, playerUp, rivalUp } = matchplayScoreWHS(playerScore, rivalScore, mappedScore);

                    if (rivalScore) {
                        return (
                            <h2 className="fw-600 col-4">
                                {winner === 'player'
                                    ? result
                                    : dormiePlayer
                                        ? "DM"
                                        : winner === 'rival'
                                            ? `${isNaN(playerWins - rivalWins) ? '' : `(${playerWins - rivalWins})`}`
                                            : playerUp
                                                ? result
                                                : !playerUp && !rivalUp
                                                    ? result //all square
                                                    : `-`
                                }
                            </h2>
                        )
                    } else {
                        if (scoring === 'Gross-WHS' || scoring === 'gross-whs') {
                            return (
                                <h2 className="fw-600">-</h2>
                            )
                        } else if (scoring === 'To Par-WHS' || scoring === 'to par-whs') {
                            return (
                                <h2 className="fw-600">-</h2>
                            )
                        } else {
                            console.warn('incorrect scoring', scoring);
                            return (
                                <h2 className="fw-600">-</h2>
                            )
                        }
                    }
                } else if (!isPlayer && isRival) {
                    const { result, winner, playerWins, rivalWins, dormieRival, playerUp, rivalUp } = matchplayScoreWHS(playerScore, rivalScore, mappedScore);
                    return (
                        <h2 className="fw-600">
                            {winner === 'rival'
                                ? result
                                : dormieRival
                                    ? "DM"
                                    : winner === 'player'
                                        ? `${isNaN(playerWins - rivalWins) ? '' : `(${playerWins - rivalWins})`}`
                                        : rivalUp
                                            ? result
                                            : !playerUp && !rivalUp
                                                ? result //all square
                                                : `${isNaN(rivalWins - playerWins) ? '' : `(${rivalWins - playerWins})`}`
                            }
                        </h2>
                    )
                } else {
                    const { playerWins, rivalWins } = matchplayScoreWHS(playerScore, rivalScore, mappedScore);
                    return (
                        <h2 className="fw-600">{isNaN(rivalWins - playerWins) ? '' : `(${rivalWins - playerWins})`}</h2>
                    )
                }
            })()}
            <span className='d-flex flex-column align-items-center col-4'>
                <h5 className='fw-600'>{score ? handicap.isNegative ? `+${handicap.handicap}` : `${handicap.handicap}` : ''}</h5>
                <h5 className='fw-600'>{sumPutts(score)}</h5>
            </span>
        </div>
    )
}

function MatchPlayScoreS36Display({ score, isPlayer, isRival, playerScore, rivalScore, scoring, courseHoles }) {
    const { mappedScore } = useScore();
    return (
        <div className='d-flex justify-content-around align-items-center w-100'>
            <h5 className='fw-600 text-danger'>{sumPenalties(score)}</h5>
            {(() => {
                if (isPlayer) {
                    const { result, winner, playerWins, rivalWins, dormiePlayer, playerUp, rivalUp } = matchplayScoreSystem36(playerScore, rivalScore, mappedScore);

                    if (rivalScore) {
                        return (
                            <h2 className="fw-600">
                                {winner === 'player'
                                    ? result
                                    : dormiePlayer
                                        ? "DM"
                                        : winner === 'rival'
                                            ? `${isNaN(playerWins - rivalWins) ? '' : `(${playerWins - rivalWins})`}`
                                            : playerUp
                                                ? result
                                                : !playerUp && !rivalUp
                                                    ? result //all square
                                                    : `-`
                                }
                            </h2>
                        )
                    } else {
                        if (scoring === 'Gross-S36' || scoring === 'gross-s36') {
                            return (
                                <h2 className="fw-600">-</h2>
                            )
                        } else if (scoring === 'To Par-S36' || scoring === 'to par-S36') {
                            return (
                                <h2 className="fw-600">-</h2>
                            )
                        } else {
                            console.warn('incorrect scoring', scoring);
                            return (
                                <h2 className="fw-600">-</h2>
                            )
                        }
                    }
                } else if (!isPlayer && isRival) {
                    const { result, playerWins, rivalWins, winner, dormieRival, playerUp, rivalUp } = matchplayScoreSystem36(playerScore, rivalScore, mappedScore);
                    return (
                        <h2 className="fw-600">
                            {winner === 'rival'
                                ? result
                                : dormieRival
                                    ? "DM"
                                    : winner === 'player'
                                        ? `${isNaN(playerWins - rivalWins) ? '' : `(${playerWins - rivalWins})`}`
                                        : rivalUp
                                            ? result
                                            : !playerUp && !rivalUp
                                                ? result //all square
                                                : `${isNaN(rivalWins - playerWins) ? '' : `(${rivalWins - playerWins})`}`
                            }
                        </h2>
                    )
                } else {
                    const { playerWins, rivalWins } = matchplayScoreSystem36(playerScore, rivalScore, mappedScore);
                    return (
                        <h2 className="fw-600">{isNaN(rivalWins - playerWins) ? '' : `(${rivalWins - playerWins})`}</h2>
                    )
                }
            })()}
            <h5 className='fw-600'>{sumPutts(score)}</h5>
        </div>
    )
}

