import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';

// const engFlag = process.env.PUBLIC_URL + '/assets/images/flag/us_flag.png';
const usFlag = process.env.PUBLIC_URL + '/assets/images/flag/us_flag.png';
const thFlag = process.env.PUBLIC_URL + '/assets/images/flag/th_flag.png';

const LanguageDropdown = ({ size = 28 }) => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState('en')


  useEffect(() => {
    const currentLang = localStorage.getItem('i18nextLng');
    i18n.changeLanguage(currentLang);
    setCurrentLanguage(currentLang)
  }, [])

  function handleChangeLang(lang) {
    localStorage.setItem('i18nextLng', lang);
    i18n.changeLanguage(lang);
    setCurrentLanguage(lang)
  }

  return (
    <Dropdown>
      <Dropdown.Toggle className='bg-transparent border border-0' variant='light' id="language-select" size="sm">
        <div className='d-flex align-items-center'>
          <img src={i18n.language === 'en' ? usFlag : thFlag} alt='sel_flag' width={size} />
          <span className='ms-1 fs-6'>
            {currentLanguage !== 'th' ? currentLanguage?.toUpperCase() || 'EN' : 'ไทย' || 'EN'}
          </span>

        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className='p-0 overflow-hidden w-25 border shadow-sm' style={{ borderRadius: 10 }}>
        <Dropdown.Item
          className='text-black px-3'
          onClick={() => handleChangeLang('en')}
          style={{ width: '100%', lineHeight: 'inherit', backgroundColor: i18n.language === 'en' ? '#e9ecef' : 'transparent' }}
        >
          <img className='me-1' src={usFlag} alt='us_flag' width={20} />
          English
        </Dropdown.Item>
        <Dropdown.Item
          className='text-black px-3'
          onClick={() => handleChangeLang('th')}
          style={{ width: '100%', lineHeight: 'inherit', backgroundColor: i18n.language === 'th' ? '#e9ecef' : 'transparent' }}
        >
          <img className='me-1' src={thFlag} alt='th_flag' width={20} />
          ไทย
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageDropdown;
