import React, { useState, useEffect, useRef } from 'react';

import { firestore } from '../../components/firebase/FirebaseConfig';
import { collection, query, orderBy, limit, startAfter, getDocs, where, or } from 'firebase/firestore';

import InfiniteScroll from 'react-infinite-scroll-component';
import Load from '../../components/Load';
import PostviewUser from './PostViewUser';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function FetchUserPost({ user, handleEditedPost }) {
    const [items, setItems] = useState([]);
    // const [lastVisible, setLastVisible] = useState(null);
    const lastVisible = useRef(null);
    const [hasMore, setHasMore] = useState(true);
    const userData = useSelector((state) => state.user.data);
    const maxInit = 10;
    const maxNext = 5

    useEffect(() => {
        window.scrollTo(0, 0);
        if (user) {
            fetchData();
        }
    }, [user]);

    const fetchData = async () => {
        const collectionRef = collection(firestore, 'posts');
        let q = query(
            collectionRef, 
            orderBy('timestamp', 'desc'), 
            limit(maxInit), 
            where('userId', '==', user.uid)
        );
    
        if (lastVisible.current) {
            q = query(
                collectionRef, 
                orderBy('timestamp', 'desc'), 
                startAfter(lastVisible.current), 
                limit(maxNext), 
                where('userId', '==', user.uid)
            );
        }
    
        const documentSnapshots = await getDocs(q);
        const itemsArray = documentSnapshots.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
        const mergedData = itemsArray
            .filter(item => 
                item.audience !== "only_me" || 
                (item.audience === "only_me" && item.userId === userData.uid)
            )
            .map(item => ({
                ...item,
                userData: user || {}
            }));
    
        async function getFriends() {
            const listId = new Set();
    
            const friendsQuery = query(
                collection(firestore, "friends"),
                or(
                    where("friendId", "==", user?.uid),
                    where("userId", "==", user?.uid)
                )
            );
    
            const querySnapshot = await getDocs(friendsQuery);
            querySnapshot.forEach((doc) => {
                if (doc.data().status === 'active') {
                    listId.add(doc.data().friendId);
                    listId.add(doc.data().userId);
                }
            });
    
            return listId.has(userData?.uid);
        }
    
        const isFriend = await getFriends();
    
        const updatedData = mergedData.filter((item) => {
            if (item.audience === 'friends' && !isFriend && item.userId !== userData.uid) {
                return false; // Exclude this item
            }
            return true; // Include all other items
        });
    
        // Check if there is no data to display
        if (updatedData.length === 0 && items.length === 0) {
            setHasMore(false);
            // console.warn("No data available to display.");
            return; // Exit the function early to prevent unnecessary processing
        }
    
        if (isFriend) {
            setItems(prevItems => [...prevItems, ...mergedData]);
        } else {
            setItems(prevItems => [...prevItems, ...updatedData]);
        }
    
        const lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
        lastVisible.current = lastDoc;
    
        if (documentSnapshots.docs.length < maxNext) {
            setHasMore(false);
        }
    };
    

    const handleEditedPost1 = () => handleEditedPost()


    return (
        <>
            {items.length ? (
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<Load />}
                    endMessage={<div className='text-center'><h5 className='fw-600'>You've seen all post</h5></div>}
                >
                    <PostviewUser posts={items} editedPost={handleEditedPost1} />

                </InfiniteScroll>
            ) : (
                <>
                    {hasMore ? (<Load />) : (
                        <Card className='border border-0'>
                            <Card.Body className='m-0'>
                                <div className='text-center'><h5 className='fw-600 p-5'>No post to show</h5></div>
                            </Card.Body>
                        </Card>
                    )}
                </>
            )}
        </>
    )
}

export default FetchUserPost