import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { collection, getDocs, query, where, or } from 'firebase/firestore';
import { firestore } from './firebase/FirebaseConfig';

const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';

const FriendsListContext = createContext({
    friendList: [], // Default value
});

export const useFriends = () => {
    return useContext(FriendsListContext);
};

export const FriendProvider = ({ children }) => {
    const userData = useSelector((state) => state.user.data);
    const [friendLists, setFriendLists] = useState([]);
    const [friendLoaded, setFriendLoaded] = useState(false);

    useEffect(() => {

        async function fetchFriends() {
            try {
                let listId = [];
                const q = query(
                    collection(firestore, "friends"),
                    or(
                        where("friendId", "==", userData?.uid),
                        where("userId", "==", userData?.uid)
                    )
                );

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    if (doc.data().status === 'active') {
                        listId.push(doc.data().friendId);
                        listId.push(doc.data().userId);
                    }
                });

                listId = [...new Set(listId)];
                listId = listId.filter((item) => item !== userData?.uid);

                let friendListArray = [];
                for (const id of listId) {
                    const q2 = query(collection(firestore, "users"), where("uid", "==", id));
                    const querySnapshot2 = await getDocs(q2);
                    querySnapshot2.forEach((doc2) => {
                        friendListArray.push({
                            imageUrl: doc2.data().profileImage || defaultProfile,
                            name: `${doc2.data().firstName} ${doc2.data().lastName}`,
                            uid: doc2.data().uid,
                            dooNineId: doc2.data().dooNineId,
                        });
                    });
                }

                setFriendLists(friendListArray); // Correctly update the state here
                setFriendLoaded(true);

            } catch (error) {
                setFriendLoaded(false);
                console.error('Fail to get friends list', error);
            }
        }

        if (userData) {
            fetchFriends()
        }

    }, [userData])


    return (
        <FriendsListContext.Provider value={{
            friendLists,
            friendLoaded
        }}>
            {children}
        </FriendsListContext.Provider>
    );
};