import React from 'react'
import HoleDetail from './HoleDetail';
import useModalWithHistory from '../../../components/ModalPopState';

function HoleIndex({ holeDetail }) {
    const { isModalOpen, openModal, closeModal } = useModalWithHistory();

    return (
        <>
            <div className='d-flex align-items-center justify-content-center gap-2 pointer bg-light theme-dark-bg rounded-3'
                onClick={() => openModal()}
            >
                <h1 className='fw-700 text-current'>{holeDetail.holeNumber}</h1>
                <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                    <h4 className='fw-600'>{holeDetail.par}</h4>
                    <h4 className='fw-600'>{holeDetail.index}</h4>
                </div>
            </div>
            <HoleDetail
                hole={holeDetail}
                isOpen={isModalOpen}
                closeModal={closeModal}
            />
        </>
    )
}

export default HoleIndex