import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { denyRound } from "../module/denyRound";
import Spinner from "react-bootstrap/Spinner";
import { Card } from "react-bootstrap";
import { getDataRequestRound } from "./module/getData";
import { getDataStartRound } from "../startround/module/getData";
import ModalPlayerCaddie from "../PlayerCaddieModal";

function FriendRequestTest() {
  const userData = useSelector((state) => state.user.data);
  const [listRoundRequest, setListRoundRequest] = useState([]);
  const [listRoundStart, setListRoundStart] = useState([]);
  const [loading, setLoading] = useState(true);
  const maxUserList = { desktop: 7, mobile: 5 };
  const navigate = useNavigate();
  const location = useLocation();
  // const [selectedRound, setSelectedRound] = useState();
  const [listRoundTest, setListRoundTest] = useState([]);
  const [modalPlayerCaddie, setModalPlayerCaddie] = useState(false);
  const [listUserData, setListUserData] = useState([]);
  // useEffect(() => {
  //   const unsubscribe = onSnapshot(
  //     query(collection(firestore, "round")),
  //     async (userSnapshot) => {
  //       try {
  //         let roundDataArray = [];
  //         const fetchScoreDetails = async (uid, roundId) => {
  //           const userQuery = query(
  //             collection(firestore, "scoreCards"),
  //             where("userId", "==", uid),
  //             where("roundId", "==", roundId)
  //           );
  //           const caddieQuery = query(
  //             collection(firestore, "scoreCards"),
  //             where("caddieId", "==", uid),
  //             where("roundId", "==", roundId)
  //           );

  //           const [userSnapshot, caddieSnapshot] = await Promise.all([
  //             getDocs(userQuery),
  //             getDocs(caddieQuery),
  //           ]);

  //           let scoreDetails = {};

  //           userSnapshot.forEach((doc) => {
  //             scoreDetails = doc.data();
  //           });

  //           caddieSnapshot.forEach((doc) => {
  //             scoreDetails = doc.data();
  //           });

  //           return scoreDetails;
  //         };

  //         for (const roundDoc of userSnapshot.docs) {
  //           const roundData = roundDoc.data();
  //           const isCreator = roundData.creator === userData?.uid;
  //           const isUserInList =
  //             Array.isArray(roundData.userList) &&
  //             roundData.userList.some(
  //               (user) =>
  //                 (user.player?.uid === userData?.uid &&
  //                   user.player?.isJoin === true) ||
  //                 (user.caddie?.uid === userData?.uid &&
  //                   user.caddie?.isJoin === true)
  //             );

  //           if (isCreator || isUserInList) {
  //             const playerUids = (roundData.userList || []) // ตรวจสอบว่ามีค่าเป็นอาร์เรย์
  //               .map((user) => user.player?.uid || user.caddie?.uid) // ถ้าไม่มี player ใช้ caddie
  //               .filter((uid) => uid !== undefined);

  //             if (playerUids.length > 0) {
  //               const scoreDetailsArray = await Promise.all(
  //                 playerUids.map((uid) => fetchScoreDetails(uid, roundDoc.id))
  //               );

  //               roundData.userList = roundData.userList.map((user) => {
  //                 const activeUser = user.player ?? user.caddie; // ใช้ player ถ้ามี, ถ้าไม่มีใช้ caddie
  //                 if (activeUser?.uid) {
  //                   const playerScore = scoreDetailsArray.find(
  //                     (details) =>
  //                       details.userId === activeUser.uid ||
  //                       details.caddieId === activeUser.uid
  //                   );
  //                   const status = playerScore ? playerScore.status : undefined;
  //                   return {
  //                     ...user,
  //                     player: { ...user.player, status },
  //                     caddie: { ...user.caddie, status },
  //                   };
  //                 }
  //                 return user;
  //               });

  //               const isRoundStatus = roundData.userList.some(
  //                 (user) =>
  //                   (user.player?.uid === userData.uid &&
  //                     user.player !== null &&
  //                     (user.player?.status === "playing" ||
  //                       user.player?.status == undefined)) ||
  //                   (user.caddie?.uid === userData.uid &&
  //                     user.caddie !== null &&
  //                     (user.caddie?.status === "playing" ||
  //                       user.caddie?.status == undefined))
  //               );

  //               if (
  //                 isRoundStatus &&
  //                 roundData.teeTime === "now" &&
  //                 roundData.status !== "deleted"
  //               ) {
  //                 roundDataArray.push({
  //                   ...roundData,
  //                   roundId: roundDoc.id,
  //                 });
  //               }
  //             }
  //           }
  //         }

  //         setListRoundStart(roundDataArray);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   );

  //   return () => unsubscribe(); // Clean up the listener on unmount
  // }, [userData]);

  useEffect(() => {
    if (!userData) return;

    // Fetch Start Round Data
    const fetchStartRoundData = async () => {
      await getDataStartRound(userData, setListRoundStart, setLoading);
    };

    // Fetch Request Round Data
    const fetchRequestRoundData = async () => {
      await getDataRequestRound(userData, setListRoundTest, setLoading);
    };

    fetchStartRoundData();
    fetchRequestRoundData();
  }, [userData]);

  // console.log(listRoundTest);

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  // useEffect(() => {
  //   if (!userData?.uid) return;
  //   setLoading(true);

  //   const fetchUserDetails = async (uid, userCache) => {
  //     if (userCache[uid]) return userCache[uid];

  //     const userQuery = query(
  //       collection(firestore, "users"),
  //       where("uid", "==", uid)
  //     );
  //     const userSnapshot = await getDocs(userQuery);
  //     let userDetails = {};
  //     userSnapshot.forEach((userDoc) => {
  //       userDetails = userDoc.data();
  //     });
  //     userCache[uid] = userDetails;
  //     return userDetails;
  //   };

  //   const fetchScoreDetails = async (uid, roundId) => {
  //     const userQuery = query(
  //       collection(firestore, "scoreCards"),
  //       where("userId", "==", uid),
  //       where("roundId", "==", roundId)
  //     );
  //     const caddieQuery = query(
  //       collection(firestore, "scoreCards"),
  //       where("caddieId", "==", uid),
  //       where("roundId", "==", roundId)
  //     );

  //     const [userSnapshot, caddieSnapshot] = await Promise.all([
  //       getDocs(userQuery),
  //       getDocs(caddieQuery),
  //     ]);

  //     let scoreDetails = {};
  //     userSnapshot.forEach((doc) => {
  //       scoreDetails = doc.data();
  //     });
  //     caddieSnapshot.forEach((doc) => {
  //       scoreDetails = doc.data();
  //     });

  //     return scoreDetails;
  //   };

  //   const fetchRoundActivity = () => {
  //     const roundActivityQuery = query(
  //       collection(firestore, "roundActivity"),
  //       where("userList", "array-contains", userData.uid),
  //       where("creator", "!=", userData.uid),
  //       orderBy("created", "desc")
  //     );

  //     const unsubscribe = onSnapshot(roundActivityQuery, async (snapshot) => {
  //       if (snapshot.empty) {
  //         setListRoundRequest([]);
  //         setLoading(false);
  //         return;
  //       }

  //       const rounds = snapshot.docs.map((doc) => doc.data());
  //       const dataArray = [];
  //       const userCache = {};

  //       for (const roundActivity of rounds) {
  //         const { roundId } = roundActivity;

  //         const roundQuery = query(
  //           collection(firestore, "round"),
  //           where("roundId", "==", roundId),
  //           where("teeTime", "!=", "finished")
  //         );

  //         const roundSnapshot = await getDocs(roundQuery);
  //         if (roundSnapshot.empty) continue;

  //         const roundDoc = roundSnapshot.docs[0];
  //         const round = roundDoc.data();

  //         let isJoinTrue = false;
  //         round.userList.forEach(({ player, caddie }) => {
  //           if (player?.uid === userData.uid && player.isJoin)
  //             isJoinTrue = true;
  //           if (caddie?.uid === userData.uid && caddie.isJoin)
  //             isJoinTrue = true;
  //         });

  //         if (!isJoinTrue && round.scheduleTime) {
  //           let holesCourse = "";
  //           if (round.holesCourse) {
  //             const courseHolesRef = doc(
  //               firestore,
  //               "courseHoles",
  //               round.holesCourse
  //             );
  //             const courseHolesSnapshot = await getDoc(courseHolesRef);
  //             if (courseHolesSnapshot.exists()) {
  //               holesCourse = courseHolesSnapshot.data().courseHoleName;
  //             }
  //           }

  //           const courseQuery = query(
  //             collection(firestore, "courses"),
  //             where("id", "==", round.course)
  //           );
  //           const courseSnapshot = await getDocs(courseQuery);
  //           const courseData = courseSnapshot.docs[0]?.data() || {};

  //           const { time, day, month } = epochToDateTime(round.scheduleTime);

  //           const userFetches = round.userList.map(
  //             async ({ player, caddie }) => {
  //               const userDataArray = [];
  //               if (player?.uid) {
  //                 const playerData = await fetchUserDetails(
  //                   player.uid,
  //                   userCache
  //                 );
  //                 userDataArray.push({
  //                   ...playerData,
  //                   isCreator: player.isCreator,
  //                   isJoin: player.isJoin,
  //                 });
  //               }
  //               if (caddie?.uid) {
  //                 const caddieData = await fetchUserDetails(
  //                   caddie.uid,
  //                   userCache
  //                 );
  //                 userDataArray.push({
  //                   ...caddieData,
  //                   isCreator: caddie.isCreator,
  //                   isJoin: caddie.isJoin,
  //                   playerConfirm: caddie.playerConfirm,
  //                 });
  //               }
  //               return userDataArray;
  //             }
  //           );

  //           const userDataArray = await Promise.all(userFetches);

  //           const playerUids = round.userList
  //             .flatMap((user) => [user.player?.uid, user.caddie?.uid])
  //             .filter((uid) => uid !== undefined);

  //           if (playerUids.length > 0) {
  //             const scoreDetailsArray = await Promise.all(
  //               playerUids.map((uid) => fetchScoreDetails(uid, roundDoc.id))
  //             );

  //             round.userList = round.userList.map((user) => {
  //               const playerUid = user.player?.uid;
  //               const caddieUid = user.caddie?.uid;

  //               const playerScore = scoreDetailsArray.find(
  //                 (details) =>
  //                   details.userId === playerUid ||
  //                   details.caddieId === caddieUid
  //               );

  //               const status = playerScore ? playerScore.status : undefined;

  //               return {
  //                 ...user,
  //                 player: { ...user.player, status },
  //                 caddie: { ...user.caddie, status },
  //               };
  //             });

  //             const isRoundStatus = round.userList.some(
  //               (user) =>
  //                 (user.player?.uid === userData.uid &&
  //                   (user.player?.status === "playing" ||
  //                     user.player?.status === undefined)) ||
  //                 (user.caddie?.uid === userData.uid &&
  //                   (user.caddie?.status === "playing" ||
  //                     user.caddie?.status === undefined))
  //             );

  //             if (isRoundStatus && round.status !== "deleted") {
  //               dataArray.push({
  //                 ...round,
  //                 courseInfo: courseData,
  //                 time,
  //                 day,
  //                 month,
  //                 userData: userDataArray.flat(),
  //                 holesCourse,
  //               });
  //             }
  //           }
  //         }
  //       }

  //       setListRoundRequest(dataArray);
  //       setLoading(false);
  //     });

  //     return unsubscribe; // Ensure listener is cleaned up
  //   };

  //   const unsubscribe = fetchRoundActivity();
  //   return () => unsubscribe?.();
  // }, [userData]);

  // console.log(listRoundStart);

  const handleJoinClick = (roundId, teeTime) => {
    if (listRoundStart.length > 0 && teeTime == "now") {
      Swal.fire({
        title: "Round In Progress",
        text: "You still have a round in progress. Please finish that round first.",
        icon: "warning",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      });
    } else {
      navigate(`/join-round?id=${roundId}`);
    }
  };

  const handleDeny = async (roundId, userId) => {
    try {
      await denyRound(roundId, userId);
      console.log("Round denied successfully");
    } catch (error) {
      console.error("Error denying round:", error);
    }
  };

  const handleOpenPlayerCaddieModal = (userData) => {
    setModalPlayerCaddie(true);
    setListUserData(userData);
  };

  const handleClosePlayerCaddieModal = () => setModalPlayerCaddie(false);

  const filteredRoundRequest = listRoundTest.sort(
    (a, b) => new Date(b.scheduleTime) - new Date(a.scheduleTime)
  );

  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl">
        <Card.Body className="main-player-stat">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "300px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {filteredRoundRequest.length > 0 ? (
                filteredRoundRequest.map((round, index) => (
                  <div
                    key={index}
                    className="create-round-req-main"
                    style={{ border: "1px solid #e1e1f0 " }}
                  >
                    <section className="create-round-req-head">
                      <div className="pointer">
                        <h4 className="fw-600 mb-1">{round.roundName}</h4>
                        <h6 className="fw-600 text-muted">
                          {`${round.holes} (${round.holesCourse}), ${round.gameMode}(${round.scoring})`}
                        </h6>
                      </div>
                    </section>
                    <section
                      className="create-round-req-body p-1 pointer"
                      style={{
                        backgroundImage: round.coverImage
                          ? `url(${
                              typeof round.coverImage === "string"
                                ? round.coverImage
                                : URL.createObjectURL(round.coverImage)
                            })`
                          : "none",
                        height: round.coverImage ? "" : "inherit",
                      }}
                    >
                      <div
                        className="req-course-data px-0 row"
                        style={{
                          backgroundColor: round.coverImage ? "" : "#fff",
                        }}
                      >
                        <div className="col-sm-2 col-3 pointer p-0 d-flex justify-content-center align-items-center">
                          <img
                            src={round.courseInfo.profileImage}
                            alt="course-profile"
                            width={70}
                            height={70}
                            className="rounded-circle my-2 d-sm-block d-none"
                          />
                          <img
                            src={round.courseInfo.profileImage}
                            alt="course-profile"
                            width={50}
                            height={50}
                            className="rounded-circle my-2 d-sm-none d-block"
                          />
                        </div>
                        <div className="col-sm-8 col-6 pointer p-0 d-flex justify-content-center align-items-center">
                          <div>
                            {/* desktop */}
                            <span className="fw-600 fs-5 d-sm-block d-none">
                              {round.courseInfo.courseName}
                            </span>
                            {/* mobile */}
                            <span className="fw-600 fs-6 d-sm-none d-block">
                              {round.courseInfo.courseName}
                            </span>
                            {/* mobile */}
                            <span className="fw-600 fs-6 d-sm-none d-block">{`${round.courseInfo.location.provinceEng}`}</span>
                            {/* desktop */}
                            <span className="fw-600 fs-5 d-sm-block d-none">{`${round.courseInfo.location.provinceEng}`}</span>
                          </div>
                        </div>
                        <div className="col-sm-2 col-3 px-sm-20">
                          <div
                            className="pointer rounded-3 h-100 d-flex justify-content-center align-items-center flex-column"
                            style={{
                              backgroundColor:
                                round.teeTime === "schedule"
                                  ? "#E59E49"
                                  : "#81D77A",
                            }}
                          >
                            <h5 className="fw-600 text-white text-center">
                              {round.month}
                            </h5>
                            <h5 className="fw-700 text-white text-center">
                              {round.day}
                            </h5>
                            <div className="bg-white text-center w-100">
                              <span
                                className="fw-600 fs-6"
                                style={{
                                  color:
                                    round.teeTime === "schedule"
                                      ? "#E59E49"
                                      : "#81D77A",
                                }}
                              >
                                {round.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section>
                      {/* Desktop display with 7 user limit */}
                      <div
                        className="d-none d-sm-flex flex-column justify-content-between pointer"
                        style={{ padding: "0.6rem 1rem 0.7rem" }}
                      >
                        <div className="d-flex align-items-center mb-3 ms-2">
                          {round.userData
                            .slice(0, maxUserList.desktop)
                            .map((user, idx) =>
                              user.isCreator ? (
                                <React.Fragment key={idx}>
                                  {user.role === "player" ? (
                                    <img
                                      src={user.profileImage}
                                      alt="player"
                                      width={50}
                                      height={50}
                                      className="rounded-circle player-color-outline me-4"
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={user.profileImage}
                                      alt="caddie"
                                      width={50}
                                      height={50}
                                      className="rounded-circle caddie-color-outline me-4"
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  )}
                                </React.Fragment>
                              ) : (
                                <React.Fragment key={idx}>
                                  {user.role === "player" ? (
                                    <img
                                      src={user.profileImage}
                                      alt="player"
                                      width={50}
                                      height={50}
                                      className="rounded-circle player-color-outline"
                                      style={{
                                        marginLeft: "0px",
                                        zIndex: maxUserList.desktop - idx,
                                      }}
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={user.profileImage}
                                      alt="caddie"
                                      width={50}
                                      height={50}
                                      className="rounded-circle caddie-color-outline"
                                      style={{
                                        marginLeft: "-10px",
                                        zIndex: maxUserList.desktop - idx,
                                      }}
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  )}
                                </React.Fragment>
                              )
                            )}
                          {round.userData.length > maxUserList.desktop && (
                            <div
                              className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                              style={{
                                width: 40,
                                height: 40,
                                opacity: 0.6,
                              }}
                            >
                              <h4
                                className="text-center"
                                onClick={() =>
                                  handleOpenPlayerCaddieModal(round.userData)
                                }
                              >
                                +{round.userData.length - maxUserList.desktop}
                              </h4>
                            </div>
                          )}
                        </div>

                        <div className="d-flex align-items-center">
                          {round.userData.map(
                            (user, idx) =>
                              user.isCreator && (
                                <div key={idx}>
                                  <h6
                                    className="fw-600"
                                    style={{ fontSize: "11px" }}
                                  >
                                    Request By {user.firstName} {user.lastName}
                                  </h6>
                                </div>
                              )
                          )}
                        </div>

                        <div className="d-flex justify-content-end">
                          <button
                            className="p-2 lh-20 w100 bg-current me-1 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                            onClick={() =>
                              handleJoinClick(round.roundId, round.teeTime)
                            }
                          >
                            Join
                          </button>
                          <button
                            className="p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                            style={{ background: "#f5f5f5" }}
                            onClick={() =>
                              handleDeny(round.roundId, userData.uid)
                            }
                          >
                            Deny
                          </button>
                        </div>
                      </div>

                      <div
                        className="d-sm-none d-flex flex-column justify-content-between pointer"
                        style={{ padding: "0.3rem 1rem 0.6rem" }}
                      >
                        <div className="d-flex align-items-center mb-3 ms-2">
                          {round.userData
                            .slice(0, maxUserList.mobile)
                            .map((user, idx) =>
                              user.isCreator ? (
                                <React.Fragment key={idx}>
                                  {user.role === "player" ? (
                                    <img
                                      src={user.profileImage}
                                      alt="player"
                                      width={40}
                                      height={40}
                                      className="rounded-circle player-color-outline me-4"
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={user.profileImage}
                                      alt="caddie"
                                      width={40}
                                      height={40}
                                      className="rounded-circle caddie-color-outline me-4"
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  )}
                                </React.Fragment>
                              ) : (
                                <React.Fragment key={idx}>
                                  {user.role === "player" ? (
                                    <img
                                      src={user.profileImage}
                                      alt="player"
                                      width={40}
                                      height={40}
                                      className="rounded-circle player-color-outline"
                                      style={{
                                        marginLeft: "0px",
                                        zIndex: maxUserList.mobile - idx,
                                      }}
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={user.profileImage}
                                      alt="caddie"
                                      width={40}
                                      height={40}
                                      className="rounded-circle caddie-color-outline"
                                      style={{
                                        marginLeft: "-10px",
                                        zIndex: maxUserList.mobile - idx,
                                      }}
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  )}
                                </React.Fragment>
                              )
                            )}
                          {round.userData.length > maxUserList.mobile && (
                            <div
                              className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                              style={{
                                width: 40,
                                height: 40,
                              }}
                            >
                              <h4
                                className="text-center"
                                onClick={() =>
                                  handleOpenPlayerCaddieModal(round.userData)
                                }
                              >
                                +{round.userData.length - maxUserList.mobile}
                              </h4>
                            </div>
                          )}
                        </div>

                        <div className="d-flex align-items-center mb-3">
                          {round.userData.map(
                            (user, idx) =>
                              user.isCreator && (
                                <div key={idx}>
                                  <h6 className="fw-600 fs-7">
                                    Request By {user.firstName} {user.lastName}
                                  </h6>
                                </div>
                              )
                          )}
                        </div>

                        <div className="d-flex justify-content-end">
                          <button
                            className="p-2 lh-20 w100 bg-current me-1 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                            onClick={() =>
                              handleJoinClick(round.roundId, round.teeTime)
                            }
                          >
                            Join
                          </button>
                          <button
                            className="p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                            style={{ background: "#f5f5f5", color: "black" }}
                            onClick={() =>
                              handleDeny(round.roundId, userData.uid)
                            }
                          >
                            Deny
                          </button>
                        </div>
                      </div>
                    </section>
                  </div>
                ))
              ) : (
                <h5 className="text-center p-2">No rounds request available</h5>
              )}
            </>
          )}
        </Card.Body>
      </Card>

      <ModalPlayerCaddie
        isOpen={modalPlayerCaddie}
        handleClose={handleClosePlayerCaddieModal}
        listUserData={listUserData}
      />
    </>
  );
}
export default FriendRequestTest;
