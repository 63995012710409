import { Card } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";

import clsx from "clsx";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { getDataStartRound } from "./module/getData";

function RoundStart() {
  const [listRound, setListRound] = useState([]);
  const userData = useSelector((state) => state.user.data);
  const maxUserList = { desktop: 7, mobile: 5 };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [listRoundTest, setListRoundTest] = useState([]);

  // useEffect(() => {
  //   const unsubscribe = onSnapshot(
  //     query(collection(firestore, "round"), where("teeTime", "==", "now")),
  //     async (userSnapshot) => {
  //       try {
  //         let roundDataArray = [];
  //         let dataArray = [];
  //         // setLoading(true);

  //         const fetchScoreDetails = async (uid, roundId) => {
  //           const userQuery = query(
  //             collection(firestore, "scoreCards"),
  //             where("userId", "==", uid),
  //             where("roundId", "==", roundId)
  //           );
  //           const caddieQuery = query(
  //             collection(firestore, "scoreCards"),
  //             where("caddieId", "==", uid),
  //             where("roundId", "==", roundId)
  //           );

  //           const [userSnapshot, caddieSnapshot] = await Promise.all([
  //             getDocs(userQuery),
  //             getDocs(caddieQuery),
  //           ]);

  //           let scoreDetails = {};

  //           userSnapshot.forEach((doc) => {
  //             scoreDetails = doc.data();
  //           });

  //           caddieSnapshot.forEach((doc) => {
  //             scoreDetails = doc.data();
  //           });

  //           return scoreDetails;
  //         };

  //         for (const roundDoc of userSnapshot.docs) {
  //           const roundData = roundDoc.data();
  //           const isCreator = roundData?.creator === userData?.uid;
  //           const isUserInList = roundData.userList.some(
  //             (user) =>
  //               (user.player?.uid === userData.uid &&
  //                 user.player?.isJoin === true) ||
  //               (user.caddie?.uid === userData.uid &&
  //                 user.caddie?.isJoin === true)
  //           );

  //           if ((isCreator || isUserInList) && (roundData.status === undefined || roundData.status !== "deleted")) {
  //             const playerUids = roundData.userList
  //               .flatMap((user) => [user.player?.uid, user.caddie?.uid]) // รวม uid ของ player และ caddie
  //               .filter((uid) => uid !== undefined); // กรอง undefined ออก

  //             if (playerUids.length > 0) {
  //               const scoreDetailsArray = await Promise.all(
  //                 playerUids.map((uid) => fetchScoreDetails(uid, roundDoc.id))
  //               );

  //               roundData.userList = roundData.userList.map((user) => {
  //                 const playerUid = user.player?.uid;
  //                 const caddieUid = user.caddie?.uid;

  //                 const playerScore = scoreDetailsArray.find(
  //                   (details) =>
  //                     details.userId === playerUid ||
  //                     details.caddieId === caddieUid
  //                 );

  //                 const status = playerScore ? playerScore.status : undefined;

  //                 return {
  //                   ...user,
  //                   player: { ...user.player, status },
  //                   caddie: { ...user.caddie, status },
  //                 };
  //               });

  //               const isRoundStatus = roundData.userList.some(
  //                 (user) =>
  //                   (user.player?.uid === userData.uid &&
  //                     ((user.player?.status !== "finished" &&
  //                       user.player?.status !== "unfinished") ||
  //                       user.player?.status == undefined)) ||
  //                   (user.caddie?.uid === userData.uid &&
  //                     ((user.caddie?.status !== "finished" &&
  //                       user.caddie?.status !== "unfinished") ||
  //                       user.caddie?.status == undefined))
  //               );

  //               // const isRoundStatusPlayer = roundData.userList.every(
  //               //   (user) =>
  //               //     (user.player?.uid === userData.uid && ((user.player?.status !== "finished" && user.player?.status !== "unfinished")))
  //               // );

  //               // const isRoundStatusCaddie = roundData.userList.every(
  //               //   (user) =>
  //               //     (user.caddie?.uid === userData.uid && ((user.caddie?.status !== "finished" && user.caddie?.status !== "unfinished")))

  //               // );

  //               if (isRoundStatus) {
  //                 roundDataArray.push({
  //                   ...roundData,
  //                   roundId: roundDoc.id,
  //                 });
  //               }
  //             }
  //           }
  //         }

  //         for (const round of roundDataArray) {
  //           let holesCourse = "";
  //           if (round.holesCourse) {
  //             const courseHolesCollection = collection(
  //               firestore,
  //               "courseHoles"
  //             );
  //             const q = doc(courseHolesCollection, round.holesCourse);
  //             const courseHolesSnapshot = await getDoc(q);
  //             if (courseHolesSnapshot.exists) {
  //               holesCourse = courseHolesSnapshot.data().courseHoleName;
  //             }
  //           }

  //           const courseQuery = query(
  //             collection(firestore, "courses"),
  //             where("id", "==", round.course)
  //           );
  //           const { time, day, month } = epochToDateTime(round.scheduleTime);

  //           const fetchUserDetails = async (uid) => {
  //             const q = query(
  //               collection(firestore, "users"),
  //               where("uid", "==", uid)
  //             );
  //             const userSnapshot = await getDocs(q);
  //             let userDetails = {};
  //             userSnapshot.forEach((userDoc) => {
  //               userDetails = userDoc.data();
  //             });
  //             return userDetails;
  //           };

  //           let userList = round.userList;
  //           let userDataArray = [];

  //           for (let userObj of userList) {
  //             const { caddie, player } = userObj;

  //             if (player?.uid) {
  //               const playerData = await fetchUserDetails(player.uid);
  //               const combinedPlayerData = {
  //                 ...playerData,
  //                 isCreator: player.isCreator,
  //                 isJoin: player.isJoin,
  //               };
  //               userDataArray.push(combinedPlayerData);
  //             }

  //             if (caddie?.uid) {
  //               const caddieData = await fetchUserDetails(caddie.uid);
  //               const combinedCaddieData = {
  //                 ...caddieData,
  //                 isCreator: caddie.isCreator,
  //                 isJoin: caddie.isJoin,
  //               };
  //               userDataArray.push(combinedCaddieData);
  //             }
  //           }

  //           const courseSnapshot = await getDocs(courseQuery);
  //           courseSnapshot.forEach((doc2) => {
  //             const courseData = doc2.data();
  //             dataArray.push({
  //               ...round,
  //               courseInfo: courseData,
  //               time: time,
  //               day: day,
  //               month: month,
  //               userData: userDataArray,
  //               holesCourse: holesCourse,
  //             });
  //           });
  //         }

  //         setListRound(dataArray);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       } finally {
  //         setLoading(false); // Set loading to false after data is fetched
  //       }
  //     }
  //   );

  //   return () => unsubscribe(); // Clean up the listener on unmount
  // }, [userData]);

  useEffect(() => {
    if (!userData) return;

    getDataStartRound(userData, setListRoundTest, setLoading);
  }, [userData]);

  const handleChat = async (channelUrl) => {
    navigate("/chat-room?channelId=" + channelUrl);
  };

  const handleEndRound = async (roundId) => {
    Swal.fire({
      title: "End this round?",
      html: `
        <h5 class="fw-600">Are you sure you want to end this round?</h5>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate(`/finish-round?round=${roundId}`);
      }
    });
  };

  const handleLinkToCourse = (courseId) => {
    navigate(`/course-detail?courseId=${courseId}`);
  };

  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl mb-2">
        <Card.Body className="main-player-stat">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {listRoundTest.map((round, index) => (
                <div
                  key={index}
                  className="create-round-req-main"
                  style={{ border: "1px solid #e1e1f0 " }}
                >
                  <section className="create-round-req-head">
                    <div className="pointer">
                      <h4 className="fw-600 mb-1">{round.roundName}</h4>
                      <h6 className="fw-600 text-muted">
                        {`${round.holes} (${round.holesCourse}), ${round.gameMode}(${round.scoring})`}
                      </h6>
                    </div>
                    <div className="d-flex flex-wrap-reverse justify-content-end align-items-end">
                      <ReactSVG
                        src="assets/images/dooNineIconV2/chat-SL.svg"
                        wrapper="svg"
                        style={{ height: 40, width: 40 }}
                        className={clsx("pointer", {
                          "d-none": round.groupChat !== "create",
                        })}
                        onClick={() => handleChat(round.channelUrl)}
                      />
                      <Dropdown className="drodownFriend" drop="down">
                        <Dropdown.Toggle
                          as="span" // Use a span element for the wrapper
                          id="dropdown-basic"
                          style={{ cursor: "pointer", zIndex: 1000 }}
                        >
                          <ReactSVG
                            src="assets/images/dooNineIconV3/create-round/createRound-menu.svg"
                            wrapper="svg"
                            className="wizrd-more-btn"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomDropdown}>
                          <Dropdown.Item href={`/score?round=${round.roundId}`}>
                            Resume Round
                          </Dropdown.Item>
                          <Dropdown.Item
                            href={`/leader-board?round=${round.roundId}`}
                          >
                            Leader Board
                          </Dropdown.Item>
                          <Dropdown.Item href="#/game-mode">
                            Game mode
                          </Dropdown.Item>
                          <Dropdown.Item href="#/competitor">
                            Competitor
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            href={`/p-and-c-round?round=${round?.roundId}`}
                          >
                            Player & Caddie
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleLinkToCourse(round.course)}
                          >
                            Course
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item disabled>Edit Round</Dropdown.Item>
                          <Dropdown.Item disabled>
                            Invitation Link
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleEndRound(round.roundId)}
                            disabled={round.userData?.some(
                              (user) =>
                                userData.uid === user.uid &&
                                user.role === "caddie" &&
                                !user.isCreator
                            )} // กำหนดให้ disabled เมื่อ role เป็น "caddie"
                          >
                            End Round
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </section>
                  <section
                    className="create-round-req-body p-1 pointer"
                    //   onClick={() => handleChatandDateClick()}
                    style={{
                      backgroundImage: round.coverImage
                        ? typeof round.coverImage === "string"
                          ? `url(${round.coverImage})` // ถ้าเป็น URL ให้ใช้โดยตรง
                          : `url(${URL.createObjectURL(round.coverImage)})` // ถ้าเป็นไฟล์ ให้ใช้ createObjectURL
                        : "none",
                      height: round.coverImage ? "" : "inherit",
                    }}
                  >
                    <div
                      className="req-course-data px-0 row"
                      style={{
                        backgroundColor: round.coverImage ? "" : "#fff",
                      }}
                    >
                      <div
                        className="col-sm-2 col-3 pointer p-0 d-flex justify-content-center align-items-center"
                        onClick={() => handleLinkToCourse(round.course)}
                      >
                        <img
                          src={round.courseInfo.profileImage}
                          alt="course-profile"
                          width={70}
                          height={70}
                          className="rounded-circle my-2 d-sm-block d-none"
                        />
                        {/* mobile */}
                        <img
                          src={round.courseInfo.profileImage}
                          alt="course-profile"
                          width={50}
                          height={50}
                          className="rounded-circle my-2 d-sm-none d-block"
                        />
                      </div>
                      <div
                        className="col-sm-8 col-6 pointer p-0 d-flex justify-content-center align-items-center"
                        onClick={() => handleLinkToCourse(round.course)}
                      >
                        {/* <div>
                      <span className="fw-600 fs-4">
                        {round.courseInfo.courseName}
                      </span>{" "}
                      <br />
                      <span className="fw-600 fs-6">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</span>
                    </div> */}

                        <div>
                          {/* desktop */}
                          <span className="fw-600 fs-5 d-sm-block d-none">
                            {round.courseInfo.courseName}
                          </span>
                          {/* mobile */}
                          <span className="fw-600 fs-6 d-sm-none d-block">
                            {round.courseInfo.courseName}
                          </span>
                          {/* mobile */}
                          <span className="fw-600 fs-6 d-sm-none d-block">{`${round.courseInfo.location.provinceEng}`}</span>
                          {/* desktop */}
                          <span className="fw-600 fs-5 d-sm-block d-none">{`${round.courseInfo.location.provinceEng}`}</span>
                        </div>
                      </div>
                      <div className="col-sm-2 col-3 px-sm-20">
                        <div
                          className="pointer rounded-3 h-100 d-flex justify-content-center align-items-center flex-column"
                          style={{ backgroundColor: "#81D77A" }}
                          // onClick={() => handleChatandDateClick()}
                        >
                          {round.teeTime === "now" ? (
                            <>
                              <h5 className="fw-600 text-white text-center">
                                {round.month}
                              </h5>
                              <h5 className="fw-700 text-white text-center">
                                {round.day}
                              </h5>
                              <div className="bg-white text-center w-100">
                                <span
                                  className="fw-600 fs-6"
                                  style={{ color: "#81D77A" }}
                                >
                                  {round.time}
                                </span>
                              </div>
                            </>
                          ) : (
                            <div className="bg-white text-center w-100">
                              <span
                                className="fw-600 fs-4"
                                style={{ color: "#81D77A" }}
                              >
                                Start Now
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section>
                    {/* desktop display 7 limit */}
                    <div
                      className="d-none d-sm-flex align-items-center justify-content-between pointer"
                      style={{ padding: "0.6rem 1rem 0.7rem" }}
                    >
                      {/* Left side: User profile images */}
                      <Link to={`/leader-board?round=${round.roundId}`}>
                        <div className="d-flex align-items-center">
                          {round.userData
                            .slice(0, maxUserList.desktop)
                            .map((user, idx) =>
                              user.isCreator ? (
                                <React.Fragment key={idx}>
                                  {user.role === "player" ? (
                                    <img
                                      src={user.profileImage}
                                      alt="player"
                                      width={50}
                                      height={50}
                                      className="rounded-circle player-color-outline me-4"
                                    />
                                  ) : (
                                    <img
                                      src={user.profileImage}
                                      alt="caddie"
                                      width={50}
                                      height={50}
                                      className="rounded-circle caddie-color-outline me-4"
                                    />
                                  )}
                                </React.Fragment>
                              ) : (
                                <React.Fragment key={idx}>
                                  {user.role === "player" ? (
                                    <img
                                      src={user.profileImage}
                                      alt="player"
                                      width={50}
                                      height={50}
                                      className="rounded-circle player-color-outline"
                                      style={{
                                        marginLeft: "0px",
                                        zIndex: maxUserList.desktop - idx,
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={user.profileImage}
                                      alt="caddie"
                                      width={50}
                                      height={50}
                                      className="rounded-circle caddie-color-outline"
                                      style={{
                                        marginLeft: "-10px",
                                        zIndex: maxUserList.desktop - idx,
                                      }}
                                    />
                                  )}
                                </React.Fragment>
                              )
                            )}
                          {round.userData.length > maxUserList.desktop ? (
                            <div
                              className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                              style={{
                                width: 50,
                                height: 50,
                                opacity: 0.6,
                              }}
                            >
                              <h4 className="text-center">
                                +{round.userData.length - maxUserList.desktop}
                              </h4>
                            </div>
                          ) : null}
                        </div>
                      </Link>

                      {/* Right side: Button */}
                      <div>
                        <Link to={`/score?round=${round.roundId}`}>
                          <div className="p-2 lh-20 w100 bg-current text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                            Playing
                          </div>
                        </Link>
                      </div>
                    </div>

                    {/* mobile display 5 limit */}
                    <div
                      className="d-sm-none d-flex align-items-center justify-content-between pointer"
                      style={{ padding: "0.3rem 1rem 0.6rem" }}
                    >
                      {/* Left side: User profile images */}
                      <Link to={`/leader-board?round=${round.roundId}`}>
                        <div className="d-flex align-items-center">
                          {round.userData
                            .slice(0, maxUserList.mobile)
                            .map((user, idx) =>
                              user.isCreator ? (
                                <React.Fragment key={idx}>
                                  {user.role === "player" ? (
                                    <img
                                      src={user.profileImage}
                                      alt="player"
                                      width={40}
                                      height={40}
                                      className="rounded-circle player-color-outline me-4"
                                    />
                                  ) : (
                                    <img
                                      src={user.profileImage}
                                      alt="caddie"
                                      width={40}
                                      height={40}
                                      className="rounded-circle caddie-color-outline me-4"
                                    />
                                  )}
                                </React.Fragment>
                              ) : (
                                <React.Fragment key={idx}>
                                  {user.role === "player" ? (
                                    <img
                                      src={user.profileImage}
                                      alt="player"
                                      width={40}
                                      height={40}
                                      className="rounded-circle player-color-outline"
                                      style={{
                                        marginLeft: "0px",
                                        zIndex: maxUserList.mobile - idx,
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={user.profileImage}
                                      alt="caddie"
                                      width={40}
                                      height={40}
                                      className="rounded-circle caddie-color-outline"
                                      style={{
                                        marginLeft: "-10px",
                                        zIndex: maxUserList.mobile - idx,
                                      }}
                                    />
                                  )}
                                </React.Fragment>
                              )
                            )}
                          {round.userData.length > maxUserList.mobile ? (
                            <div
                              className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                              style={{
                                width: 40,
                                height: 40,
                              }}
                            >
                              <h4 className="text-center">
                                +{round.userData.length - maxUserList.mobile}
                              </h4>
                            </div>
                          ) : null}
                        </div>
                      </Link>
                      {/* Right side: Button */}
                      <div>
                        <Link to={`/score?round=${round.roundId}`}>
                          <div className="p-2 lh-20 w100 bg-current text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                            Playing
                          </div>
                        </Link>
                      </div>
                    </div>
                  </section>
                </div>
              ))}
              {listRoundTest.length > 0 && (
                <Link to={"/create-round"} state={{ schedule: true }}>
                  <button className="btn bg-light theme-dark-bg d-inline-flex align-items-center border">
                    <i className="feather-plus-circle text-current fs-1 me-2"></i>
                    <h5 className="fw-600">Create Round</h5>
                  </button>
                </Link>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
export default RoundStart;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
