import { Card } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  limit,
  orderBy,
  addDoc,
  onSnapshot,
  serverTimestamp,
  startAfter,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";
import { requestToJoinRound } from "../../../../components/notification/sentNotification";

import clsx from "clsx";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getDataIsPlayer } from "./module/getData";
import { getDataIsCaddie } from "./module/getData";
import Spinner from "react-bootstrap/Spinner";
import { getDataStartRound } from "../startround/module/getData";
import ModalPlayerCaddie from "../PlayerCaddieModal";

function AllFriendActivity() {
  const userData = useSelector((state) => state.user.data);
  const [listRound, setListRound] = useState([]);
  const maxUserList = { desktop: 7, mobile: 5 };
  const [requestStatuses, setRequestStatuses] = useState({});
  const [listRoundStart, setListRoundStart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalPlayerCaddie, setModalPlayerCaddie] = useState(false);
  const [listUserData, setListUserData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) return;
    getDataStartRound(userData, setListRoundStart, setLoading);

    if (userData.role === "player") {
      getDataIsPlayer(userData, setListRound);
    } else {
      getDataIsCaddie(userData, setListRound);
    }
  }, [userData]);

  useEffect(() => {
    if (!listRound.length || !userData) return;

    const fetchRequestStatuses = async () => {
      const statuses = {};

      for (const round of listRound) {
        const roundRequestQuery = query(
          collection(firestore, "roundRequest"),
          where("creator", "==", round.creator),
          where("requesterId", "==", userData.uid),
          where("roundId", "==", round.roundId) // Match the specific round
        );

        const querySnapshot = await getDocs(roundRequestQuery);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0].data();
          statuses[round.roundId] = doc.status; // Save status for this round
        } else {
          statuses[round.roundId] = null; // No request found
        }
      }

      setRequestStatuses(statuses);
    };

    fetchRequestStatuses();
  }, [listRound, userData]);

  const filteredRound = listRound.sort(
    (a, b) => new Date(b.scheduleTime) - new Date(a.scheduleTime)
  );

  const handleRequestTojoin = async (roundId, creator, roundName) => {
    try {
      const timeStampInSeconds = Math.floor(Date.now() / 1000);

      const docRef = await addDoc(collection(firestore, "roundRequest"), {
        id: "",
        timestamp: timeStampInSeconds,
        status: "pending",
        requesterId: userData.uid,
        creator: creator,
        roundId: roundId,
      });

      await updateDoc(docRef, {
        id: docRef.id,
        timestamp: timeStampInSeconds,
      });

      requestToJoinRound(
        userData.uid,
        creator,
        timeStampInSeconds,
        roundId,
        docRef.id,
        roundName
      );

      // Show success alert when the request is successfully added to Firestore
      Swal.fire({
        title: "Request Sent",
        text: "Your request to join the round has been successfully submitted!",
        icon: "success",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      });
    } catch (error) {
      // Handle any errors and show error alert
      Swal.fire({
        title: "Error",
        text: "An error occurred while sending your request. Please try again.",
        icon: "error",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      });
      console.error("Error adding request to join: ", error);
    }
  };

  const handleTojoin = async (roundId,teeTime) => {
    try {
      if (listRoundStart.length > 0 && teeTime === "now") {
        Swal.fire({
          title: "Round In Progress",
          text: "You still have a round in progress. Please finish that round first.",
          icon: "warning",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });
      } else {
        window.location.href = `/join-round?id=${roundId}`;
      }
    } catch (error) {
      console.error("Error denying round:", error);
    }
  };

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  const handleOpenPlayerCaddieModal = (userData) => {
    setModalPlayerCaddie(true);
    setListUserData(userData);
  };

  const handleClosePlayerCaddieModal = () => setModalPlayerCaddie(false);

  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl w-100">
        <Card.Body className="main-player-stat">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {filteredRound.length > 0 ? (
                filteredRound.map((round, index) => (
                  <div
                    key={index}
                    className="create-round-req-main"
                    style={{ border: "1px solid #e1e1f0 " }}
                  >
                    <section className="create-round-req-head">
                      <div className="pointer">
                        <h4 className="fw-600 mb-1">{round.roundName}</h4>

                        <h6 className="fw-600 text-muted">
                          {`${round.holes} (${round.holesCourse}), ${round.gameMode}(${round.scoring})`}
                        </h6>
                      </div>
                    </section>
                    <section
                      className="create-round-req-body p-1 pointer"
                      style={{
                        backgroundImage: round.coverImage
                          ? `url(${
                              typeof round.coverImage === "string"
                                ? round.coverImage
                                : URL.createObjectURL(round.coverImage)
                            })`
                          : "none",
                        height: round.coverImage ? "" : "inherit",
                      }}
                    >
                      <div
                        className="req-course-data px-0 row"
                        style={{
                          backgroundColor: round.coverImage ? "" : "#fff",
                        }}
                      >
                        <div className="col-sm-2 col-3 pointer p-0 d-flex justify-content-center align-items-center">
                          <img
                            src={round.courseInfo?.profileImage}
                            alt="course-profile"
                            width={70}
                            height={70}
                            className="rounded-circle my-2 d-sm-block d-none"
                          />
                          <img
                            src={round.courseInfo.profileImage}
                            alt="course-profile"
                            width={50}
                            height={50}
                            className="rounded-circle my-2 d-sm-none d-block"
                          />
                        </div>
                        <div className="col-sm-8 col-6 pointer p-0 d-flex justify-content-center align-items-center">
                          <div>
                            {/* desktop */}
                            <span className="fw-600 fs-5 d-sm-block d-none">
                              {round.courseInfo.courseName}
                            </span>
                            {/* mobile */}
                            <span className="fw-600 fs-6 d-sm-none d-block">
                              {round.courseInfo.courseName}
                            </span>
                            {/* mobile */}
                            <span className="fw-600 fs-6 d-sm-none d-block">{`${round.courseInfo.location.provinceEng}`}</span>
                            {/* desktop */}
                            <span className="fw-600 fs-5 d-sm-block d-none">{`${round.courseInfo.location.provinceEng}`}</span>
                          </div>
                        </div>
                        <div className="col-sm-2 col-3 px-sm-20">
                          <div
                            className="pointer rounded-3 h-100 d-flex justify-content-center align-items-center flex-column"
                            style={{
                              backgroundColor:
                                round.teeTime === "schedule"
                                  ? "#E59E49"
                                  : "#81D77A",
                            }}
                          >
                            <h5 className="fw-600 text-white text-center">
                              {round.month}
                            </h5>
                            <h5 className="fw-700 text-white text-center">
                              {round.day}
                            </h5>
                            <div className="bg-white text-center w-100">
                              <span
                                className="fw-600 fs-6"
                                style={{
                                  color:
                                    round.teeTime === "schedule"
                                      ? "#E59E49"
                                      : "#81D77A",
                                }}
                              >
                                {round.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section>
                      {/* Desktop display with 7 user limit */}
                      <div
                        className="d-none d-sm-flex flex-column justify-content-between pointer"
                        style={{ padding: "0.6rem 1rem 0.7rem" }}
                      >
                        <div className="d-flex align-items-center mb-3 ms-2">
                          {round.userData
                            .slice(0, maxUserList.desktop)
                            .map((user, idx) =>
                              user.isCreator ? (
                                <React.Fragment key={idx}>
                                  {user.role === "player" ? (
                                    <img
                                      src={user.profileImage}
                                      alt="player"
                                      width={50}
                                      height={50}
                                      className="rounded-circle player-color-outline me-4"
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={user.profileImage}
                                      alt="caddie"
                                      width={50}
                                      height={50}
                                      className="rounded-circle caddie-color-outline me-4"
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  )}
                                </React.Fragment>
                              ) : (
                                <React.Fragment key={idx}>
                                  {user.role === "player" ? (
                                    <img
                                      src={user.profileImage}
                                      alt="player"
                                      width={50}
                                      height={50}
                                      className="rounded-circle player-color-outline"
                                      style={{
                                        marginLeft: "0px",
                                        zIndex: maxUserList.desktop - idx,
                                      }}
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={user.profileImage}
                                      alt="caddie"
                                      width={50}
                                      height={50}
                                      className="rounded-circle caddie-color-outline"
                                      style={{
                                        marginLeft: "-10px",
                                        zIndex: maxUserList.desktop - idx,
                                      }}
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  )}
                                </React.Fragment>
                              )
                            )}
                          {round.userData.length > maxUserList.desktop && (
                            <div
                              className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                              style={{
                                width: 40,
                                height: 40,
                                // outline: "1px dashed grey",
                                opacity: 0.6,
                              }}
                            >
                              <h4
                                className="text-center"
                                onClick={() =>
                                  handleOpenPlayerCaddieModal(round.userData)
                                }
                              >
                                +{round.userData.length - maxUserList.desktop}
                              </h4>
                            </div>
                          )}
                        </div>

                        <div className="d-flex align-items-center">
                          {round.userData.map(
                            (user, idx) =>
                              user.uid === round.creator && ( // ตรวจสอบ user.uid เท่ากับ round.creator
                                <div key={idx}>
                                  <h6
                                    className="fw-600"
                                    style={{ fontSize: "11px" }}
                                  >
                                    Created By {user.firstName} {user.lastName}
                                  </h6>
                                </div>
                              )
                          )}
                        </div>

                        <div className="d-flex justify-content-end">
                          <button
                            className="p-2 lh-20 w100 bg-current text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                            onClick={() =>
                              requestStatuses[round.roundId] === "confirm"
                                ? handleTojoin(round.roundId,round.teeTime)
                                : handleRequestTojoin(
                                    round.roundId,
                                    round.creator,
                                    round.roundName
                                  )
                            }
                          >
                            {requestStatuses[round.roundId] === "confirm"
                              ? "Join"
                              : "Request"}
                          </button>
                        </div>
                      </div>

                      <div
                        className="d-sm-none d-flex flex-column justify-content-between pointer"
                        style={{ padding: "0.3rem 1rem 0.6rem" }}
                      >
                        <div className="d-flex align-items-center mb-3 ms-2">
                          {round.userData
                            .slice(0, maxUserList.mobile)
                            .map((user, idx) =>
                              user.isCreator ? (
                                <React.Fragment key={idx}>
                                  {user.role === "player" ? (
                                    <img
                                      src={user.profileImage}
                                      alt="player"
                                      width={40}
                                      height={40}
                                      className="rounded-circle player-color-outline me-4"
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={user.profileImage}
                                      alt="caddie"
                                      width={40}
                                      height={40}
                                      className="rounded-circle caddie-color-outline me-4"
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  )}
                                </React.Fragment>
                              ) : (
                                <React.Fragment key={idx}>
                                  {user.role === "player" ? (
                                    <img
                                      src={user.profileImage}
                                      alt="player"
                                      width={40}
                                      height={40}
                                      className="rounded-circle player-color-outline"
                                      style={{
                                        marginLeft: "0px",
                                        zIndex: maxUserList.mobile - idx,
                                      }}
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={user.profileImage}
                                      alt="caddie"
                                      width={40}
                                      height={40}
                                      className="rounded-circle caddie-color-outline"
                                      style={{
                                        marginLeft: "-10px",
                                        zIndex: maxUserList.mobile - idx,
                                      }}
                                      onClick={() =>
                                        handleProfileClick(user?.uid)
                                      }
                                    />
                                  )}
                                </React.Fragment>
                              )
                            )}
                          {round.userData.length > maxUserList.mobile && (
                            <div
                              className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                              style={{
                                width: 40,
                                height: 40,
                              }}
                            >
                              <h4
                                className="text-center"
                                onClick={() =>
                                  handleOpenPlayerCaddieModal(round.userData)
                                }
                              >
                                +{round.userData.length - maxUserList.mobile}
                              </h4>
                            </div>
                          )}
                        </div>

                        <div className="d-flex align-items-center">
                          {round.userData.map(
                            (user, idx) =>
                              user.uid === round.creator && ( // ตรวจสอบ user.uid เท่ากับ round.creator
                                <div key={idx}>
                                  <h6 className="fw-600 fs-7">
                                    Created By {user.firstName} {user.lastName}
                                  </h6>
                                </div>
                              )
                          )}
                        </div>

                        <div className="d-flex justify-content-end">
                          <button
                            className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                            onClick={() =>
                              requestStatuses[round.roundId] === "confirm"
                                ? handleTojoin(round.roundId,round.teeTime)
                                : handleRequestTojoin(
                                    round.roundId,
                                    round.creator,
                                    round.roundName
                                  )
                            }
                          >
                            {requestStatuses[round.roundId] === "confirm"
                              ? "Join"
                              : "Request"}
                          </button>
                        </div>
                      </div>
                    </section>
                  </div>
                ))
              ) : (
                <h5 className="text-center p-2">No friends activity available</h5>
              )}
            </>
          )}
          {/* <button onClick={loadMoreRounds} disabled={!hasMore}>
            Load More
          </button> */}
        </Card.Body>
      </Card>

      <ModalPlayerCaddie
        isOpen={modalPlayerCaddie}
        handleClose={handleClosePlayerCaddieModal}
        listUserData={listUserData}
      />
    </>
  );
}
export default AllFriendActivity;
