import { firestore } from "../../../components/firebase/FirebaseConfig";
import { where, query, collection, updateDoc, getDocs } from "firebase/firestore";

export const saveTeebox = async (uid, teeData, roundId) => {
    if (!uid || !teeData || !roundId) {
        console.error('saveTeebox: missing required parameters');
        return;
    }

    const scorecardCol = collection(firestore, 'scoreCards');
    const q = query(scorecardCol, where('userId', '==', uid), where('roundId', '==', roundId));
    const scoreCardDoc = await getDocs(q);
    if (!scoreCardDoc.empty) {
        scoreCardDoc.forEach(async (doc) => {
            // console.log(doc.id);

            await updateDoc(doc.ref, {
                teebox: teeData
            });
        });
    }
}