import React, { useEffect, useState } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import { useScore } from '../ScoreContext/ScoreContext';
import { firestore } from '../../../components/firebase/FirebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore'
import { useSelector } from 'react-redux';
import { defaultClubs } from '../../user/myperfprofile/module/clubDefaultData';

import {
    Table,
    Header,
    HeaderRow,
    Body,
    Row,
    HeaderCell,
    Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import LoadCircle from '../../../components/LoadCircle';
import { Link } from 'react-router-dom';
import { stablefordCalculation, system36Calculation } from './scoreInput/scoreCalculation';


function DetailScore({ isOpen, closeModal, user }) {
    const userData = useSelector((state) => state.user.data);
    const { mappedScore, roundData } = useScore();
    const [playerScore, setPlayerScore] = useState(null);
    const [loadingClub, setLoadingClub] = useState(true);
    const [myClub, setMyclub] = useState([]);

    useEffect(() => {
        const filteredScore = []
        mappedScore.forEach(scoreData => {
            const score = scoreData.scores;
            const foundScore = score.find(item => item.userId === user?.player?.uid);
            if (foundScore) {
                filteredScore.push(foundScore)
            }
        });
        const nodes = { nodes: filteredScore }
        setPlayerScore(nodes)

    }, [mappedScore, user])

    useEffect(() => {

        async function getMyClub() {
            const clubsCollection = collection(firestore, 'clubs');
            const q = query(clubsCollection, where("userId", "==", user.player.uid));
            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const data = querySnapshot.docs[0].data();
                    setMyclub(data.clubs);
                } else {
                    setMyclub(defaultClubs);
                }
            } catch (e) {
                console.error("Error adding or updating document: ", e);
            } finally {
                setLoadingClub(false);
            }
        }
        if (user && user.player) {
            getMyClub()
        }

        return () => {
            setLoadingClub(true);
            setMyclub([]);
        }
    }, [user])

    const theme = useTheme({
        Table: `
            --data-table-library_grid-template-columns:  50px 50px ${roundData.handicap === 'world_handicap_system' || roundData.handicap === 'system36' ? '50px' : ''} 50px ${roundData.gameMode === 'Stableford' ? '60px' : ''} 60px 60px 60px 60px 80px;
          `,
        BaseCell: `
            padding: 0;
            &:nth-of-type(1) {
                        left: 0px;
                        border-right: 1px solid;
                    }
                `,
        HeaderRow: `
            th div {
                text-wrap: auto;
                text-align: center;
                font-size: small; 
            }
            th {
                padding:0;
            }
        `,
        Row: `
            td {
                padding:0;
            }
            &:hover .td {
                border-top: 1px solid black;
                border-bottom: 1px solid black;
            }
        `
    });

    // console.log(playerScore);

    return (
        <Modal show={isOpen} onHide={closeModal} centered>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title>
                    <Link to={user?.player?.uid === userData?.uid ? '/userPage' : `/user?uid=${user?.player?.uid}`}>
                        <div className='d-inline-flex align-items-center gap-3'>
                            <img src={user?.player?.profileImage} alt="player" width={50} height={50} className='rounded-circle' />
                            <h4 className='fw-600'>{`${user?.player?.firstName} ${user?.player?.lastName}`}</h4>
                        </div>
                    </Link>
                </Modal.Title>
            </Modal.Header>
            <Tabs
                defaultActiveKey="score"
                id="player-detail"
                className="mb-3"
                fill
            >
                <Tab eventKey="score" title="Scorecard">
                    <Modal.Body style={{ height: '60vh' }} className='p-1'>
                        <Table
                            data={playerScore}
                            theme={theme}
                            layout={{ custom: true, horizontalScroll: true, fixedHeader: true }}
                        >
                            {(tableList) => (
                                <>
                                    <Header>
                                        <HeaderRow className='text-black'>
                                            <HeaderCell pinLeft className=' bg-white'>Hole</HeaderCell>
                                            <HeaderCell>Index</HeaderCell>
                                            <HeaderCell>Par</HeaderCell>
                                            {roundData.handicap === 'world_handicap_system' &&
                                                <HeaderCell>HCP</HeaderCell>
                                            }
                                            <HeaderCell>Stroke</HeaderCell>
                                            {roundData.gameMode === 'Stableford' &&
                                                <HeaderCell>Stableford</HeaderCell>
                                            }
                                            {roundData.handicap === 'system36' &&
                                                <HeaderCell>S36</HeaderCell>
                                            }
                                            <HeaderCell>Putt</HeaderCell>
                                            <HeaderCell>Fairway Hit</HeaderCell>
                                            <HeaderCell>Bunker Hit</HeaderCell>
                                            <HeaderCell>Driving</HeaderCell>
                                        </HeaderRow>
                                    </Header>

                                    <Body>
                                        {tableList.map((item, idx) => (
                                            <Row key={idx} item={item} className='text-center text-black fs-4'>
                                                <Cell pinLeft className='bg-white fw-600'>{item.holeDetail.holeNumber}</Cell>
                                                <Cell>{item.holeDetail.index}</Cell>
                                                <Cell>{item.holeDetail.par}</Cell>
                                                {roundData.handicap === 'world_handicap_system' &&
                                                    <Cell>{`${item.holeScore?.isNegativeIndex && item.holeScore?.handicapStrokes ? '+' : ''}${item.holeScore?.handicapStrokes || "-"}`}</Cell>
                                                }
                                                <Cell>{item.holeScore?.score || "-"}</Cell>
                                                {roundData.gameMode === 'Stableford' &&
                                                    <Cell>
                                                        {(() => {
                                                            const score = item.holeScore?.score;
                                                            const par = item.holeDetail?.par;
                                                            const handicap = item.holeScore?.handicapStrokes;

                                                            // Check if score or par is NaN or undefined
                                                            if (isNaN(score) || score == null || isNaN(par) || par == null) {
                                                                return "-";
                                                            }

                                                            let scoreDiff = 0
                                                            if (roundData.handicap === 'world_handicap_system') {
                                                                if (item.holeScore?.isNegativeIndex) {
                                                                    scoreDiff = (Number(score) + handicap) - par
                                                                } else {
                                                                    scoreDiff = (Number(score) - handicap) - par
                                                                }
                                                            } else {
                                                                scoreDiff = Number(score) - par
                                                            }

                                                            // Perform Stableford calculation if valid
                                                            const calculatedValue = stablefordCalculation(scoreDiff);
                                                            return isNaN(calculatedValue) ? "-" : calculatedValue;
                                                        })()}
                                                    </Cell>
                                                }
                                                {roundData.handicap === 'system36' &&
                                                    <Cell>{system36Calculation(item.holeScore?.score, item.holeDetail.par)}</Cell>
                                                }
                                                <Cell>{item.holeScore?.putts || "-"}</Cell>
                                                <Cell>{convertFairwayHit(item.holeScore?.fairwayHit)}</Cell>
                                                <Cell>{item.holeScore?.bunkerHit || "-"}</Cell>
                                                <Cell>{item.holeScore?.drivingRange || "-"}</Cell>
                                            </Row>
                                        ))}
                                    </Body>
                                </>
                            )}
                        </Table>
                    </Modal.Body>
                </Tab>
                <Tab eventKey="clubs" title="Clubs">
                    <Modal.Body style={{ height: '60vh' }} className='overflow-scroll p-1'>
                        {loadingClub ?
                            <div className=' d-flex justify-content-center p-5'>
                                <LoadCircle size='md' variant='dark' />
                            </div>
                            :
                            <>
                                <div className="d-flex justify-content-center align-items-center p-3 rounded-3 mb-2" style={{ backgroundColor: '#E9F6E9' }}>
                                    <strong className="text-center py-1 px-3 fs-1 fw-bolder me-2 rounded-3 text-white" style={{ backgroundColor: '#81D77A' }}>{myClub.filter(club => club.inBag).length}</strong>
                                    <strong className="fs-4">{`Clubs in ${user.player.firstName}'s bag`}</strong>
                                </div>
                                <div className="py-2 px-4 rounded-3" style={{ backgroundColor: '#EBF6FF' }}>
                                    {myClub
                                        .filter(club => club.inBag)
                                        .sort((a, b) => b.distance - a.distance)
                                        .slice(0, 14)
                                        .map((club, index) => (
                                            <div key={index} className="d-flex justify-content-between align-items-center">
                                                <strong className="text-truncate me-4">{club.name}</strong>
                                                <div className="text-nowrap">{club.distance} {club.distance ? "yd" : null}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                        }
                    </Modal.Body>
                </Tab>
            </Tabs>
        </Modal>
    );
};


export default DetailScore

function convertFairwayHit(fairway = "-") {
    switch (fairway) {
        case 'center':
            return "↑"
        case 'left':
            return "↖"
        case 'right':
            return "↗"

        // case "↑":
        //     return 'center'
        // case "↖":
        //     return 'left'
        // case "↗":
        //     return 'right'

        default:
            return "-"
    }
}