import { Card } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  limit,
  orderBy,
  addDoc,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";
import { requestToJoinRound } from "../../../../components/notification/sentNotification";

import clsx from "clsx";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getDataIsPlayer } from "./module/getData";
import { getDataIsCaddie } from "./module/getData";
import ModalPlayerCaddie from "../PlayerCaddieModal";
import { getDataStartRound } from "../startround/module/getData";

function FriendActivity() {
  const userData = useSelector((state) => state.user.data);
  const [listFriendsId, setListFriendsId] = useState([]);
  const [listRound, setListRound] = useState([]);
  const [loading, setLoading] = useState(true);
  const maxUserList = { desktop: 5, mobile: 7 };
  const [requestStatuses, setRequestStatuses] = useState({});
  const [listRoundStart, setListRoundStart] = useState([]);
  const [modalPlayerCaddie, setModalPlayerCaddie] = useState(false);
  const [listUserData, setListUserData] = useState([]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   async function fetchFriends() {
  //     const userQuery = query(
  //       collection(firestore, "friends"),
  //       where("userId", "==", userData.uid),
  //       where("status", "==", "active")
  //     );

  //     const userQuery2 = query(
  //       collection(firestore, "friends"),
  //       where("friendId", "==", userData.uid),
  //       where("status", "==", "active")
  //     );

  //     const [userSnapshot, friendSnapshot] = await Promise.all([
  //       getDocs(userQuery),
  //       getDocs(userQuery2),
  //     ]);

  //     const friendIds = [
  //       ...userSnapshot.docs.map((doc) => doc.data().friendId),
  //       ...friendSnapshot.docs.map((doc) => doc.data().userId),
  //     ];

  //     setListFriendsId([...new Set(friendIds)]);
  //   }

  //   if (userData) {
  //     fetchFriends();
  //   }
  // }, [userData]);

  // useEffect(() => {
  //   async function fetchRounds() {
  //     if (listFriendsId.length === 0) return;

  //     const roundQuery = query(
  //       collection(firestore, "round"),
  //       where("creator", "in", listFriendsId)
  //     );
  //     const roundSnapshot = await getDocs(roundQuery);
  //     const rounds = roundSnapshot.docs
  //       .map((doc) => doc.data())
  //       .filter(
  //         (round) => round.teeTime !== "now" && round.teeTime !== "finished"
  //       );
  //     setListRound(rounds);
  //   }

  //   fetchRounds();
  // }, [userData,listFriendsId]);

  // useEffect(() => {
  //   if (!userData?.uid || !listFriendsId?.length) return;

  //   const fetchUserDetails = async (uid, userCache) => {
  //     if (userCache[uid]) return userCache[uid];

  //     const userQuery = query(
  //       collection(firestore, "users"),
  //       where("uid", "==", uid)
  //     );
  //     const userSnapshot = await getDocs(userQuery);
  //     let userDetails = {};
  //     userSnapshot.forEach((userDoc) => {
  //       userDetails = userDoc.data();
  //     });
  //     userCache[uid] = userDetails;
  //     return userDetails;
  //   };

  //   const roundQuery = query(
  //     collection(firestore, "round"),
  //     where("creator", "in", listFriendsId),
  //     orderBy("created", "desc"),
  //   );

  //   const unsubscribe = onSnapshot(roundQuery, async (roundSnapshot) => {
  //     if (roundSnapshot.empty) return setListRound([]);

  //     const rounds = roundSnapshot.docs
  //       .map((doc) => doc.data())
  //       .filter(
  //         (round) =>
  //           !round.userList.some(
  //             ({ player, caddie }) =>
  //               player?.uid === userData.uid || caddie?.uid === userData.uid
  //           )
  //       );

  //     const dataArray = [];
  //     const userCache = {};

  //     for (const round of rounds) {
  //       if (round.teeTime !== "now" && round.teeTime !== "finished") {
  //         const courseHolesRef = doc(
  //           firestore,
  //           "courseHoles",
  //           round.holesCourse
  //         );
  //         const [courseHolesSnapshot, courseSnapshot] = await Promise.all([
  //           getDoc(courseHolesRef),
  //           getDocs(
  //             query(
  //               collection(firestore, "courses"),
  //               where("id", "==", round.course)
  //             )
  //           ),
  //         ]);

  //         const holesCourse = courseHolesSnapshot.exists()
  //           ? courseHolesSnapshot.data().courseHoleName
  //           : "";

  //         const { time, day, month } = epochToDateTime(round.scheduleTime);

  //         const userFetches = round.userList.map(async ({ player, caddie }) => {
  //           const userDataArray = [];
  //           if (player?.uid) {
  //             const playerData = await fetchUserDetails(player.uid, userCache);
  //             userDataArray.push({
  //               ...playerData,
  //               isCreator: player.isCreator,
  //               isJoin: player.isJoin,
  //             });
  //           }
  //           if (caddie?.uid) {
  //             const caddieData = await fetchUserDetails(caddie.uid, userCache);
  //             userDataArray.push({
  //               ...caddieData,
  //               isCreator: caddie.isCreator,
  //               isJoin: caddie.isJoin,
  //               playerConfirm: caddie.playerConfirm,
  //             });
  //           }
  //           return userDataArray;
  //         });

  //         const userDataArray = await Promise.all(userFetches);

  //         courseSnapshot.forEach((courseDoc) => {
  //           const courseData = courseDoc.data();
  //           dataArray.push({
  //             ...round,
  //             courseInfo: courseData,
  //             time,
  //             day,
  //             month,
  //             userData: userDataArray.flat(),
  //             holesCourse,
  //           });
  //         });
  //       }
  //     }

  //     setListRound(dataArray);
  //   });

  //   return () => unsubscribe();
  // }, [userData, listFriendsId]);

  // useEffect(() => {
  //   if (!userData?.uid || !listFriendsId?.length) return;

  //   const fetchUserDetails = async (uid, userCache) => {
  //     if (userCache[uid]) return userCache[uid];

  //     const userQuery = query(
  //       collection(firestore, "users"),
  //       where("uid", "==", uid)
  //     );
  //     const userSnapshot = await getDocs(userQuery);
  //     let userDetails = {};
  //     userSnapshot.forEach((userDoc) => {
  //       userDetails = userDoc.data();
  //     });
  //     userCache[uid] = userDetails;
  //     return userDetails;
  //   };

  //   const fetchRounds = async () => {
  //     const roundQuery = query(
  //       collection(firestore, "round"),
  //       where("creator", "in", listFriendsId),
  //       orderBy("created", "desc")
  //     );

  //     const unsubscribe = onSnapshot(roundQuery, async (roundSnapshot) => {
  //       if (roundSnapshot.empty) return setListRound([]);

  //       const userCache = {};

  //       const rounds = await Promise.all(
  //         roundSnapshot.docs.map(async (docSnap) => {
  //           const round = { id: docSnap.id, ...docSnap.data() };
  //           if (round.teeTime === "now" || round.teeTime === "finished" || round.status === "deleted" ||
  //             (userData.role === "player" &&
  //               (!round.userList.some(({ player }) => player === null) ||
  //                 round.userList.some(({ player }) => player?.uid === userData.uid))) ||
  //             (userData.role === "caddie" &&
  //               (await checkCaddieRequest(round, userData.uid, firestore)))
  //           ) {
  //             return null; // Skip rounds that don't meet criteria
  //           }

  //           let holesCourse = "";
  //           if (round?.holesCourse) {
  //             const courseHolesRef = doc(
  //               firestore,
  //               "courseHoles",
  //               round.holesCourse
  //             );
  //             const courseHolesSnapshot = await getDoc(courseHolesRef);
  //             holesCourse = courseHolesSnapshot.exists()
  //               ? courseHolesSnapshot.data().courseHoleName
  //               : "";
  //           }

  //           const courseSnapshot = await getDocs(
  //             query(
  //               collection(firestore, "courses"),
  //               where("id", "==", round.course)
  //             )
  //           );

  //           const { time, day, month } = epochToDateTime(round.scheduleTime);

  //           const userFetches = round.userList.map(async ({ player, caddie }) => {
  //             const userDataArray = [];
  //             if (player?.uid) {
  //               const playerData = await fetchUserDetails(player.uid, userCache);
  //               userDataArray.push({
  //                 ...playerData,
  //                 isCreator: player.isCreator,
  //                 isJoin: player.isJoin,
  //               });
  //             }
  //             if (caddie?.uid) {
  //               const caddieData = await fetchUserDetails(caddie.uid, userCache);
  //               userDataArray.push({
  //                 ...caddieData,
  //                 isCreator: caddie.isCreator,
  //                 isJoin: caddie.isJoin,
  //                 playerConfirm: caddie.playerConfirm,
  //               });
  //             }
  //             return userDataArray;
  //           });

  //           const userDataArray = await Promise.all(userFetches);

  //           const courseData = courseSnapshot.docs[0]?.data() || {};

  //           return {
  //             ...round,
  //             courseInfo: courseData,
  //             time,
  //             day,
  //             month,
  //             userData: userDataArray.flat(),
  //             holesCourse,
  //           };
  //         })
  //       );

  //       // Filter out null rounds and set the list
  //       setListRound(rounds.filter((round) => round !== null));
  //     });

  //     return () => unsubscribe();
  //   };

  //   fetchRounds();
  // }, [userData, listFriendsId]);

  // useEffect(() => {
  //   const unsubscribe = onSnapshot(
  //     query(collection(firestore, "round")),
  //     async (userSnapshot) => {
  //       try {
  //         let roundDataArray = [];
  //         const fetchScoreDetails = async (uid, roundId) => {
  //           const userQuery = query(
  //             collection(firestore, "scoreCards"),
  //             where("userId", "==", uid),
  //             where("roundId", "==", roundId)
  //           );
  //           const caddieQuery = query(
  //             collection(firestore, "scoreCards"),
  //             where("caddieId", "==", uid),
  //             where("roundId", "==", roundId)
  //           );

  //           const [userSnapshot, caddieSnapshot] = await Promise.all([
  //             getDocs(userQuery),
  //             getDocs(caddieQuery),
  //           ]);

  //           let scoreDetails = {};

  //           userSnapshot.forEach((doc) => {
  //             scoreDetails = doc.data();
  //           });

  //           caddieSnapshot.forEach((doc) => {
  //             scoreDetails = doc.data();
  //           });

  //           return scoreDetails;
  //         };

  //         for (const roundDoc of userSnapshot.docs) {
  //           const roundData = roundDoc.data();
  //           const isCreator = roundData.creator === userData?.uid;
  //           const isUserInList =
  //             Array.isArray(roundData.userList) &&
  //             roundData.userList.some(
  //               (user) =>
  //                 (user.player?.uid === userData?.uid &&
  //                   user.player?.isJoin === true) ||
  //                 (user.caddie?.uid === userData?.uid &&
  //                   user.caddie?.isJoin === true)
  //             );

  //           if (isCreator || isUserInList) {
  //             const playerUids = (roundData.userList || [])
  //               .flatMap((user) => [user.player?.uid, user.caddie?.uid]) // รวม uid ของ player และ caddie
  //               .filter((uid) => uid !== undefined); // กรอง undefined ออก

  //             if (playerUids.length > 0) {
  //               const scoreDetailsArray = await Promise.all(
  //                 playerUids.map((uid) => fetchScoreDetails(uid, roundDoc.id))
  //               );

  //               roundData.userList = roundData.userList.map((user) => {
  //                 const playerUid = user.player?.uid;
  //                 const caddieUid = user.caddie?.uid;

  //                 const playerScore = scoreDetailsArray.find(
  //                   (details) =>
  //                     details.userId === playerUid ||
  //                     details.caddieId === caddieUid
  //                 );

  //                 const status = playerScore ? playerScore.status : undefined;

  //                 return {
  //                   ...user,
  //                   player: { ...user.player, status },
  //                   caddie: { ...user.caddie, status },
  //                 };
  //               });

  //               const isRoundStatus = roundData.userList.some(
  //                 (user) =>
  //                   (user.player?.uid === userData.uid &&
  //                     user.player !== null &&
  //                     (user.player?.status === "playing" ||
  //                       user.player?.status == undefined)) ||
  //                   (user.caddie?.uid === userData.uid &&
  //                     user.caddie !== null &&
  //                     (user.caddie?.status === "playing" ||
  //                       user.caddie?.status == undefined))
  //               );

  //               if (
  //                 isRoundStatus &&
  //                 roundData.teeTime === "now" &&
  //                 roundData.status !== "deleted"
  //               ) {
  //                 roundDataArray.push({
  //                   ...roundData,
  //                   roundId: roundDoc.id,
  //                 });
  //               }
  //             }
  //           }
  //         }

  //         setListRoundStart(roundDataArray);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   );

  //   return () => unsubscribe(); // Clean up the listener on unmount
  // }, [userData]);

  useEffect(() => {
    if (!userData) return;
    getDataStartRound(userData, setListRoundStart, setLoading);

    if (userData.role === "player") {
      getDataIsPlayer(userData, setListRound);
    } else {
      getDataIsCaddie(userData, setListRound);
    }
  }, [userData]);

  useEffect(() => {
    if (!listRound.length || !userData) return;

    const fetchRequestStatuses = async () => {
      const statuses = {};

      for (const round of listRound) {
        const roundRequestQuery = query(
          collection(firestore, "roundRequest"),
          where("creator", "==", round.creator),
          where("requesterId", "==", userData.uid),
          where("roundId", "==", round.roundId) // Match the specific round
        );

        const querySnapshot = await getDocs(roundRequestQuery);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0].data();
          statuses[round.roundId] = doc.status; // Save status for this round
        } else {
          statuses[round.roundId] = null; // No request found
        }
      }

      setRequestStatuses(statuses);
    };

    fetchRequestStatuses();
  }, [listRound, userData]);

  const filteredRound = listRound
    .sort((a, b) => new Date(b.scheduleTime) - new Date(a.scheduleTime)) // Sort by createdAt
    .slice(0, 1); // Select only the latest round

  // Notifications Request to join
  const handleRequestTojoin = async (roundId, creator, roundName) => {
    try {
      const timeStampInSeconds = Math.floor(Date.now() / 1000);

      const docRef = await addDoc(collection(firestore, "roundRequest"), {
        id: "",
        timestamp: timeStampInSeconds,
        status: "pending",
        requesterId: userData.uid,
        creator: creator,
        roundId: roundId,
      });

      await updateDoc(docRef, {
        id: docRef.id,
        timestamp: timeStampInSeconds,
      });

      requestToJoinRound(
        userData.uid,
        creator,
        timeStampInSeconds,
        roundId,
        docRef.id,
        roundName
      );

      // Show success alert when the request is successfully added to Firestore
      Swal.fire({
        title: "Request Sent",
        text: "Your request to join the round has been successfully submitted!",
        icon: "success",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      });
    } catch (error) {
      // Handle any errors and show error alert
      Swal.fire({
        title: "Error",
        text: "An error occurred while sending your request. Please try again.",
        icon: "error",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      });
      console.error("Error adding request to join: ", error);
    }
  };

  const handleTojoin = async (roundId, teeTime) => {
    try {
      if (listRoundStart.length > 0 && teeTime === "now") {
        Swal.fire({
          title: "Round In Progress",
          text: "You still have a round in progress. Please finish that round first.",
          icon: "warning",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });
      } else {
        window.location.href = `/join-round?id=${roundId}`;
      }
    } catch (error) {
      console.error("Error denying round:", error);
    }
  };

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  const handleOpenPlayerCaddieModal = (userData) => {
    setModalPlayerCaddie(true);
    setListUserData(userData);
  };

  const handleClosePlayerCaddieModal = () => setModalPlayerCaddie(false);

  // const handleRequestTojoin = async (roundId) => {
  //   window.location.href = `/join-round?id=${roundId}`
  // }

  // console.log(listRound)
  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl w-100 mt-1">
        <Card.Body className="main-player-stat p-1">
          <div className="card-body d-flex align-items-center p-2">
            <h4 className="fw-700 mb-0 font-xssss text-grey-900">
              Friend Activity
            </h4>
            <Link
              to="/round-activity?roundRequest=friendRequest"
              className="fw-600 ms-auto font-xssss text-primary"
            >
              See all
            </Link>
          </div>

          {filteredRound.length > 0 ? (
            filteredRound.map((round, index) => (
              <div key={index} className="create-round-req-main mb-3">
                <section className="create-round-req-head">
                  <div className="pointer">
                    <h5 className="fw-600 d-sm-block d-none">
                      {round.roundName}
                    </h5>
                    <h4 className="fw-600 d-sm-none d-block">
                      {round.roundName}
                    </h4>
                  </div>
                </section>

                <section
                  className="create-round-req-body p-1 pointer"
                  style={{
                    backgroundImage: round.coverImage
                      ? typeof round.coverImage === "string"
                        ? `url(${round.coverImage})`
                        : `url(${URL.createObjectURL(round.coverImage)})`
                      : "none",
                    height: round.coverImage ? "250px" : "inherit",
                  }}
                >
                  <div
                    className="req-course-data d-flex align-items-center w-100"
                    style={{
                      backgroundColor: round.coverImage ? "" : "#fff",
                    }}
                  >
                    <div className="col-sm-3 col-3 pointer p-0 d-flex justify-content-center align-items-center">
                      <img
                        src={round.courseInfo.profileImage}
                        alt="course-profile"
                        width={50}
                        height={50}
                        className="rounded-circle my-2"
                      />
                    </div>
                    <div className="col-sm-6 col-xs-6 d-flex align-items-center justify-content-start pointer">
                      <div>
                        <span
                          className="fw-600 d-sm-block d-none"
                          style={{ fontSize: "11px" }}
                        >
                          {round.courseInfo.courseName}
                        </span>
                        <span className="fw-600 fs-5 d-sm-none d-block">
                          {round.courseInfo.courseName}
                        </span>
                        <span className="fw-600 fs-5 d-sm-none d-block">{`${round.courseInfo.location.provinceEng}`}</span>
                        <span
                          className="fw-600 d-sm-block d-none"
                          style={{ fontSize: "10px" }}
                        >
                          {`${round.courseInfo.location.provinceEng}`}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4 col-3 px-sm-20">
                      <div
                        className="h-100 w-75 d-flex flex-column justify-content-evenly rounded-3 py-2 pointer"
                        style={{
                          backgroundColor:
                            round.teeTime === "schedule"
                              ? "#E59E49"
                              : "#81D77A",
                        }}
                      >
                        {round.teeTime === "schedule" ? (
                          <>
                            <h5 className="fw-600 text-white text-center">
                              {round.month}
                            </h5>
                            <h5 className="fw-700 text-white text-center">
                              {round.day}
                            </h5>
                            <div className="bg-white text-center w-100">
                              <span
                                className="fw-600 fs-6"
                                style={{ color: "#E59E49" }}
                              >
                                {round.time}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <h5 className="fw-600 text-white text-center">
                              {round.month}
                            </h5>
                            <h5 className="fw-700 text-white text-center">
                              {round.day}
                            </h5>
                            <div className="bg-white text-center w-100">
                              <span
                                className="fw-600 fs-6"
                                style={{ color: "#81D77A" }}
                              >
                                {round.time}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <div
                    className="d-none d-sm-flex flex-column justify-content-between pointer"
                    style={{ padding: "0.6rem 1rem 0.7rem" }}
                  >
                    <div className="d-flex align-items-center mb-3 ms-2">
                      {round.userData
                        .slice(0, maxUserList.desktop)
                        .map((user, idx) =>
                          user.isCreator ? (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={40}
                                  height={40}
                                  className="rounded-circle player-color-outline me-4"
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={40}
                                  height={40}
                                  className="rounded-circle caddie-color-outline me-4"
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={40}
                                  height={40}
                                  className="rounded-circle player-color-outline"
                                  style={{
                                    marginLeft: "0px",
                                    zIndex: maxUserList.desktop - idx,
                                  }}
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={40}
                                  height={40}
                                  className="rounded-circle caddie-color-outline"
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.desktop - idx,
                                  }}
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              )}
                            </React.Fragment>
                          )
                        )}
                      {round.userData.length > maxUserList.desktop && (
                        <div
                          className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                          style={{
                            width: 40,
                            height: 40,
                            // outline: "1px dashed grey",
                            opacity: 0.6,
                          }}
                        >
                          <h6
                            className="text-center"
                            onClick={() =>
                              handleOpenPlayerCaddieModal(round.userData)
                            }
                          >
                            +{round.userData.length - maxUserList.desktop} more
                          </h6>
                        </div>
                      )}
                    </div>

                    <div className="d-flex align-items-center mb-3">
                      {round.userData.map(
                        (user, idx) =>
                          user.uid === round.creator && ( // ตรวจสอบ user.uid เท่ากับ round.creator
                            <div key={idx}>
                              <h6 className="fw-600 fs-7">
                                Created By {user.firstName} {user.lastName}
                              </h6>
                            </div>
                          )
                      )}
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        className="p-2 lh-20 w100 bg-current text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                        onClick={() =>
                          requestStatuses[round.roundId] === "confirm"
                            ? handleTojoin(round.roundId,round.teeTime)
                            : handleRequestTojoin(
                                round.roundId,
                                round.creator,
                                round.roundName
                              )
                        }
                      >
                        {requestStatuses[round.roundId] === "confirm"
                          ? "Join"
                          : "Request"}
                      </button>
                      {/* <button
                        className="p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                        style={{ background: "#f5f5f5" }}
                        onClick={() => handleDeny(round.roundId, userData.uid)}
                      >
                        Deny
                      </button> */}
                    </div>
                  </div>

                  <div
                    className="d-sm-none d-flex flex-column justify-content-between pointer"
                    style={{ padding: "0.3rem 1rem 0.6rem" }}
                  >
                    <div className="d-flex align-items-center mb-3 ms-2">
                      {round.userData
                        .slice(0, maxUserList.mobile)
                        .map((user, idx) =>
                          user.isCreator ? (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={40}
                                  height={40}
                                  className="rounded-circle player-color-outline me-4"
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={40}
                                  height={40}
                                  className="rounded-circle caddie-color-outline me-4"
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={40}
                                  height={40}
                                  className="rounded-circle player-color-outline"
                                  style={{
                                    marginLeft: "0px",
                                    zIndex: maxUserList.mobile - idx,
                                  }}
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={40}
                                  height={40}
                                  className="rounded-circle caddie-color-outline"
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.mobile - idx,
                                  }}
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              )}
                            </React.Fragment>
                          )
                        )}
                      {round.userData.length > maxUserList.mobile && (
                        <div
                          className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                          style={{
                            width: 40,
                            height: 40,
                            // outline: "1px dashed grey",
                          }}
                        >
                          <h6
                            className="text-center"
                            onClick={() =>
                              handleOpenPlayerCaddieModal(round.userData)
                            }
                          >
                            +{round.userData.length - maxUserList.mobile} more
                          </h6>
                        </div>
                      )}
                    </div>

                    <div className="d-flex align-items-center mb-3">
                      {round.userData.map(
                        (user, idx) =>
                          user.uid === round.creator && ( // ตรวจสอบ user.uid เท่ากับ round.creator
                            <div key={idx}>
                              <h6 className="fw-600 fs-7">
                                Created By {user.firstName} {user.lastName}
                              </h6>
                            </div>
                          )
                      )}
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        className="p-2 lh-20 w100 bg-current text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                        onClick={() =>
                          requestStatuses[round.roundId] === "confirm"
                            ? handleTojoin(round.roundId,round.teeTime)
                            : handleRequestTojoin(
                                round.roundId,
                                round.creator,
                                round.roundName
                              )
                        }
                      >
                        {requestStatuses[round.roundId] === "confirm"
                          ? "Join"
                          : "Request"}
                      </button>
                    </div>
                    {/* <div className="d-flex justify-content-end">
                      <button
                        className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                        onClick={() =>
                          handleJoinClick(round.roundId, round.teeTime)
                        }
                      >
                        Join
                      </button>
                      <button
                        className="p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                        style={{ background: "#f5f5f5" }}
                        onClick={() => handleDeny(round.roundId, userData.uid)}
                      >
                        Deny
                      </button>
                    </div> */}
                  </div>
                </section>
              </div>
            ))
          ) : (
            <h5 className="text-center p-2">No friend activity available</h5>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

async function checkCaddieRequest(round, caddieId, firestore) {
  const caddieRequestQuery = query(
    collection(firestore, "caddieRequests"),
    where("caddieId", "==", caddieId),
    where("status", "==", "approved"),
    where("courseId", "==", round.course)
  );
  const caddieRequestSnapshot = await getDocs(caddieRequestQuery);

  return (
    (caddieRequestSnapshot.empty &&
      !round.userList.some(({ caddie }) => caddie?.uid === caddieId)) ||
    !round.userList.some(({ caddie }) => caddie === null)
  );
}

export default FriendActivity;
