import React, { createContext, useContext, useState, useEffect } from 'react';
import { mapScores, mapScoresWHS, mapUser } from '../scoring/module/scoreCalculation';
import { useSelector } from 'react-redux';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../../components/firebase/FirebaseConfig';
import { set } from 'lodash';

const ScoreContext = createContext();

export const useScore = () => {
    return useContext(ScoreContext);
};


export const ScoreProvider = ({ children }) => {
    const userData = useSelector((state) => state.user.data);
    const [roundData, setRoundData] = useState(null);
    const [userList, setUserList] = useState([]);
    const [courseHoles, setCourseHoles] = useState(null);
    const [score, setScore] = useState(null);
    const [playerGameMode, setPlayerGameMode] = useState(null);
    const [mappedScore, setMappedScore] = useState([]);
    const [mappedUser, setMappedUser] = useState([]);
    const [playerStat, setPlayerStat] = useState([]);

    useEffect(() => {
        if (!roundData) return;

        const getStat = async (userList) => {
            const uidArr = userList
                .filter(item => item.player && item.player.uid) // Ensure valid player and uid
                .map(item => item.player.uid); // Extract only the uid

            const statCollection = collection(firestore, 'playerStatistic');
            const q = query(statCollection, where('userId', 'in', uidArr));
            const statSnapshot = await getDocs(q);

            if (!statSnapshot.empty) {
                statSnapshot.forEach(doc => {
                    const data = doc.data();
                    setPlayerStat(prev => [...prev, data]);
                    const foundUser = userList.find(item => item.player && item.player.uid === data.userId);
                    if (foundUser) {
                        let isNegativeIndex = false;
                        if (data?.handicap?.startsWith("+")) {
                            isNegativeIndex = true
                        }
                        const handicapNum = parseFloat(data.handicap);
                        if (!isNaN(handicapNum)) {
                            foundUser.player.handicap = handicapNum;
                        } else {
                            foundUser.player.handicap = 0;
                        }
                        foundUser.player.isNegativeIndex = isNegativeIndex;
                    }
                });
            }

            // Return the updated userList after processing
            return userList;
        };

        const updateUserList = async () => {
            const updatedUserList = await getStat(roundData.userList);
            setUserList(updatedUserList);
        };

        updateUserList();
    }, [roundData]);


    useEffect(() => {
        if (!courseHoles || !score || !userData || !roundData || !userList) return;

        userList.sort((a, b) => a.row - b.row);

        const courseDet = courseHoles?.holeDetails;

        if (!courseDet) return

        courseDet.sort((a, b) => a.holeNumber - b.holeNumber);

        const roundData2 = playerGameMode ?? roundData;

        if (roundData2.handicap === "world_handicap_system") {
            const mappedScore = mapScoresWHS(courseDet, score, userList, userData.uid);
            const mappedUser = mapUser(mappedScore, userList);
            setMappedScore(mappedScore)
            setMappedUser(mappedUser)
        } else if (roundData2.handicap === "system36") {
            const mappedScore = mapScores(courseDet, score, userList, userData.uid);
            const mappedUser = mapUser(mappedScore, userList);
            setMappedScore(mappedScore)
            setMappedUser(mappedUser)
        } else {
            const mappedScore = mapScores(courseDet, score, userList, userData.uid);
            const mappedUser = mapUser(mappedScore, userList);
            setMappedScore(mappedScore)
            setMappedUser(mappedUser)
        }

        // console.log(mappedScore);
        // console.log(mappedUser);

    }, [courseHoles, roundData, score, userData, userList, playerGameMode])

    useEffect(() => {
        if (roundData) {
            const getGameMode = JSON.parse(sessionStorage.getItem(`playerGameMode_${roundData?.roundId}`)) || null;
            if (getGameMode)
                setPlayerGameMode(getGameMode);
        }
    }, [roundData])



    return (
        <ScoreContext.Provider value={{
            roundData,
            setRoundData,
            userList,
            setUserList,
            setCourseHoles,
            courseHoles,
            score,
            setScore,
            setPlayerGameMode,
            playerGameMode,
            mappedScore,
            mappedUser,
            playerStat,
        }}>
            {children}
        </ScoreContext.Provider>
    );
};