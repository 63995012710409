import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  getDoc,
  orderBy,
} from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getDataIsPlayer } from "../roundactivity/friendactivity/module/getData";
import { getDataIsCaddie } from "../roundactivity/friendactivity/module/getData";
import { getDataUpcomingRound } from "./upcominground/module/getData";
import { getDataFinshedRound } from "./finishedround/module/getData";
import { getDataRequestRound } from "./requestround/module/getData";

function RoundsCount({ activeSectionRound, setActiveSectionRound }) {
  const [listRoundUpcoming, setListRoundUpcoming] = useState([]);
  const [listRoundFinished, setListRoundFinsihed] = useState([]);
  const [listRoundRequest, setListRoundRequest] = useState([]);
  const [listRoundActivity, setListRoundActivity] = useState([]);
  const [listFriendActivity, setListFriendActivity] = useState([]);
  const [loading, setLoading] = useState(true);

  const userData = useSelector((state) => state.user.data);

  useEffect(() => {
    const savedSection = localStorage.getItem("activeSectionRound");
    if (savedSection) {
      setActiveSectionRound(savedSection);
    }
  }, [setActiveSectionRound]);

  // Save the active section to localStorage whenever it changes
  useEffect(() => {
    if (activeSectionRound) {
      localStorage.setItem("activeSectionRound", activeSectionRound);
    }
  }, [activeSectionRound]);

  // Handlers for clicking each section
  const handleUpcomingClick = () => {
    setActiveSectionRound("upcoming");
  };

  const handleFinishedClick = () => {
    setActiveSectionRound("finished");
  };

  const handleFriendRequestClick = () => {
    setActiveSectionRound("FriendRequest");
  };

  const handleFriendActivityClick = () => {
    setActiveSectionRound("FriendActivity");
  };

  // useEffect(() => {
  //   const fetchData = () => {
  //     const roundQuery = query(collection(firestore, "round"));

  //     const unsubscribe = onSnapshot(roundQuery, async (roundSnapshot) => {
  //       let dataRondUpcoming = [];
  //       let dataRondFinished = [];

  //       // Collect all asynchronous fetch operations
  //       const roundPromises = roundSnapshot.docs.map(async (doc) => {
  //         const roundData = doc.data();
  //         const roundId = doc.id;

  //         const fetchScoreDetails = async (uid, roundId) => {
  //           if (!uid || !roundId) return {}; // Guard against undefined values

  //           const userQuery = query(
  //             collection(firestore, "scoreCards"),
  //             where("userId", "==", uid),
  //             where("roundId", "==", roundId)
  //           );
  //           const caddieQuery = query(
  //             collection(firestore, "scoreCards"),
  //             where("caddieId", "==", uid),
  //             where("roundId", "==", roundId)
  //           );

  //           const [userSnapshot, caddieSnapshot] = await Promise.all([
  //             getDocs(userQuery),
  //             getDocs(caddieQuery),
  //           ]);

  //           let scoreDetails = {};

  //           userSnapshot.forEach((doc) => {
  //             scoreDetails = doc.data();
  //           });

  //           caddieSnapshot.forEach((doc) => {
  //             scoreDetails = doc.data();
  //           });

  //           return scoreDetails;
  //         };

  //         const isCreator = roundData.creator === userData.uid;
  //         const isUserInList =
  //           Array.isArray(roundData.userList) &&
  //           roundData.userList.some(
  //             (user) =>
  //               (user.player?.uid === userData.uid &&
  //                 user.player?.isJoin === true) ||
  //               (user.caddie?.uid === userData.uid &&
  //                 user.caddie?.isJoin === true)
  //           );

  //         if (isCreator || isUserInList) {
  //           if (
  //             roundData.teeTime === "schedule" &&
  //             (roundData.status === undefined || roundData.status !== "deleted")
  //           ) {
  //             dataRondUpcoming.push({ ...roundData });
  //           }

  //           if (
  //             (roundData.teeTime === "now" ||
  //               roundData.teeTime === "finished") &&
  //             (roundData.status === undefined || roundData.status !== "deleted")
  //           ) {
  //             const playerUids = roundData.userList
  //               .flatMap((user) => [user.player?.uid, user.caddie?.uid]) // รวม uid ของ player และ caddie
  //               .filter((uid) => uid !== undefined); // กรอง undefined ออก

  //             if (playerUids.length > 0) {
  //               const scoreDetailsArray = await Promise.all(
  //                 playerUids.map((uid) => fetchScoreDetails(uid, roundId))
  //               );

  //               roundData.userList = roundData.userList.map((user) => {
  //                 const playerUid = user.player?.uid;
  //                 const caddieUid = user.caddie?.uid;

  //                 const playerScore = scoreDetailsArray.find(
  //                   (details) =>
  //                     details.userId === playerUid ||
  //                     details.caddieId === caddieUid
  //                 );

  //                 const status = playerScore ? playerScore.status : undefined;

  //                 return {
  //                   ...user,
  //                   player: { ...user.player, status },
  //                   caddie: { ...user.caddie, status },
  //                 };
  //               });

  //               const isRoundStatus = roundData.userList.some(
  //                 (user) =>
  //                   (user.player?.uid === userData.uid &&
  //                     user.player !== null &&
  //                     (user.player?.status === "finished" ||
  //                       user.player?.status === "unfinished")) ||
  //                   (user.caddie?.uid === userData.uid &&
  //                     user.caddie !== null &&
  //                     (user.caddie?.status === "finished" ||
  //                       user.caddie?.status === "unfinished"))
  //               );

  //               if (
  //                 isRoundStatus &&
  //                 (roundData.teeTime === "now" ||
  //                   roundData.teeTime === "finished")
  //               ) {
  //                 dataRondFinished.push({
  //                   ...roundData,
  //                   roundId: roundId,
  //                 });
  //               }
  //             }
  //           }
  //         }
  //       });

  //       // Wait for all rounds to be processed before updating the state
  //       await Promise.all(roundPromises);

  //       setListRoundUpcoming(dataRondUpcoming);
  //       setListRoundFinsihed(dataRondFinished);
  //     });

  //     return () => unsubscribe(); // Clean up the subscription on unmount
  //   };

  //   if (userData) {
  //     fetchData();
  //   }
  // }, [userData]);

  // useEffect(() => {
  //   const unsubscribe = () => {}; // Placeholder for unsubscribe function.

  //   const fetchRoundActivity = () => {
  //     try {
  //       const roundActivityQuery = query(
  //         collection(firestore, "roundActivity"),
  //         where("userList", "array-contains", userData.uid),
  //         orderBy("created", "desc")
  //       );

  //       unsubscribe = onSnapshot(roundActivityQuery, (snapshot) => {
  //         const filteredRounds = snapshot.docs
  //           .map((doc) => ({ ...doc.data(), id: doc.id }))
  //           .filter((round) => round.creator !== userData.uid);

  //         setListRoundActivity(filteredRounds);
  //       });
  //     } catch (error) {
  //       console.error("Error setting up real-time listener:", error);
  //     }
  //   };

  //   if (userData?.uid) {
  //     fetchRoundActivity();
  //   }

  //   return () => unsubscribe();
  // }, [userData]);

  // useEffect(() => {
  //   const fetchRounds = () => {
  //     const rounds = listRoundActivity; // Array of round activities

  //     const fetchScoreDetails = async (uid, roundId) => {
  //       const userQuery = query(
  //         collection(firestore, "scoreCards"),
  //         where("userId", "==", uid),
  //         where("roundId", "==", roundId)
  //       );
  //       const caddieQuery = query(
  //         collection(firestore, "scoreCards"),
  //         where("caddieId", "==", uid),
  //         where("roundId", "==", roundId)
  //       );

  //       const [userSnapshot, caddieSnapshot] = await Promise.all([
  //         getDocs(userQuery),
  //         getDocs(caddieQuery),
  //       ]);

  //       let scoreDetails = {};

  //       userSnapshot.forEach((doc) => {
  //         scoreDetails = doc.data();
  //       });

  //       caddieSnapshot.forEach((doc) => {
  //         scoreDetails = doc.data();
  //       });

  //       return scoreDetails;
  //     };

  //     // Listen to Firestore updates for "round" collection
  //     const unsubscribe = onSnapshot(
  //       collection(firestore, "round"),
  //       async (snapshot) => {
  //         const uniqueRounds = new Map(); // Use a Map to track unique rounds

  //         await Promise.all(
  //           snapshot.docs.map(async (doc) => {
  //             const round = doc.data();
  //             const roundActivity = rounds.find(
  //               (activity) => activity.roundId === round.roundId
  //             );

  //             if (
  //               roundActivity &&
  //               round.teeTime !== "finished" &&
  //               round.status !== "deleted"
  //             ) {
  //               const playerUids = round.userList
  //                 .flatMap((user) => [user.player?.uid, user.caddie?.uid])
  //                 .filter((uid) => uid !== undefined);

  //               if (playerUids.length > 0) {
  //                 const scoreDetailsArray = await Promise.all(
  //                   playerUids.map((uid) =>
  //                     fetchScoreDetails(uid, round.roundId)
  //                   )
  //                 );

  //                 round.userList = round.userList.map((user) => {
  //                   const playerUid = user.player?.uid;
  //                   const caddieUid = user.caddie?.uid;

  //                   const playerScore = scoreDetailsArray.find(
  //                     (details) =>
  //                       details.userId === playerUid ||
  //                       details.caddieId === caddieUid
  //                   );

  //                   const status = playerScore ? playerScore.status : undefined;

  //                   return {
  //                     ...user,
  //                     player: { ...user.player, status },
  //                     caddie: { ...user.caddie, status },
  //                   };
  //                 });

  //                 const isRoundStatus = round.userList.some(
  //                   (user) =>
  //                     (user.player?.uid === userData.uid &&
  //                       (user.player?.status === "playing" ||
  //                         user.player?.status === undefined)) ||
  //                     (user.caddie?.uid === userData.uid &&
  //                       (user.caddie?.status === "playing" ||
  //                         user.caddie?.status === undefined))
  //                 );

  //                 const isPlayerMatched = round.userList.some(
  //                   (user) =>
  //                     user.player?.uid === userData.uid &&
  //                     user.player?.isJoin === false
  //                 );

  //                 const isCaddieMatched = round.userList.some(
  //                   (user) =>
  //                     user.caddie?.uid === userData.uid &&
  //                     user.caddie?.playerConfirm === true &&
  //                     user.caddie?.isJoin === false
  //                 );

  //                 if (isRoundStatus && (isPlayerMatched || isCaddieMatched)) {
  //                   uniqueRounds.set(round.roundId, { ...round, id: doc.id });
  //                 }
  //               }
  //             }
  //           })
  //         );

  //         // Convert Map to array and update state
  //         setListRoundRequest(Array.from(uniqueRounds.values()));
  //       },
  //       (error) => {
  //         console.error("Error listening to rounds:", error);
  //       }
  //     );

  //     return () => unsubscribe(); // Clean up listener on component unmount
  //   };

  //   fetchRounds();
  // }, [listRoundActivity, userData]);

  useEffect(() => {
    if (!userData) return;
    getDataUpcomingRound(userData, setListRoundUpcoming,setLoading);
    getDataFinshedRound(userData, setListRoundFinsihed,setLoading);
    getDataRequestRound(userData, setListRoundRequest,setLoading);
    
    if (userData.role === "player") {
      getDataIsPlayer(userData, setListFriendActivity);
    } else {
      getDataIsCaddie(userData, setListFriendActivity);
    }
  }, [userData]);
  // console.log(listRoundRequest)
  // console.log(listFriendActivity.length);
  return (
    <>
      {/* Card for selecting sections */}
      <Card className="border-0 shadow-xss rounded-xxl mb-1 mt-2">
        <Card.Body className="main-player-stat">
          <section className="d-none d-sm-flex gap-2 d-flex flex-wrap mb-2">
            {/* Row 1 */}

            {/* <div className="w-100 d-flex gap-2"> */}
            <div className="w-100 d-flex flex-md-nowrap flex-wrap gap-2">
              {/* First Section: Upcoming Round */}
              <div
                className="d-flex align-items-center p-2 rounded-3"
                style={{
                  backgroundColor: "#F2CFA4",
                  boxShadow:
                    activeSectionRound === "upcoming"
                      ? "0 0 0 2px #E59E49"
                      : "none",
                  cursor: "pointer",
                  minHeight: "80px", // ปรับให้ใกล้เคียงกับขนาดในรูป
                  width:"50%"
                }}
                onClick={handleUpcomingClick}
              >
                <strong
                  className="px-3 py-1 rounded-3"
                  style={{
                    backgroundColor: "#E59E49",
                    fontSize: "23px",
                    color: "#fff",
                  }}
                >
                  {listRoundUpcoming.length}
                </strong>
                <h5
                  className="mb-0 ms-3 fw-600"
                  style={{
                    color: "#E59E49",
                    fontSize: "14px",
                    width: "100%", // กำหนดความกว้างคงที่
                    whiteSpace: "nowrap", // บังคับให้ข้อความอยู่ในบรรทัดเดียว
                    overflow: "hidden", // ซ่อนข้อความที่เกินความกว้าง
                    textOverflow: "ellipsis",
                  }}
                >
                  {`Upcoming Round`}
                </h5>
              </div>

              {/* Second Section: Friend Request */}
              <div
                className="d-flex align-items-center p-2 rounded-3"
                style={{
                  backgroundColor: "#f9a9c6",
                  boxShadow:
                    activeSectionRound === "FriendRequest"
                      ? "0 0 0 2px #ee3d7f"
                      : "none",
                  cursor: "pointer",
                  minHeight: "80px",
                  width:"50%"
                }}
                onClick={handleFriendRequestClick}
              >
                <strong
                  className="px-3 py-1 rounded-3"
                  style={{
                    backgroundColor: "#ee3d7f",
                    fontSize: "23px",
                    color: "#fff",
                  }}
                >
                  {listRoundRequest.length}
                </strong>
                <h5
                  className="mb-0 ms-3 fw-600"
                  style={{
                    color: "#ee3d7f",
                    fontSize: "14px",
                    width: "100%", // กำหนดความกว้างคงที่
                    whiteSpace: "nowrap", // บังคับให้ข้อความอยู่ในบรรทัดเดียว
                    overflow: "hidden", // ซ่อนข้อความที่เกินความกว้าง
                    textOverflow: "ellipsis",
                  }}
                >
                  {`Round Request`}
                </h5>
              </div>
            </div>

            {/* Row 2 */}
            {/* <div className="w-100 d-flex gap-2"> */}
            <div className="w-100 d-flex flex-md-nowrap flex-wrap gap-2">
              {/* Third Section: Friend Activity */}
              <div
                className="d-flex align-items-center p-2 rounded-3"
                style={{
                  backgroundColor: "#A6B9F3",
                  boxShadow:
                    activeSectionRound === "FriendActivity"
                      ? "0 0 0 2px #4A74E5"
                      : "none",
                  cursor: "pointer",
                  minHeight: "80px",
                  width:"50%"

                }}
                onClick={handleFriendActivityClick}
              >
                <strong
                  className="px-3 py-1 rounded-3"
                  style={{
                    backgroundColor: "#4A74E5",
                    fontSize: "23px",
                    color: "#fff",
                  }}
                >
                  {listFriendActivity.length}
                </strong>
                <h5
                  className="mb-0 ms-3 fw-600"
                  style={{
                    color: "#4A74E5",
                    fontSize: "14px",
                    width: "100%", // กำหนดความกว้างคงที่
                    whiteSpace: "nowrap", // บังคับให้ข้อความอยู่ในบรรทัดเดียว
                    overflow: "hidden", // ซ่อนข้อความที่เกินความกว้าง
                    textOverflow: "ellipsis",
                  }}
                >
                  {`Friend Activity`}
                </h5>
              </div>

              {/* Fourth Section: Finished Round */}
              <div
                className="d-flex align-items-center p-2 rounded-3"
                style={{
                  backgroundColor: "#909294",
                  boxShadow:
                    activeSectionRound === "finished"
                      ? "0 0 0 2px #212529"
                      : "none",
                  cursor: "pointer",
                  minHeight: "80px",
                  width:"50%"
                }}
                onClick={handleFinishedClick}
              >
                <strong
                  className="px-3 py-1 rounded-3"
                  style={{
                    backgroundColor: "#212529",
                    fontSize: "23px",
                    color: "#fff",
                  }}
                >
                  {listRoundFinished.length}
                </strong>
                <h5
                  className="mb-0 ms-3 fw-600"
                  style={{
                    color: "#212529",
                    fontSize: "14px",
                    width: "100%", // กำหนดความกว้างคงที่
                    whiteSpace: "nowrap", // บังคับให้ข้อความอยู่ในบรรทัดเดียว
                    overflow: "hidden", // ซ่อนข้อความที่เกินความกว้าง
                    textOverflow: "ellipsis",
                  }}
                >
                  {`Finished Round`}
                </h5>
              </div>
            </div>
          </section>

          {/* mobile */}
          <section className="d-sm-none d-flex gap-2 d-flex flex-wrap mb-2">
            {/* Row 1 */}

            <div className="w-100 d-flex gap-2">
              {/* <div className="w-100 d-flex flex-md-nowrap flex-wrap gap-2"> */}
              {/* First Section: Upcoming Round */}
              <div
                className="d-flex align-items-center p-2 rounded-3"
                style={{
                  backgroundColor: "#F2CFA4",
                  boxShadow:
                    activeSectionRound === "upcoming"
                      ? "0 0 0 2px #E59E49"
                      : "none",
                  cursor: "pointer",
                  minHeight: "80px", // ปรับให้ใกล้เคียงกับขนาดในรูป
                  width:"50%"

                }}
                onClick={handleUpcomingClick}
              >
                <strong
                  className="px-3 py-1 rounded-3"
                  style={{
                    backgroundColor: "#E59E49",
                    fontSize: "18px",
                    color: "#fff",
                  }}
                >
                  {listRoundUpcoming.length}
                </strong>
                <h5
                  className="mb-0 ms-3 fw-600"
                  style={{ color: "#E59E49", fontSize: "14px" }}
                >
                  {`Upcoming Round`}
                </h5>
              </div>
              {/* Second Section: Friend Request */}
              <div
                className="d-flex align-items-center p-2 rounded-3"
                style={{
                  backgroundColor: "#f9a9c6",
                  boxShadow:
                    activeSectionRound === "FriendRequest"
                      ? "0 0 0 2px #ee3d7f"
                      : "none",
                  cursor: "pointer",
                  minHeight: "80px",
                  width:"50%"
                }}
                onClick={handleFriendRequestClick}
              >
                <strong
                  className="px-3 py-1 rounded-3"
                  style={{
                    backgroundColor: "#ee3d7f",
                    fontSize: "18px",
                    color: "#fff",
                  }}
                >
                  {listRoundRequest.length}
                </strong>
                <h5
                  className="mb-0 ms-3 fw-600"
                  style={{ color: "#ee3d7f", fontSize: "14px" }}
                >
                  {`Round Request`}
                </h5>
              </div>
            </div>

            {/* Row 2 */}
            <div className="w-100 d-flex gap-2">
              {/* <div className="w-100 d-flex flex-md-nowrap flex-wrap gap-2"> */}

              {/* Third Section: Friend Activity */}
              <div
                className="d-flex align-items-center p-2 rounded-3"
                style={{
                  backgroundColor: "#A6B9F3",
                  boxShadow:
                    activeSectionRound === "FriendActivity"
                      ? "0 0 0 2px #4A74E5"
                      : "none",
                  cursor: "pointer",
                  minHeight: "80px",
                  width:"50%"
                }}
                onClick={handleFriendActivityClick}
              >
                <strong
                  className="px-3 py-1 rounded-3"
                  style={{
                    backgroundColor: "#4A74E5",
                    fontSize: "18px",
                    color: "#fff",
                  }}
                >
                  {listFriendActivity.length}
                </strong>
                <h5
                  className="mb-0 ms-3 fw-600"
                  style={{ color: "#4A74E5", fontSize: "14px" }}
                >
                  {`Friend Activity`}
                </h5>
              </div>

              {/* Fourth Section: Finished Round */}
              <div
                className="d-flex align-items-center p-2 rounded-3"
                style={{
                  backgroundColor: "#909294",
                  boxShadow:
                    activeSectionRound === "finished"
                      ? "0 0 0 2px #212529"
                      : "none",
                  cursor: "pointer",
                  minHeight: "80px",
                  width:"50%"
                }}
                onClick={handleFinishedClick}
              >
                <strong
                  className="px-3 py-1 rounded-3"
                  style={{
                    backgroundColor: "#212529",
                    fontSize: "18px",
                    color: "#fff",
                  }}
                >
                  {listRoundFinished.length}
                </strong>
                <h5
                  className="mb-0 ms-3 fw-600"
                  style={{ color: "#212529", fontSize: "14px" }}
                >
                  {`Finished Round`}
                </h5>
              </div>
            </div>
          </section>
        </Card.Body>
      </Card>
    </>
  );
}

export default RoundsCount;
