import React from 'react'
import { Modal } from 'react-bootstrap'
import { useScore } from '../ScoreContext/ScoreContext'
import { useSelector } from 'react-redux';
import { doc, getDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { firestore } from '../../../components/firebase/FirebaseConfig';
import Swal from 'sweetalert2';

function Competitor({ isOpen, closeModal }) {
    const userData = useSelector((state) => state.user.data);
    const { userList, roundData } = useScore();

    const copiedUserList = () => {
        const sortedUserList = structuredClone(userList).sort((a, b) => {
            const aHasBoth = a.player && a.caddie;
            const bHasBoth = b.player && b.caddie;

            if (aHasBoth && !bHasBoth) {
                return -1; // 'a' comes before 'b'
            } else if (!aHasBoth && bHasBoth) {
                return 1; // 'b' comes before 'a'
            } else {
                return 0; // Keep original order if both have or neither has
            }
        });
        return sortedUserList
    }

    const handleSelectCompet = async (rivalUid) => {
        if (!rivalUid) {
            console.error('Not found player rivalUid', rivalUid);
            return;
        }

        try {
            Swal.showLoading()
            const roundDoc = doc(firestore, 'round', roundData.roundId);
            const roundSnapshot = await getDoc(roundDoc);
            if (roundSnapshot.exists()) {
                const roundFirestore = roundSnapshot.data();
                const userListFirestore = roundFirestore.userList;
                const foundUser = userListFirestore.find(item => (item.player && item.player.uid === userData.uid) || (item.caddie && item.caddie.uid === userData.uid));
                if (foundUser) {
                    if (foundUser.player.rival) {
                        if (foundUser.player.rival === rivalUid) {
                            foundUser.player.rival = null
                        } else {
                            foundUser.player.rival = rivalUid
                        }
                    } else {
                        foundUser.player.rival = rivalUid
                    }
                }
                await updateDoc(roundDoc, {
                    ...roundFirestore,
                    lastUpdate: Timestamp.now().seconds,
                    userList: userListFirestore
                });
                closeModal()
                Swal.close()
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: error
            })
            console.error('Fail to select competitor', error);
        }
    }

    return (
        <>
            <Modal show={isOpen} onHide={() => closeModal()} animation={false} centered>
                <Modal.Title>
                    <Modal.Header><h2 className='fw-600'>Choose Competitor</h2></Modal.Header>
                </Modal.Title>
                <Modal.Body>
                    {copiedUserList().map((user, idx) => {
                        const foundPlayer = copiedUserList().find(item => item?.player?.uid === userData.uid || item?.caddie?.uid === userData.uid);
                        // console.log(foundPlayer);
                        if (((user?.player?.uid === userData?.uid) || (user?.caddie?.uid === userData?.uid)) || (!user.player && !user.caddie)) {
                            return null
                        } else {
                            return (
                                <div key={idx} className='d-flex align-items-center justify-content-between align-items-center py-2 border-bottom'>
                                    <div className='d-flex align-items-center col-8'>
                                        <div className='d-flex align-items-center col-6'>
                                            {user.player ?
                                                <img src={user.player.profileImage} width={40} height={40} className='player-color-outline rounded-circle' alt="player" style={{ zIndex: 1 }} />
                                                :
                                                null
                                            }

                                            {user.caddie ?
                                                <img src={user.caddie.profileImage} width={40} height={40} className='caddie-color-outline rounded-circle' alt="caddie" />
                                                :
                                                null
                                            }
                                            {!user.player && !user.caddie ?
                                                <span className='fs-5 fw-600 text-black d-flex align-items-center justify-content-center' style={{ height: 40 }}>No Player</span>
                                                : null}
                                        </div>
                                        <div className='text-start px-2'>
                                            {user?.player?.isJoin ?
                                                <span className='fs-6 text-success'>Joined</span>
                                                :
                                                <span className='fs-6 text-muted'>Not joined</span>
                                            }
                                        </div>
                                    </div>
                                    {foundPlayer && user.player && foundPlayer?.player?.rival === user?.player?.uid ?
                                        <button className='btn col-4 btn-light h-100' onClick={() => handleSelectCompet(user?.player?.uid)}><span className='fw-600 fs-5'>Unselect</span></button>
                                        :
                                        <button className='btn col-4 bg-current' onClick={() => handleSelectCompet(user?.player?.uid)}><span className='fw-600 fs-5 text-white'>Select</span></button>
                                    }
                                </div>
                            )
                        }
                    }
                    )}
                    {/* <small className='text-muted'>You can click selected player again to remove selection.</small> */}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Competitor