export const en = {
    translation: {
        log_in: 'Log in',
        language: 'Language',
        Thai: 'Thai',
        English: 'English',
        Phone: 'Phone',
        Forgot_your_Password: 'Forgot your Password ?',
        Remember_me: 'Remember me',
        email: 'Email',
        password: 'Password',
        do_not_have_account: 'Do not have account ?',
        register: 'Register',
        what_on_your_mind: "What's on your mind ?",
        my_performance: 'Performance',
        golf_course: 'Golf Course',
        caddies: 'Caddies',
        chat: 'Chat',
        tournament: 'Tournament',
        store: 'Store',
        my_friends: 'Friends',
        my_activity: 'Activity',
        settings: 'Settings',
        privacy: 'Privacy',
        logout: 'Logout',
        about: 'About',
        images: 'Images',
        view_and_save: 'submit',
        save_and_exit: 'Save',
        intro: 'Profile Status',
        edit: 'Edit',
        cancel: 'Cancel',
        my_course: 'My Course',
    }
}
