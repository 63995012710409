import React, { useEffect, useState } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useScore } from '../ScoreContext/ScoreContext';
import useModalWithHistory from '../../../components/ModalPopState';
import Competitor from './Competitor';

function MatchPlayWarning() {

    const { roundData, playerGameMode, userList } = useScore();
    const userData = useSelector((state) => state.user.data);
    const { isModalOpen, openModal, closeModal } = useModalWithHistory();
    const [show, setShow] = useState(true);
    const [filteredUser, setFilteredUser] = useState([]);

    useEffect(() => {
        if (userList.length) {
            setFilteredUser(userList.filter((user) => user.player));
        }
    }, [userList])


    if (((playerGameMode?.gameMode ?? roundData?.gameMode) === 'Match Play') && filteredUser.length > 1) {
        const foundPlayer = userList.find(item => item?.player?.uid === userData?.uid || item?.caddie?.uid === userData?.uid);
        if (foundPlayer) {
            if (!foundPlayer.player.rival) {
                return (
                    <>
                        <ToastContainer
                            className="p-3"
                            position={'bottom-center'}
                            style={{ zIndex: 2 }}
                        >
                            <Toast show={show} onClose={() => setShow(false)} animation={true}>
                                <Toast.Header closeButton={true}>
                                    <strong className="me-auto">Match Play warning</strong>
                                </Toast.Header>
                                <Toast.Body className='bg-current text-white'>Please select your competitor. <span className='fw-600' onClick={openModal}>Here</span></Toast.Body>
                            </Toast>
                        </ToastContainer>
                        <Competitor
                            isOpen={isModalOpen}
                            closeModal={closeModal}
                        />
                    </>
                )
            }
        }
    }
}

export default MatchPlayWarning