export const th = {
  translation: {
    log_in: 'ล็อกอิน',
    language: 'ภาษา',
    Thai: 'ไทย',
    English: 'อังกฤษ',
    Phone: 'โทรศัพท์',
    Forgot_your_Password: 'ลืมรหัสผ่าน ?',
    Remember_me: 'จดจำฉัน',
    email: 'อีเมล',
    password: 'รหัสผ่าน',
    do_not_have_account: 'ยังไม่มีบัญชี ?',
    register: 'สมัคร',
    what_on_your_mind: 'คุณกำลังคิดอะไรอยู่ ?',
    my_performance: 'ผลงานของฉัน',
    golf_course: 'สนามกอล์ฟ',
    caddies: 'แคดดี้',
    chat: 'แชท',
    tournament: 'ทัวร์นาเมนต์',
    store: 'ร้านค้า',
    my_friends: 'เพื่อน',
    my_activity: 'กิจกรรม',
    settings: 'ตั้งค่า',
    privacy: 'ความเป็นส่วนตัว',
    logout: 'ออกจากระบบ',
    about: 'เกี่ยวกับ',
    images: 'รูปภาพ',
    view_and_save: 'บันทึก',
    save_and_exit: 'ตกลง',
    intro: 'แนะนำตัวเอง',
    edit: 'แก้ไข',
    cancel: 'ยกเลิก',
    my_course:'สนาม',
  }
}
