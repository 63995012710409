import React from 'react'
import {
    Table,
    Header,
    HeaderRow,
    Body,
    Row,
    HeaderCell,
    Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import LeaderboardTotalPar from './header/LeaderboardTotalPar';
import UserHead from './header/UserHead';
import HoleIndex from './HoleIndex';
import { useScore } from '../ScoreContext/ScoreContext';

import ScoreInputGross from './scoreInput/stroke/ScoreInputGross';
import ScoreInputGrossWHS from './scoreInput/stroke/ScoreInputGrossWHS';
import ScoreInputPar from './scoreInput/stroke/ScoreInputPar';
import ScoreInputParWHS from './scoreInput/stroke/ScoreInputParWHS';
import ScoreInputGrossStb from './scoreInput/stableford/ScoreInputGross';
import ScoreInputGrossStbWHS from './scoreInput/stableford/ScoreInputGrossWHS';
import ScoreInputGrossS36 from './scoreInput/stroke/ScoreInputGrossS36';
import ScoreInputParS36 from './scoreInput/stroke/ScoreInputParS36';
import ScoreInputGrossStbS36 from './scoreInput/stableford/ScoreInputGrossS36';

function ScoreTable({
    mappedScore,
    mappedUser,
}) {
    const { roundData, courseHoles, userList, playerGameMode, score } = useScore();

    const columnWidths = ["60px", ...Array(userList.length || 1).fill("150px")].join(" ");

    const theme = useTheme({
        Table: `
            --data-table-library_grid-template-columns: ${columnWidths} !important;
        `,
        HeaderRow: `
            .th {
              border: none;
              max-height: 180px;
              padding: 3px;
            }
            .th div {
              height: 100%;
            }
        `,
        Row: `
            .td {
              border: none;
              padding: 3px;
            }
            .td div {
              height: 100%;
            }
        `,
        BaseCell: `
            &:not(:last-of-type) {
              border: none;
            }
    
            &:nth-of-type(1) {
              left: 0px;
            }
        `,
    });
    if (courseHoles) {
        return (
            <div style={{ height: 'calc(100vh - 210px)' }}>

                <Table className='right-scroll-bar horizontal-scroll-bar' data={mappedScore} theme={theme} layout={{ custom: true, horizontalScroll: true, fixedHeader: true }}>
                    {(tableList) => (
                        <>
                            <Header>
                                <HeaderRow>
                                    <HeaderCell className='bg-white' pinLeft>
                                        <LeaderboardTotalPar roundData={roundData} courseHoles={courseHoles} />
                                    </HeaderCell>
                                    {mappedUser.map((user, idx) =>
                                        <HeaderCell className='bg-white shadow-sm rounded-3' key={idx}>
                                            <UserHead user={user} mappedUser={mappedUser} />
                                        </HeaderCell>
                                    )}
                                </HeaderRow>
                            </Header>

                            <Body>
                                {tableList.map((item, idx) => (
                                    <Row key={idx} item={item}>
                                        <Cell className='bg-white shadow-sm' pinLeft>
                                            <HoleIndex holeDetail={item.holeDetail} />
                                        </Cell>
                                        {item.scores.map((scores, idx) =>
                                            <Cell key={idx} className='text-black'>
                                                <div style={{ pointerEvents: score.find(item => item?.userId === scores?.userId && item?.status === "playing") ? 'auto' : 'none' }}>
                                                    {(() => {
                                                        const scoring = playerGameMode?.scoring ?? roundData?.scoring;
                                                        const gameMode = playerGameMode?.gameMode ?? roundData?.gameMode;
                                                        switch (scoring) {
                                                            case 'Gross':
                                                                switch (gameMode) {
                                                                    case 'Stroke Play':
                                                                    case 'Match Play':
                                                                        return <ScoreInputGross score={scores} />;

                                                                    case 'Stableford':
                                                                        return <ScoreInputGrossStb score={scores} />;

                                                                    default:
                                                                        return <ScoreInputGross score={scores} />;
                                                                }
                                                            case 'Gross-WHS':
                                                                switch (gameMode) {
                                                                    case 'Stroke Play':
                                                                    case 'Match Play':
                                                                        return <ScoreInputGrossWHS score={scores} />

                                                                    case 'Stableford':
                                                                        return <ScoreInputGrossStbWHS score={scores} />;

                                                                    default:
                                                                        return <ScoreInputGrossWHS score={scores} />;
                                                                }

                                                            case 'To Par':
                                                                return <ScoreInputPar score={scores} />

                                                            case 'To Par-WHS':
                                                                return <ScoreInputParWHS score={scores} />

                                                            case 'Gross-S36':
                                                                switch (gameMode) {
                                                                    case 'Stroke Play':
                                                                    case 'Match Play':
                                                                        return <ScoreInputGrossS36 score={scores} />;

                                                                    case 'Stableford':
                                                                        return <ScoreInputGrossStbS36 score={scores} />;

                                                                    default:
                                                                        return <ScoreInputGrossS36 score={scores} />;

                                                                }


                                                            case 'To Par-S36':
                                                                return <ScoreInputParS36 score={scores} />;

                                                            default:
                                                                console.error('Invalid Scoring', scoring);
                                                                console.log('used gross');
                                                                return <ScoreInputGross score={scores} />
                                                        }
                                                    })()}
                                                </div>
                                            </Cell>
                                        )}
                                    </Row>
                                ))}
                            </Body>
                        </>
                    )}
                </Table>
            </div>
        )
    } else {
        return null
    }
}

export default ScoreTable