import Header from '../../../../../components/Header'
import Leftnav from '../../../../../components/Leftnav'
import Rightchat from '../../../../../components/Rightchat'
import Popupchat from '../../../../../components/Popupchat'
import Appfooter from '../../../../../components/Appfooter'

import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CourseRender from '../module/CourseRender'
import CaddieReq from '../module/CaddieReq'
import { firestore } from '../../../../../components/firebase/FirebaseConfig'
import { where, query, collection, getDocs } from 'firebase/firestore'
import { useSelector } from 'react-redux'
import { ReactSVG } from 'react-svg'
import { confirmJoinCaddie } from '../module/confirmJoinCaddie'
import LoadCircle from '../../../../../components/LoadCircle'
import clsx from 'clsx'
import SelectPLayerModal from '../module/SelectPLayerModal'

const JoinRoundUnCaddie = ({ roundData }) => {
    const userData = useSelector((state) => state.user.data);

    const [userList, setUserList] = useState([]);
    const [userUids, setUserUids] = useState([]);
    const [yourPlayer, setYourPlayer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [playerModal, setPlayerModal] = useState(false);
    const [newPlayer, setNewPlayer] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!roundData) return;

        // deep copy roundData
        const clonedRounData = structuredClone(roundData);

        // set user List
        async function getUserData(userList) {

            const userUidList = getUids(userList);
            // Collect UIDs from both player and caddie fields
            setUserUids(userUidList);

            try {
                const userCollection = collection(firestore, 'users');
                const q = query(userCollection, where('uid', 'in', userUidList));
                const userSnapshot = await getDocs(q);

                if (!userSnapshot.empty) {
                    userSnapshot.forEach(doc => {
                        const firestoreUserData = doc.data();
                        const firestoreUid = firestoreUserData.uid;

                        userList.forEach(user => {
                            if (user.player && user.player.uid === firestoreUid) {
                                user.player = {
                                    ...user.player,
                                    ...firestoreUserData
                                };
                            }
                            if (user.caddie && user.caddie.uid === firestoreUid) {
                                user.caddie = {
                                    ...user.caddie,
                                    ...firestoreUserData
                                };
                            }
                        });
                    });
                }
                setUserList(userList);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setUserList(userList);
            }
        }

        getUserData(clonedRounData.userList);

    }, [roundData]);


    const handleAddCaddie = (selectedRow) => {
        const updatedList = userList.map(item => {
            // Check if there's already a caddie with the same UID and replace it with null
            if (item.caddie && item.caddie.uid === userData.uid) {
                return { ...item, caddie: null };
            }

            // Update the selected row with the new cadie data
            if (item.row === selectedRow) {
                return { ...item, caddie: userData };
            }

            return item; // No change for other items
        });
        // find your player
        const foundCaddie = updatedList.find(item => (item.caddie && item.caddie.uid === userData.uid) && item.caddie);
        if (foundCaddie) {
            setYourPlayer(foundCaddie.player);
        }

        // remove new added player when caddie change slot
        if (newPlayer) {
            const foundNewPlayer = updatedList.find(item => item.player && item.player.uid === newPlayer.uid);
            if (foundNewPlayer) {
                const filteredPlayer = userUids.filter(item => item !== newPlayer.uid);
                setUserUids(filteredPlayer);

                foundNewPlayer.player = null
                setNewPlayer(null);
            }
        }

        setUserUids(getUids(updatedList))

        setUserList(updatedList); // Update the state with the new list

    };

    const isDisable = () => {
        const foundCaddie = userList.find(item => item.caddie && item.caddie.uid === userData.uid);
        if (foundCaddie) {
            return false
        } else {
            return true
        }
    }

    const handleAddPlayer = () => {
        setPlayerModal(true);
    }
    const handleClosePlayerModal = () => {
        setPlayerModal(false);
    }

    // const handleSelectedPlayer = (player) => {
    //     const foundCaddie = userList.find(item => item.caddie && item.caddie.uid === userData.uid);
    //     if (foundCaddie) {
    //         foundCaddie.player = player;
    //         setNewPlayer(player)
    //         setYourPlayer(player);
    //         setUserUids(prev => [...prev, player.uid]);
    //     }
    // }

    const handleCancelPlayer = (player) => {
        setYourPlayer(null);
        const filteredPlayer = userUids.filter(item => item !== player.uid);
        setUserUids(filteredPlayer);

        const foundPlayer = userList.find(item => item.player && item.player.uid === player.uid);
        if (foundPlayer) {
            foundPlayer.player = null;
        }
        setNewPlayer(false);
    }
    // console.log(userList);
    // console.log(copiedRoundData);
    // console.log(roundData);

    function getUids(list) {
        const userUidList = []

        list.forEach(user => {
            if (user.player) {
                userUidList.push(user.player.uid);
            }
            if (user.caddie) {
                userUidList.push(user.caddie.uid);
            }
        });
        return userUidList
    }

    return (
        <>
            <Header />
            <Leftnav />
            <Rightchat />

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <Link to="/round-activity" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Join Round</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {/* course */}
                                            <CourseRender roundData={roundData} />
                                            <br />

                                            {/* select caddie */}
                                            <section>
                                                <h4 className='fw-600 mb-1'>Your player</h4>
                                                <div className='col-sm-6 col-12 p-3 bg-light theme-dark-bg rounded-3'>
                                                    {yourPlayer ?
                                                        <>
                                                            <div className='d-flex align-items-center gap-2'>
                                                                <img src={yourPlayer.profileImage} alt="player" width={50} height={50} className='rounded-circle player-color-outline' />
                                                                <h4 className='fw-600'>{`${yourPlayer.firstName} ${yourPlayer.lastName}`}</h4>
                                                            </div>
                                                            {newPlayer ?
                                                                <div className='mt-2 text-end'>
                                                                    <button className='btn bg-white theme-dark-bg'
                                                                        onClick={() => handleCancelPlayer(yourPlayer)}>
                                                                        <h4 className='fw-600 '>
                                                                            Remove
                                                                        </h4>
                                                                    </button>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </>
                                                        :
                                                        <div className={clsx('w-100 text-center', { 'd-none': isDisable() })}>
                                                            {/* <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addPlayer_p.svg'
                                                                wrapper='svg'
                                                                width={50}
                                                                height={50}
                                                                className='pointer'
                                                                onClick={() => handleAddPlayer()}
                                                            /> */}
                                                        </div>
                                                    }
                                                </div>
                                            </section>
                                            <br />

                                            {/* caddie req */}
                                            {/* <CaddieReq roundData={roundData} /> */}
                                            {/* <br /> */}

                                            {/* player and caddie in course */}
                                            <section>
                                                <h4 className='fw-600 mb-1'>{`Players and caddies in this round (${userUids.length}/${userList.length * 2})`}</h4>
                                                <div className='card bg-light py-1 px-2 border-0'>
                                                    {userList.map((user, idx) =>
                                                        <div key={idx} className='card-body bg-white theme-dark-bg my-1 d-flex flex-wrap'>
                                                            <div className='col-sm-6 col-12 p-2'>
                                                                {user.player ?
                                                                    <div className='d-flex align-items-center gap-2'>
                                                                        <div className='position-relative'>
                                                                            <img src={user.player.profileImage} alt="player" width={50} height={50} className='rounded-circle player-color-outline' />
                                                                            {user.player.isJoin ?
                                                                                <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-confirmJoin_g.svg'
                                                                                    wrapper='svg'
                                                                                    width={20}
                                                                                    height={20}
                                                                                    className='position-absolute'
                                                                                    style={{ top: 0, left: 0 }}
                                                                                />
                                                                                : null}
                                                                        </div>
                                                                        <div>
                                                                            <h4 className='fw-600'>{`${user.player.firstName} ${user.player.lastName}`}</h4>
                                                                            {userData.uid === user.player.uid ? <h6 className='fw-600 text-muted'>You</h6> : null}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className='bg-light theme-light-bg rounded-3'>
                                                                        <div className='w-100' style={{ height: 50 }}></div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className='col-sm-6 col-12 p-2'>
                                                                {user.caddie ?
                                                                    <div className='d-flex align-items-center gap-2'>
                                                                        <div className='position-relative'>
                                                                            <img src={user.caddie.profileImage} alt="caddie" width={50} height={50} className='rounded-circle caddie-color-outline' />
                                                                            {user.caddie.isJoin ?
                                                                                <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-confirmJoin_g.svg'
                                                                                    wrapper='svg'
                                                                                    width={20}
                                                                                    height={20}
                                                                                    className='position-absolute'
                                                                                    style={{ top: 0, left: 0 }}
                                                                                />
                                                                                : null}
                                                                        </div>
                                                                        <div>
                                                                            <h4 className='fw-600'>{`${user.caddie.firstName} ${user.caddie.lastName}`}</h4>
                                                                            {userData.uid === user.caddie.uid ? <h6 className='fw-600 text-muted'>You</h6> : null}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className='text-center theme-light-bg rounded-3'>
                                                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addCaddie_w.svg'
                                                                            wrapper='svg'
                                                                            width={50}
                                                                            height={50}
                                                                            className='pointer'
                                                                            onClick={() => handleAddCaddie(user.row)}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </section>
                                            <section className='d-flex gap-2 justify-content-end align-items-center mt-4'>
                                                <button className='btn bg-current'
                                                    disabled={isDisable()}
                                                    onClick={() => confirmJoinCaddie(roundData, userData, yourPlayer, setLoading, navigate, userList)}
                                                >
                                                    {loading ?
                                                        <LoadCircle size='sm' />
                                                        :
                                                        <h4 className='fw-600 text-white'>Join</h4>
                                                    }
                                                </button>
                                                <button className='btn bg-white theme-dark-bg' onClick={() => navigate('/round-activity')}>
                                                    <h4 className='fw-600'>Cancel</h4>
                                                </button>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />

            {/* <SelectPLayerModal
                isOpen={playerModal}
                handleClose={handleClosePlayerModal}
                selectedPlayer={(player) => handleSelectedPlayer(player)}
                inplayPlayer={userUids}
            /> */}
        </>
    )
}

export default JoinRoundUnCaddie