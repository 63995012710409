import React, { useState, useEffect, useRef, useMemo } from "react";
import { ReactSVG } from "react-svg";
import { ListGroup, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ChatTo } from "../../../components/chat/Chat";

const ModalPlayerCaddie = ({ isOpen, handleClose, listUserData }) => {
  const userData = useSelector((state) => state.user.data);
  const navigate = useNavigate();

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  const handleChatClick = async (dooNineId) => {
    const result = await ChatTo(userData.dooNineId, dooNineId);
    navigate("/chat-room?channelId=" + result[0].channel_url);
  };

  return (
    <>
      <Modal show={isOpen} onHide={handleClose} centered animation={false}>
        <Modal.Header>
          <Modal.Title className="d-flex justify-content-between align-items-center gap-4 w-100">
            <h2 className="fw-600 text-nowrap">Player & Caddie</h2>
            <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={handleClose}
      ></button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {listUserData.map((user, index) => (
              <ListGroup.Item
                key={index}
                className="sel-caddie-item pointer"
                // onClick={() => handleCaddieClick(caddie)}
                // style={{
                //   backgroundColor: inServiceCaddie.includes(caddie.uid)
                //     ? "rgba(155, 155, 155, 0.350)"
                //     : "",
                // }}
              >
                <div
                  className="d-flex align-items-center"
                  onClick={() => handleProfileClick(user.uid)}
                >
                  <div className="col-3">
                    {user.role === "player" ? (
                      <img
                        src={user.profileImage}
                        alt="player"
                        width={50}
                        height={50}
                        className="rounded-circle player-color-outline"
                      />
                    ) : (
                      <img
                        src={user.profileImage}
                        alt="caddie"
                        width={50}
                        height={50}
                        className="rounded-circle caddie-color-outline"
                      />
                    )}
                  </div>
                  <div className="col-7">
                    <h4 className="fw-600">
                      {`${user.firstName} ${user.lastName}`}
                    </h4>
                  </div>
                  <div className="col-2 d-flex justify-content-center">
                    <ReactSVG
                      src="assets/images/dooNineIconV2/chat-SL.svg"
                      wrapper="svg"
                      style={{ height: 40, width: 40 }}
                      onClick={(event) => {
                        event.stopPropagation(); // หยุดการส่งต่ออีเวนต์
                        handleChatClick(user.dooNineId);
                      }}
                    />
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ModalPlayerCaddie;
