import { Card } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  orderBy,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import { epochToDateTime } from "../module/convertTime";

function CardRoundFinish({ id }) {
  const [listRound, setListRound] = useState([]);
  const [loading, setLoading] = useState(true);
  const userData = useSelector((state) => state.user.data);
  const maxUserList = { desktop: 7, mobile: 5 };
  const navigate = useNavigate();
  console.log(id);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true); // Add a loading state

        const docRef = doc(firestore, "round", id);
        const docSnap = await getDoc(docRef);
        let fetchedUsers = {};
        let roundDataArray = [];
        let fetchedCourses = {};

        if (docSnap.exists()) {
          const roundData = docSnap.data();
          roundDataArray.push({ ...roundData, roundId: docSnap.id });

          const fetchUserDetails = async (uid) => {
            if (fetchedUsers[uid]) return fetchedUsers[uid]; // Use cached result

            const q = query(
              collection(firestore, "users"),
              where("uid", "==", uid)
            );
            const userSnapshot = await getDocs(q);
            let userDetails = {};
            userSnapshot.forEach((userDoc) => {
              userDetails = userDoc.data();
            });

            fetchedUsers[uid] = userDetails; // Cache the result
            return userDetails;
          };

          const fetchCourseDetails = async (courseId) => {
            if (fetchedCourses[courseId]) return fetchedCourses[courseId]; // Use cached result

            const courseQuery = query(
              collection(firestore, "courses"),
              where("id", "==", courseId)
            );
            const courseSnapshot = await getDocs(courseQuery);
            let courseData = {};
            courseSnapshot.forEach((doc) => {
              courseData = doc.data();
            });

            fetchedCourses[courseId] = courseData; // Cache the result
            return courseData;
          };

          const promises = roundDataArray.map(async (round) => {
            let holesCourse = "";

            // Make sure round.holesCourse is defined
            if (round.holesCourse) {
              const courseHolesSnapshot = await getDoc(
                doc(firestore, "courseHoles", round.holesCourse)
              );
              if (courseHolesSnapshot.exists()) {
                holesCourse = courseHolesSnapshot.data().courseHoleName;
              }
            }

            const { time, day, month } = epochToDateTime(round.scheduleTime);
            let userDataArray = [];

            // Check if round.userList exists
            if (round.userList && Array.isArray(round.userList)) {
              for (let userObj of round.userList) {
                const { caddie, player } = userObj;

                if (player?.uid) {
                  const playerData = await fetchUserDetails(player.uid);
                  userDataArray.push({
                    ...playerData,
                    isCreator: player.isCreator,
                    isJoin: player.isJoin,
                  });
                }

                if (caddie?.uid) {
                  const caddieData = await fetchUserDetails(caddie.uid);
                  userDataArray.push({
                    ...caddieData,
                    isCreator: caddie.isCreator,
                    isJoin: caddie.isJoin,
                  });
                }
              }
            }

            const courseData = await fetchCourseDetails(round.course);

            return {
              ...round,
              courseInfo: courseData,
              time,
              day,
              month,
              userData: userDataArray,
              holesCourse,
            };
          });

          const results = await Promise.all(promises);
          setListRound(results); // Update the state with the fetched data
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false); // Ensure loading state is updated
      }
    }

    if (userData) {
      fetchData();
    }
  }, [userData, id]);

  console.log(listRound);

  const handleLinkToCourse = (courseId) => {
    navigate(`/course-detail?courseId=${courseId}`);
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {listRound.length > 0 ? (
            listRound.map((round, index) => (
              <div key={index} className="create-round-req-main">
                <section className="create-round-req-head">
                  <div className="pointer">
                    <h4 className="fw-600">{round.roundName}</h4>
                    <br />
                    <h6 className="fw-600 text-muted">
                      {`${round.holes} (${round.holesCourse} ), ${round.gameMode}(${round.scoring})`}
                    </h6>
                  </div>
                  <div className="d-flex flex-wrap-reverse justify-content-end align-items-end">
                    <ReactSVG
                      src="assets/images/dooNineIconV2/chat-SL.svg"
                      wrapper="svg"
                      style={{ height: 40, width: 40 }}
                      className={clsx("pointer", {
                        "d-none": round.groupChat !== "create",
                      })}
                    />
                  </div>
                </section>
                <section
                      className="create-round-req-body p-2 mb-2 pointer"
                      //   onClick={() => handleChatandDateClick()}
                      style={{
                        backgroundImage: round.coverImage
                          ? typeof round.coverImage === "string"
                            ? `url(${round.coverImage})`
                            : `url(${URL.createObjectURL(round.coverImage)})`
                          : "none",
                        height: round.coverImage ? "" : "inherit",
                      }}
                    >
                      <div
                        className="req-course-data px-0 row"
                        style={{
                          backgroundColor: round.coverImage ? "" : "#fff",
                        }}
                      >
                        <div
                          className="col-sm-2 col-3 pointer p-0 d-flex justify-content-center align-items-center"
                          onClick={() => handleLinkToCourse(round.course)}
                        >
                          <img
                            src={round.courseInfo.profileImage}
                            alt="course-profile"
                            width={70}
                            height={70}
                            className="rounded-circle my-2 d-sm-block d-none"
                          />
                          {/* mobile */}
                          <img
                            src={round.courseInfo.profileImage}
                            alt="course-profile"
                            width={50}
                            height={50}
                            className="rounded-circle my-2 d-sm-none d-block"
                          />
                        </div>
                        <div
                          className="col-sm-8 col-6 pointer p-0 d-flex justify-content-center align-items-center"
                          onClick={() => handleLinkToCourse(round.course)}
                        >
                          <div>
                            {/* desktop */}
                            <span className="fw-600 fs-5 d-sm-block d-none">
                              {round.courseInfo.courseName}
                            </span>
                            {/* mobile */}
                            <span className="fw-600 fs-6 d-sm-none d-block">
                              {round.courseInfo.courseName}
                            </span>
                            {/* mobile */}
                            <span className="fw-600 fs-6 d-sm-none d-block">{`${round.courseInfo.location.provinceEng}`}</span>

                            <span className="fw-600 fs-5 d-sm-block d-none">{`${round.courseInfo.location.provinceEng}`}</span>
                          </div>
                        </div>
                        <div className="col-sm-2 col-3 px-0 px-sm-20">
                          <div
                            className="pointer rounded-3 h-100 d-flex justify-content-center align-items-center flex-column"
                            style={{ backgroundColor: "#212529" }}
                            // onClick={() => handleChatandDateClick()}
                          >
                            <h5 className="fw-600 text-white text-center">
                              {round.month}
                            </h5>
                            <h5 className="fw-700 text-white text-center">
                              {round.day}
                            </h5>
                            <div className="bg-white text-center w-100">
                              <span
                                className="fw-600 fs-6"
                                style={{ color: "#212529" }}
                              >
                                {round.time}
                              </span>
                            </div>
                            {/* {round.teeTime === "finished" ? (
                              <>
                                <h4 className="fw-600 text-white text-center">
                                  {round.month}
                                </h4>
                                <h1 className="fw-700 text-white text-center">
                                  {round.day}
                                </h1>
                                <div className="bg-white text-center w-100">
                                  <span
                                    className="fw-600 fs-4"
                                    style={{ color: "#212529" }}
                                  >
                                    {round.time}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <div className="bg-white text-center w-100">
                                <span
                                  className="fw-600 fs-4"
                                  style={{ color: "#212529" }}
                                >
                                  Start Now
                                </span>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </section>

                <section>
                  {/* desktop display 7 limit */}
                  <div className="d-none d-sm-flex align-items-center justify-content-between p-3 pointer">
                    {/* Left side: User profile images */}
                    <Link to={`/leader-board?round=${round.roundId}`}>
                      <div className="d-flex align-items-center">
                        {round.userData
                          .slice(0, maxUserList.desktop)
                          .map((user, idx) =>
                            user.isCreator ? (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={50}
                                    height={50}
                                    className="rounded-circle player-color-outline me-4"
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={50}
                                    height={50}
                                    className="rounded-circle caddie-color-outline me-4"
                                  />
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={50}
                                    height={50}
                                    className="rounded-circle player-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.desktop - idx,
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={50}
                                    height={50}
                                    className="rounded-circle caddie-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.desktop - idx,
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                        {round.userData.length > maxUserList.desktop ? (
                          <div
                            className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                            style={{
                              width: 50,
                              height: 50,
                              outline: "1px dashed grey",
                              opacity: 0.6,
                            }}
                          >
                            <h6 className="text-center">
                              +{round.userData.length - maxUserList.desktop}{" "}
                              more
                            </h6>
                          </div>
                        ) : null}
                      </div>
                    </Link>
                  </div>

                  {/* mobile display 5 limit */}
                  <div className="d-sm-none d-flex align-items-center justify-content-between p-3 pointer">
                    {/* Left side: User profile images */}
                    <Link to={`/leader-board?round=${round.roundId}`}>
                      <div className="d-flex align-items-center">
                        {round.userData
                          .slice(0, maxUserList.mobile)
                          .map((user, idx) =>
                            user.isCreator ? (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={40}
                                    height={40}
                                    className="rounded-circle player-color-outline me-4"
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={40}
                                    height={40}
                                    className="rounded-circle caddie-color-outline me-4"
                                  />
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={40}
                                    height={40}
                                    className="rounded-circle player-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.mobile - idx,
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={40}
                                    height={40}
                                    className="rounded-circle caddie-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.mobile - idx,
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                        {round.userData.length > maxUserList.mobile ? (
                          <div
                            className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                            style={{
                              width: 40,
                              height: 40,
                              outline: "1px dashed grey",
                            }}
                          >
                            <h6 className="text-center">
                              +{round.userData.length - maxUserList.mobile} more
                            </h6>
                          </div>
                        ) : null}
                      </div>
                    </Link>
                  </div>
                </section>
              </div>
            ))
          ) : (
            <p>No rounds available.</p>
          )}
        </>
      )}
    </>
  );
}

export default CardRoundFinish;
