import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useState, useEffect } from 'react'
import { Toast } from 'react-bootstrap'
import { firestore } from './firebase/FirebaseConfig';
import { useSelector } from 'react-redux';

const NoCourseToast = () => {
    const userData = useSelector((state) => state.user.data);
    const [show, setShow] = useState(false);
    const currentPath = window.location.pathname;

    const showToast = () => setShow(true);
    const closeToast = () => setShow(false);

    useEffect(() => {
        async function getCaddieReq() {
            const caddieReqCollection = collection(firestore, 'caddieRequests');
            const q = query(caddieReqCollection, where('caddieId', '==', userData.uid), where('status', '!=', 'removed'));
            const reqSnapshot = await getDocs(q);
            if (reqSnapshot.empty) {
                if (currentPath !== '/caddie-req') {
                    showToast();
                }
            } else {
                closeToast();
            }
        }
        if (userData && userData.role === 'caddie') {
            getCaddieReq();
        }
    }, [currentPath, userData])


    return (
        <>
            {/* desktop */}
            <div className='d-none d-sm-block'>
                <Toast show={show} onClose={closeToast} className='position-fixed bg-warning' style={{ bottom: 40, right: 0, zIndex: 3 }}>
                    <Toast.Header className='p-3'>
                        <h1 className="fw-600 me-auto">Caddie Warning</h1>
                    </Toast.Header>
                    <Toast.Body>
                        <div className='w-100 h-100 text-center'>
                            <h3 className='fw-600'>You need to choose a course to access full feature.</h3>
                            <br />
                            <h3 className='fw-600'>กรุณาเลือกสนามที่คุณสังกัดอยู่เพื่อการใช้งานแอพพลิเคชันได้อย่างเด็มรูปแบบ</h3>
                            <br />
                            <button className='btn btn-light p-3 border-2'>
                                <h4 className='fw-600'><a href="/caddie-req">ไปหน้าเลือกสนาม</a></h4>
                            </button>
                        </div>
                    </Toast.Body>
                </Toast>
            </div>
            {/* mobile */}
            <div className='d-sm-none d-block'>
                <Toast show={show} onClose={closeToast} className='position-fixed bg-warning' style={{ top: 80, right: 0, zIndex: 3, width: '100vw', height: '50vh' }}>
                    <Toast.Header className='p-3'>
                        <h1 className="fw-600 me-auto">Caddie Warning</h1>
                    </Toast.Header>
                    <Toast.Body style={{ height: 'calc(100% - 60px)' }}>
                        <div className='w-100 h-100 text-center d-flex flex-column justify-content-center'>
                            <h3 className='fw-600'>You need to choose a course to access full feature.</h3>
                            <br />
                            <h3 className='fw-600'>กรุณาเลือกสนามที่คุณสังกัดอยู่เพื่อการใช้งานแอพพลิเคชันได้อย่างเด็มรูปแบบ</h3>
                            <br />
                            <br />
                            <button className='btn btn-light p-3 border-2'>
                                <h4 className='fw-600'><a href="/caddie-req">ไปหน้าเลือกสนาม</a></h4>
                            </button>
                        </div>
                    </Toast.Body>
                </Toast>
            </div>
        </>
    )
}

export default NoCourseToast