import React, { Fragment, useState, useEffect, useRef } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useSelector } from "react-redux";
import axios from "axios";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";

import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import App from '@sendbird/uikit-react/App'


import GroupChannel from "@sendbird/uikit-react/GroupChannel";
import MessageContent from "@sendbird/uikit-react/ui/MessageContent";
import { MessageMenu, MenuItem } from "@sendbird/uikit-react/ui/MessageMenu";
import { Message } from "@sendbird/uikit-react/GroupChannel/components/Message";
import "@sendbird/uikit-react/dist/index.css";

import { ReactSVG } from "react-svg";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

import FileViewer from "@sendbird/uikit-react/ui/FileViewer";
import ChatRoomMember from "./component/ChatRoomMember";
import ChatRoomGroupSetting from "./component/ChatRoomGroupSetting";
import ChatRoomInvite from "./component/ChatRoomInvite";

const backChat = "assets/images/message/Chat-Back.svg";
const friend = "assets/images/message/Chat-Friends.svg";
const addFriend = "assets/images/message/Char-Add-Friend.svg";
const menu = "assets/images/message/Chat-Menu.svg";

const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;

function ChatRoom() {
  const userData = useSelector((state) => state.user.data);
  const [channelId, setChannelId] = useState();
  const navigate = useNavigate();

  const [members, setMembers] = useState([]);
  const [isFriend, setIsFriend] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dataProfile, setDataProfile] = useState();

  const [channelData, setChannelData] = useState(null);

  const [groupMemberData, setGroupMemberData] = useState(null);
  const [show, setShow] = useState(false);
  const [isOperator, setIsOperator] = useState(false);

  const [showGroupSettingModal, setShowGroupSettingModal] = useState(false);
  const [showGroupInviteModal, setShowGroupInviteModal] = useState(false);

  const [isDirectChannel, setIsDirectChannel] = useState(false);
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);

  const showOrigin = false;

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const channelId_ = params.get("channelId");
    setChannelId(channelId_);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}/members`,
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );

        let member = response.data.members.filter(
          (user) => user.user_id !== userData?.dooNineId
        );
        setMembers(member);

        const q = query(
          collection(firestore, "users"),
          where("dooNineId", "==", member[0].user_id)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          let isFriend = false;
          setDataProfile(doc.data());
          const q3 = query(
            collection(firestore, "friends"),
            where("friendId", "==", doc.data().uid),
            where("userId", "==", userData?.uid)
          );

          const q2 = query(
            collection(firestore, "friends"),
            where("friendId", "==", userData?.uid),
            where("userId", "==", doc.data().uid)
          );

          const querySnapshot2 = await getDocs(q2);
          querySnapshot2.forEach(async (doc2) => {
            if (doc2.data().status == "active") {
              isFriend = true;
            }
          });

          const querySnapshot3 = await getDocs(q3);
          querySnapshot3.forEach(async (doc3) => {
            if (doc3.data().status == "active") {
              isFriend = true;
            }
          });

          if (isFriend) {
            // console.log("isFriend");
            setIsFriend(true);
          } else {
            // console.log("notFriend");
            setIsFriend(false);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    if (userData && channelId) {
      fetchData();
    }
  }, [channelId, userData]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}?&show_member=true`,
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );
        setChannelData(response.data);

        console.log(response.data);

        const isDirectChannel =
          response.data?.member_count === 2 && response.data?.is_distinct;

        console.log(isDirectChannel);

        setIsDirectChannel(isDirectChannel);

        const data = isDirectChannel
          ? response.data?.members.find(
            (member) => member.user_id !== userData?.dooNineId
          )
          : null;
        setData(data);

        const data2 = !isDirectChannel
          ? response.data?.members.find(
            (member) => member.user_id == userData?.dooNineId
          )
          : null;
        setData2(data2);
      } catch (error) {
        console.log(error);
      }
    }
    if (channelId) {
      fetchData();
    }
  }, [channelId]);

  const handleShowMember = async () => {
    try {
      setShow(true);
      const response = await axios.get(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}/members`,
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
          },
        }
      );

      const members = response.data.members;
      const operators = members.filter((member) => member.role == "operator");

      let array = [];
      for (let index = 0; index < response.data.members.length; index++) {
        const element = response.data.members[index];

        const q = query(
          collection(firestore, "users"),
          where("dooNineId", "==", element.user_id)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          array.push({
            stateSendbird: element.state,
            roleSendbird: element.role,
            ...doc.data(),
          });
        });
      }
      array.sort((a, b) => {
        const roleOrder = { player: 1, caddie: 2 };

        // Prioritize operator roles first
        if (a.roleSendbird === "operator" && b.roleSendbird !== "operator")
          return -1;
        if (a.roleSendbird !== "operator" && b.roleSendbird === "operator")
          return 1;

        // If both are or are not operators, sort by role
        return roleOrder[a.role] - roleOrder[b.role];
      });
      setGroupMemberData(array);

      const user = array.find((user) => user.uid === userData.uid);
      setIsOperator(user.roleSendbird == "operator");
    } catch (error) {
      console.log(error);
    }
  };

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  const handleCloseModal = () => {
    setGroupMemberData(null);
    setShow(false);
  };

  const colorSet = {
    "--sendbird-light-primary-500": "#ee3d7f",
    "--sendbird-light-primary-400": "#f16398",
    "--sendbird-light-primary-300": "#ee3d7f",
    "--sendbird-light-primary-200": "#f8b1cb",
    "--sendbird-light-primary-100": "#fbd8e5",
  };

  const handleDeleteChat = async (friendData) => {
    Swal.fire({
      title: "Do you want to delete this chat?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}`,

            {
              headers: {
                "Content-Type": "application/json; charset=utf8",
                "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
              },
            }
          );

          Swal.fire({
            title: "Delete Channel Success ",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(async (result) => {
            navigate("/chat");
          });
        } catch (error) {
          console.error("Error leaving channel:", error);
        }
      }
    });
  };

  const handleLeaveChat = async () => {
    Swal.fire({
      title: "Do you want to leave this chat?",
      icon: "warning",
      confirmButtonColor: "#ee3d7f",
      confirmButtonText: "OK",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // messege
        const response2 = await axios.post(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}/messages`,
          {
            message_type: "MESG",
            user_id: userData.dooNineId,
            message: `${userData.firstName} ${userData.lastName} leave this group chat.`,
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": sendBird_api_token,
            },
          }
        );

        const response = await axios.put(
          "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/" +
          channelId +
          "/leave",
          {
            user_ids: [userData.dooNineId],
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );
        if (response.status == 200) {
          Swal.fire({
            icon: "success",
            title: "Leave group success",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(async (result) => {
            navigate("/chat");
          });
        } else {
          console.log(response);
          Swal.fire({
            icon: "error",
            title: "Leave group failed",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const handleShowGroupSetting = () => {
    setShowGroupSettingModal(true);
  };

  const handleShowInvite = () => {
    setShowGroupInviteModal(true);
  };

  console.log(isDirectChannel);
  console.log(data);
  console.log(data2);

  return (
    <>
      <style>
        {`

@media screen and (min-width: 1600px) {
      .sendbird-fileviewer{
        height: 70dvh;
        width: 60dvw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
}

@media screen and (max-width: 1599px) and (min-width: 950px) {
      .sendbird-fileviewer{
        height: 70dvh;
        width: 50dvw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
}

@media screen and (max-width: 949px) {
      .sendbird-fileviewer{
        height: 70dvh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
}
      .sendbird-fileviewer{
        border-radius: 10px;
      }

      .chat-container{
      // height:calc(100vh - 56px - 80px - 65px);
        height : 100%;

      }
      .sendbird-message-content__middle__body-container{
          max-width: 100%;
      }
      .main-chat{
        height: calc(100dvh - 56px);
      }
      .sendbird-bottomsheet__content{
        margin-bottom: 56px;
      }
      `}
      </style>

      <Fragment>
        <Header />
        <Leftnav />
        <Rightchat />
        <SendbirdProvider
          appId={"B72515CE-E0DA-417F-AF02-4BF3D07B3D50"}
          userId={userData?.dooNineId}
          accessToken={userData?.sendBirdAccessToken}
          breakpoint="480px"
          // theme="dark"
          colorSet={colorSet}
        >
          <div className="main-content main-chat right-chat-active">
            <div className="middle-sidebar-bottom h-100 px-1">
              <div className="middle-sidebar-left pe-0 h-100">
                <div className="chat-container">
                  <GroupChannel
                    channelUrl={channelId}
                    renderChannelHeader={() => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "5px",
                            borderStartEndRadius: "16px",
                            borderStartStartRadius: "16px",
                            marginTop: "5px",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <ReactSVG
                              src={backChat}
                              style={{
                                width: "40px",
                                height: "30px",
                                cursor: "pointer",
                              }}
                              onClick={() => navigate("/chat")}
                            />
                            <p
                              style={{
                                // fontWeight: "bold",
                                marginBottom: 0,
                                marginLeft: "10px",
                                fontSize: "18px",
                                fontWeight: "600",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "-0.2px",
                                fontFamily:
                                  "var(--sendbird-font-family-default)",
                                width: "40vw",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {/* {channelData?.member_count > 2
                                ? channelData?.name +
                                  "  (" +
                                  channelData?.member_count +
                                  ")"
                                : members[0]?.nickname} */}

                              {isDirectChannel
                                ? data.nickname || ''
                                : `${channelData?.name || ''} (${channelData?.member_count || ''})`}
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p style={{ marginBottom: 0, marginRight: "10px" }}>
                              {!isDirectChannel ? null : isFriend ? (
                                <ReactSVG
                                  src={friend}
                                  style={{ width: "35px" }}
                                />
                              ) : (
                                <ReactSVG
                                  src={addFriend}
                                  style={{ width: "30px" }}
                                />
                              )}
                            </p>
                            <div>
                              <ReactSVG
                                src={menu}
                                style={{ width: "35px", cursor: "pointer" }}
                                onClick={toggleDropdown}
                              />

                              {dropdownOpen && (
                                <Dropdown
                                  className="drodownFriend"
                                  drop="down-centered"
                                  show={dropdownOpen}
                                  onToggle={toggleDropdown}
                                >
                                  <Dropdown.Toggle
                                    as="i"
                                    id="dropdown-basic"
                                    style={{
                                      cursor: "pointer",
                                      zIndex: 1000,
                                    }}
                                  ></Dropdown.Toggle>

                                  <Dropdown.Menu
                                    className="dropdownChat"
                                    as={CustomDropdown}
                                    popperConfig={{
                                      modifiers: [
                                        {
                                          name: "flip",
                                          options: {
                                            altBoundary: true,
                                            rootBoundary: "viewport",
                                            padding: 8,
                                          },
                                        },
                                        {
                                          name: "preventOverflow",
                                          options: {
                                            altAxis: true,
                                            altBoundary: true,
                                            tether: false,
                                          },
                                        },
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [0, 10],
                                          },
                                        },
                                      ],
                                    }}
                                    style={{ zIndex: 1050 }}
                                  >
                                    {!isDirectChannel ? (
                                      <>
                                        <Dropdown.Item
                                          style={{ display: "flex" }}
                                          onClick={() => handleShowInvite()}
                                        >
                                          Invite
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          style={{ display: "flex" }}
                                          onClick={() => handleShowMember()}
                                        >
                                          Members
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          style={{ display: "flex" }}
                                          onClick={() =>
                                            handleShowGroupSetting()
                                          }
                                        >
                                          Setting
                                        </Dropdown.Item>
                                      </>
                                    ) : (
                                      <Dropdown.Item
                                        style={{ display: "flex" }}
                                        onClick={() =>
                                          handleProfileClick(dataProfile.uid)
                                        }
                                      >
                                        Profile
                                      </Dropdown.Item>
                                    )}

                                    <Dropdown.Item
                                      style={{ display: "flex" }}
                                      onClick={() => handleLeaveChat()}
                                    >
                                      Leave Chat
                                    </Dropdown.Item>

                                    {/* <Dropdown.Item
                                      style={{ display: "flex" }}
                                      onClick={() =>
                                        handleDeleteChat(dataProfile)
                                      }
                                    >
                                      Delete Chat
                                    </Dropdown.Item> */}

                                    {/* {channelData?.joined_member_count >
                                    2 ? null : (
                                      <>
                                        <hr className="verticalHeader" />
                                        <Dropdown.Item
                                          style={{ display: "flex" }}
                                          disabled
                                        >
                                          Block
                                        </Dropdown.Item>
                                      </>
                                    )} */}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr className="verticalHeader" />
                      </>
                    )}
                    renderMessage={(props) => <CustomMessage {...props} />}
                    disableUserProfile={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <ChatRoomMember
            show={show}
            handleCloseModal={handleCloseModal}
            groupMemberData={groupMemberData}
            handleShowMember={handleShowMember}
            isOperator={isOperator}
            setShowGroupInviteModal={setShowGroupInviteModal}
          />

          <ChatRoomGroupSetting
            showGroupSettingModal={showGroupSettingModal}
            setShowGroupSettingModal={setShowGroupSettingModal}
          />

          <ChatRoomInvite
            showGroupInviteModal={showGroupInviteModal}
            setShowGroupInviteModal={setShowGroupInviteModal}
          />
        </SendbirdProvider>
        <Popupchat />
        <Appfooter />
      </Fragment>
    </>
  );
}

export default ChatRoom;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}

const CustomMessage = (props) => {
  return <Message {...props} />;
};
