import React, { useEffect, useRef, useState } from 'react'
import { useWizard } from './wizardContext'
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';
import { epochToDateTime } from './module/covertTime';
import { useSelector } from 'react-redux';
import { holesDef, gameModeDef } from './module/holeDef';
import { firestore } from '../../../../components/firebase/FirebaseConfig';
import { collection, doc, getDoc, } from 'firebase/firestore';

const ReqPreview = () => {
  const { stepData, currentStepIndex } = useWizard();
  const userData = useSelector((state) => state.user.data);

  const data1 = stepData.stepOne;
  const data2 = stepData.stepTwo;
  const data3 = stepData.stepThree;

  const { time, day, month } = epochToDateTime(data3.scheduleTime);

  const [cleansingUser, setCleansingUser] = useState([]);

  const maxUserList = { desktop: 7, mobile: 5 };

  const [courseHolesName, setCourseHolesName] = useState("");

  // console.log('step 1', data1)
  // console.log('step 2', data2.row)
  // console.log('step 3', data3)

  useEffect(() => {
    async function getCourseHolesData() {
      try {
        const courseHolesCollection = collection(firestore, 'courseHoles');
        const q = doc(courseHolesCollection, data1.holesCourse);
        const courseHolesSnapshot = await getDoc(q);
        if (courseHolesSnapshot.exists) {
          setCourseHolesName(courseHolesSnapshot.data().courseHoleName);
        }
      } catch (error) {
        console.error('Fail to get Course Holes data', error);

      }
    }
    if (data1.holesCourse) {
      getCourseHolesData()
    }
  }, [data1.holesCourse])


  useEffect(() => {
    const cleansingUserList = [];
    let creatorUser = null;

    if (data2.row.length) {

      data2.row.forEach(user => {

        if (user.player) {
          if (user.player.uid === userData.uid) {
            creatorUser = { ...userData, isCreator: true };
          } else {
            cleansingUserList.push(user.player);
          }
        }

        if (user.caddie) {
          if (user.caddie.uid === userData.uid) {
            creatorUser = { ...userData, isCreator: true };
          } else {
            cleansingUserList.push(user.caddie);
          }
        }

      });

    } else {
      creatorUser = { ...userData, isCreator: true };
    }

    // Prepend creatorUser (if found) to the start of the list
    setCleansingUser(creatorUser ? [creatorUser, ...cleansingUserList] : cleansingUserList);

  }, [data2, userData]);

  const handleUserListClick = () => {
    currentStepIndex(2)
  }

  const handleCourseClick = () => {
    currentStepIndex(1);
  }

  const handleChatandDateClick = () => {
    currentStepIndex(3)
  }


  return (
    <div className='create-round-req-main' style={{border:"1px solid #e1e1f0 "}}>
      <section className='create-round-req-head'>
        <div className='pointer' onClick={() => handleCourseClick()}>
          <h4 className='fw-600 mb-1'>{data1.roundName}</h4>
          <h6 className='fw-600 text-muted'>{`${holesDef(data1.holes)} (${courseHolesName}), ${gameModeDef(data1.gameMode)}(${data1.scoring})`}</h6>
        </div>
        <div className='d-flex flex-wrap-reverse justify-content-end align-items-end'>
          <ReactSVG src='assets/images/dooNineIconV2/chat-SL.svg' wrapper='svg' style={{ height: 40, width: 40 }} className={clsx('pointer', { 'd-none': data3.groupChatBtn !== 'create' })} onClick={() => handleChatandDateClick()} />
          <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-menu.svg' wrapper='svg' className='wizrd-more-btn' />
        </div>
      </section>
      <section className='create-round-req-body p-1 pointer' onClick={() => handleChatandDateClick()}
        style={{ backgroundImage: data3.imageFile ? `url(${URL.createObjectURL(data3.imageFile)})` : `url(${data3.imageUrl ? data3.imageUrl : 'none'})`, height: data3.imageFile || data3.imageUrl ? '' : 'inherit' }}>
        <div className='req-course-data px-0 row' style={{ backgroundColor: data3.imageFile || data3.imageUrl ? '' : '#fff' }}>
          <div className='col-sm-2 col-3 pointer p-0 d-flex justify-content-center align-items-center' onClick={(e) => { handleCourseClick(); e.stopPropagation() }}>
            {/* desktop */}
            <img src={data1?.course?.profileImage} alt="course-profile" width={70} height={70} className='rounded-circle my-2 d-sm-block d-none' />
            {/* mobile */}
            <img src={data1?.course?.profileImage} alt="course-profile" width={50} height={50} className='rounded-circle my-2 d-sm-none d-block' />
          </div>
          <div className='col-sm-8 col-6 pointer p-0 d-flex justify-content-center align-items-center' onClick={(e) => { handleCourseClick(); e.stopPropagation() }}>
            <div>
              {/* desktop */}
              <span className='fw-600 fs-4 d-sm-block d-none'>{data1?.course?.courseName}</span>
              {/* mobile */}
              <span className='fw-600 fs-5 d-sm-none d-block'>{data1?.course?.courseName}</span>

              <span className='fw-600 fs-6 d-sm-block d-none'>{`${data1?.course?.location?.districtEng}, ${data1?.course?.location?.provinceEng}`}</span>
            </div>
          </div>
          <div className='col-sm-2 col-3 px-sm-20'>
            <div className='pointer rounded-3 h-100 d-flex justify-content-center align-items-center flex-column' style={{ backgroundColor: '#E59E49' }} onClick={() => handleChatandDateClick()}>
              {data3.teeTimeBtn === 'schedule' ?
                <>
                  {/* desktop */}
                  <div className='d-sm-block d-none w-100'>
                    <h5 className='fw-600 text-white text-center'>{month}</h5>
                    <h5 className='fw-700 text-white text-center'>{day}</h5>
                    <div className='bg-white text-center w-100'>
                      <span className='fw-600 fs-5' style={{ color: '#E59E49' }}>{time}</span>
                    </div>
                  </div>
                  {/* mobile */}
                  <div className='d-sm-none d-block w-100'>
                    <h5 className='fw-600 text-white text-center'>{month}</h5>
                    <h5 className='fw-700 text-white text-center'>{day}</h5>
                    <div className='bg-white text-center w-100'>
                      <span className='fw-600 fs-5' style={{ color: '#E59E49' }}>{time}</span>
                    </div>
                  </div>
                </>
                :
                <div className='bg-white text-center px-2'>
                  <span className='fw-600 fs-4' style={{ color: '#E59E49' }}>Start Now</span>
                </div>
              }
            </div>
          </div>
        </div>
      </section>

      <section>
        {/* desktop display 7 limit */}
        <div className='d-none d-sm-flex align-items-center pointer' style={{padding:"0.6rem 1rem 0.7rem"}} onClick={() => handleUserListClick()}>
          {cleansingUser.slice(0, maxUserList.desktop).map((user, idx) =>
            user.isCreator ?
              <React.Fragment key={idx}>
                {user.role === 'player' ?
                  <img src={user.profileImage} alt="player" width={50} height={50} className='rounded-circle player-color-outline me-4' />
                  :
                  <img src={user.profileImage} alt="caddie" width={50} height={50} className='rounded-circle caddie-color-outline me-4' />
                }
              </React.Fragment>
              :
              <React.Fragment key={idx}>
                {user.role === 'player' ?
                  <img src={user.profileImage} alt="player" width={50} height={50} className='rounded-circle player-color-outline' style={{ marginLeft: '-10px', zIndex: maxUserList.desktop - idx }} />
                  :
                  <img src={user.profileImage} alt="caddie" width={50} height={50} className='rounded-circle caddie-color-outline' style={{ marginLeft: '-10px', zIndex: maxUserList.desktop - idx }} />
                }
              </React.Fragment>
          )}
          {cleansingUser.length > maxUserList.desktop ?
            <div className='d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1' style={{ width: 50, height: 50, outline: '1px dashed grey', opacity: 0.6 }}>
              <h6 className='text-center'>+{cleansingUser.length - maxUserList.desktop} more</h6>
            </div>
            :
            null
          }
        </div>
        {/* desktop display 7 limit */}

        {/* ------------------------------------------------------------------------------ */}

        {/* mobile display 5 limit */}
        <div className='d-sm-none d-flex align-items-center pointer' style={{padding:"0.5rem 1rem 0.6rem"}} onClick={() => handleUserListClick()}>
          {cleansingUser.slice(0, maxUserList.mobile).map((user, idx) =>
            user.isCreator ?
              <React.Fragment key={idx}>
                {user.role === 'player' ?
                  <img src={user.profileImage} alt="player" width={40} height={40} className='rounded-circle player-color-outline me-4' />
                  :
                  <img src={user.profileImage} alt="caddie" width={40} height={40} className='rounded-circle caddie-color-outline me-4' />
                }
              </React.Fragment>
              :
              <React.Fragment key={idx}>
                {user.role === 'player' ?
                  <img src={user.profileImage} alt="player" width={40} height={40} className='rounded-circle player-color-outline' style={{ marginLeft: '-10px', zIndex: maxUserList.mobile - idx }} />
                  :
                  <img src={user.profileImage} alt="caddie" width={40} height={40} className='rounded-circle caddie-color-outline' style={{ marginLeft: '-10px', zIndex: maxUserList.mobile - idx }} />
                }
              </React.Fragment>
          )}
          {cleansingUser.length > maxUserList.mobile ?
            <div className='d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1' style={{ width: 40, height: 40, outline: '1px dashed grey' }}>
              <h6 className='text-center'>+{cleansingUser.length - maxUserList.mobile} more</h6>
            </div>
            :
            null
          }
        </div>
        {/* mobile display 5 limit */}
      </section >
    </div >
  )
}

export default ReqPreview

