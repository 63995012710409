import React, { useEffect, useState } from 'react'
import styles from '../../scorestyle.module.scss'
import { ReactSVG } from 'react-svg'
import { useScore } from '../../ScoreContext/ScoreContext'

const RenderRow3Comp = ({ score, gir }) => {
    const { userList } = useScore();
    const [filteredUser, setFilteredUser] = useState([]);

    useEffect(() => {
        if (userList.length) {
            setFilteredUser(userList.filter((user) => user.player));
        }
    }, [userList])


    return (
        <div className={`${styles.scorecardrow3} d-flex justify-content-evenly align-items-center`}>
            {/* GIR */}
            {gir &&
                <>
                    <ReactSVG src='assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_g.svg' wrapper='svg' width={20} height={20} />
                </>
            }

            {/* Fairway */}
            <>
                {score?.holeScore?.fairwayHit !== "-" ? (
                    score?.holeScore?.fairwayHit === "center" ? (
                        <ReactSVG
                            src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_up.svg"
                            wrapper="svg"
                            width={20}
                            height={20}
                        />
                    ) : score?.holeScore?.fairwayHit === "left" ? (
                        <ReactSVG
                            src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_left.svg"
                            wrapper="svg"
                            width={20}
                            height={20}
                        />
                    ) : score?.holeScore?.fairwayHit === "right" ? (
                        <ReactSVG
                            src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_right.svg"
                            wrapper="svg"
                            width={20}
                            height={20}
                        />
                    ) : (
                        <EmptyScore />
                    )
                ) : (
                    <EmptyScore />
                )}
            </>

            {/* Bunker */}
            {score?.holeScore?.bunkerHit && score?.holeScore?.bunkerHit !== "-" ?
                <ReactSVG src='assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_b.svg' wrapper='svg' width={20} height={20} />
                :
                <EmptyScore />
            }

            {/* Penalties */}
            {score?.holeScore?.penalties && score?.holeScore?.penalties !== "-" &&
                    <span className='fw-600 fs-2 text-center' style={{ color: '#C00000', lineHeight: 'normal', minWidth: 20 }}>{score?.holeScore?.penalties || "-"}</span>
            }

            {/* Match Play trop (not use in stroke) */}
            {filteredUser.length > 1 &&
                <>
                    {score?.holeScore?.winner ?
                        <ReactSVG src='assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_cup.svg' wrapper='svg' width={20} height={20} />
                        :
                        <EmptyScore />
                    }
                </>
            }

        </div>
    )
}

export default RenderRow3Comp

const EmptyScore = () => {
    return (
        <div style={{ width: 25, height: 25 }} className='text-center' ></div>
    )
}