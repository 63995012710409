import React, { useRef, useState, useEffect } from "react";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function CourseDetailHeader({
  courseData,
  section,
  setSection,
  favoriteCourse,
  trigger,
  setTrigger,
}) {
  const userData = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  const [listRoundStart, setListRoundStart] = useState([]);

  const handleFavoriteCourse = async (courseId, isCourseFavorited) => {
    const timestampInSecond = Math.round(new Date().getTime() / 1000);

    const favoriteCourseRef = collection(firestore, "favoriteCourses");
    const querySnapshot = await getDocs(
      query(
        favoriteCourseRef,
        where("userId", "==", userData.uid),
        where("courseId", "==", courseId)
      )
    );

    if (querySnapshot.empty) {
      // If no document exists, add a new one
      const docRef = await addDoc(favoriteCourseRef, {
        userId: userData.uid,
        status: "active",
        timestamp: timestampInSecond,
        courseId: courseId,
      });
    } else {
      // If document exists, update it
      querySnapshot.forEach(async (doc) => {
        await updateDoc(doc.ref, {
          status: isCourseFavorited ? "inactive" : "active",
          timestamp: timestampInSecond,
        });
      });
    }
    setTrigger(!trigger);
  };

  // Get data round start
  useEffect(() => {
    const fetchRoundData = async () => {
      try {
        const roundQuery = query(collection(firestore, "round"));
        const roundSnapshot = await getDocs(roundQuery);

        let roundDataArray = [];

        const fetchScoreDetails = async (uid, roundId) => {
          const userQuery = query(
            collection(firestore, "scoreCards"),
            where("userId", "==", uid),
            where("roundId", "==", roundId)
          );
          const caddieQuery = query(
            collection(firestore, "scoreCards"),
            where("caddieId", "==", uid),
            where("roundId", "==", roundId)
          );

          const [userSnapshot, caddieSnapshot] = await Promise.all([
            getDocs(userQuery),
            getDocs(caddieQuery),
          ]);

          let scoreDetails = {};

          userSnapshot.forEach((doc) => {
            scoreDetails = doc.data();
          });

          caddieSnapshot.forEach((doc) => {
            scoreDetails = doc.data();
          });

          return scoreDetails;
        };

        const processRound = async (roundDoc) => {
          const roundData = roundDoc.data();
          const isCreator = roundData.creator === userData?.uid;
          const isUserInList =
            Array.isArray(roundData.userList) &&
            roundData.userList.some(
              (user) =>
                (user.player?.uid === userData?.uid && user.player?.isJoin) ||
                (user.caddie?.uid === userData?.uid && user.caddie?.isJoin)
            );

          if (isCreator || isUserInList) {
            const playerUids = (roundData.userList || [])
              .flatMap((user) => [user.player?.uid, user.caddie?.uid])
              .filter(Boolean);

            if (playerUids.length > 0) {
              const scoreDetailsArray = await Promise.all(
                playerUids.map((uid) => fetchScoreDetails(uid, roundDoc.id))
              );

              roundData.userList = roundData.userList.map((user) => {
                const playerUid = user.player?.uid;
                const caddieUid = user.caddie?.uid;

                const playerScore = scoreDetailsArray.find(
                  (details) =>
                    details?.userId === playerUid ||
                    details?.caddieId === caddieUid
                );

                const status = playerScore?.status || undefined;

                return {
                  ...user,
                  player: { ...user.player, status },
                  caddie: { ...user.caddie, status },
                };
              });

              const isRoundStatus = roundData.userList.some(
                (user) =>
                  (user.player?.uid === userData?.uid &&
                    (user.player?.status === "playing" ||
                      !user.player?.status)) ||
                  (user.caddie?.uid === userData?.uid &&
                    (user.caddie?.status === "playing" || !user.caddie?.status))
              );

              if (
                isRoundStatus &&
                roundData.teeTime === "now" &&
                roundData.status !== "deleted"
              ) {
                roundDataArray.push({
                  ...roundData,
                  roundId: roundDoc.id,
                });
              }
            }
          }
        };

        await Promise.all(roundSnapshot.docs.map(processRound));

        setListRoundStart(roundDataArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchRoundData();
  }, [userData]);

  const handleStartRoundClick = () => {
    if (listRoundStart.length > 0) {
      navigate("/create-round", { state: { ...courseData, schedule: true } });
    } else {
      navigate("/create-round", { state: courseData });
    }
  };
  // console.log(courseData)
  return (
    <>
      <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
        <div
          className="card-body h250 p-0 rounded-xxl overflow-hidden m-3"
          style={{ width: "auto", height: "250px" }}
        >
          <img
            src={courseData?.coverImage}
            alt="avatar"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>

        <div className="card-body p-0 position-relative">
          <figure
            className="avatar position-absolute w90 z-index-1"
            style={{ top: "-40px", left: "30px" }}
          >
            <img
              src={courseData?.profileImage}
              alt="avatar"
              className="float-right p-1 rounded-circle w-100"
              style={{
                backgroundColor: "#81D77A",
                objectFit: "cover",
              }}
            />
          </figure>

          <div className="d-none d-sm-flex flex-column">
            {/* ชื่อคอร์สในบรรทัดแรก */}
            <h4 className="fw-700 font-sm mt-1 pl-15">
              {courseData?.courseName}
            </h4>

            {/* ข้อความตำแหน่งและไอคอนในบรรทัดเดียวกัน */}
            <div className="d-flex align-items-center mb-2">
              <span className="fw-500 font-xssss text-grey-500 me-2 pl-15 ">
                {`${courseData?.location?.districtEng}, ${courseData?.location?.provinceEng}`}
              </span>
              <span
                onClick={() =>
                  handleFavoriteCourse(courseData.courseId, favoriteCourse)
                }
              >
                {favoriteCourse ? (
                  <i
                    className="btn-round-sm font-lg bi bi-bookmark-fill pointer"
                    style={{
                      color: "#E59E49",
                    }}
                  ></i>
                ) : (
                  <i
                    className="btn-round-sm font-md bi bi-bookmark pointer"
                    style={{
                      color: "#B0B5BD",
                    }}
                  ></i>
                )}
              </span>
            </div>
          </div>

          <div className="d-sm-none d-flex flex-column">
            {/* ชื่อคอร์สในบรรทัดแรก */}
            <h5 className="fw-700 fs-4 mt-1" style={{paddingLeft:"127px",marginRight:"1rem"}}>
              {courseData?.courseName}
            </h5>

            {/* ข้อความตำแหน่งและไอคอนในบรรทัดเดียวกัน */}
            <div className="d-flex align-items-center mb-2">
              <span className="fw-500 font-xssss text-grey-500" style={{paddingLeft:"127px"}}>
                {`${courseData?.location?.districtEng}, ${courseData?.location?.provinceEng}`}
              </span>
              <span
                onClick={() =>
                  handleFavoriteCourse(courseData.courseId, favoriteCourse)
                }
              >
                {favoriteCourse ? (
                  <i
                    className="btn-round-sm font-lg bi bi-bookmark-fill pointer"
                    style={{
                      color: "#E59E49",
                    }}
                  ></i>
                ) : (
                  <i
                    className="btn-round-sm font-md bi bi-bookmark pointer"
                    style={{
                      color: "#B0B5BD",
                    }}
                  ></i>
                )}
              </span>
            </div>
          </div>

          {/* <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-2 pl-15 text-start">
            {courseData?.courseName}
            <span className="fw-500 font-xssss text-grey-500 d-block">
              {`${courseData?.location?.districtEng}, ${courseData?.location?.provinceEng}`}
              <span
                onClick={() =>
                  handleFavoriteCourse(courseData.courseId, favoriteCourse)
                }
              >
                {favoriteCourse ? (
                  <i
                    className="btn-round-sm font-lg bi bi-bookmark-fill pointer"
                    style={{
                      color: "#E59E49",
                    }}
                  ></i>
                ) : (
                  <i
                    className="btn-round-sm font-md bi bi-bookmark pointer"
                    style={{
                      color: "#B0B5BD",
                    }}
                  ></i>
                )}
              </span>
            </span>
          </h4> */}

          <div className="d-flex align-items-center justify-content-start position-absolute-md right-15 top-0 me-3 ms-3 mb-3">
            {/* por 020924 */}

            {/* <button
              className="btn bg-success p-3 z-index-1 rounded-3 text-white font-xsssss fw-700 ls-3 d-block w-100"
              disabled
            >
              Start Round
            </button> */}

            {/* <button
              className="btn player-color p-3 z-index-1 rounded-3 text-white font-xssss fw-700 ls-3 d-block w-100"
              disabled
            >
              Start Round
            </button> */}
            <button
              className="btn text-white p-2 px-3 golfCourse-color"
              onClick={() => handleStartRoundClick()}
            >
              Start Round
            </button>

            {/* <button
              id="dropdownMenu4"
              className="btn bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              disabled
            >
              <i className="ti-more font-md tetx-dark"></i>
            </button> */}

            {/* por 020924 */}

            <div
              className="dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg"
              aria-labelledby="dropdownMenu4"
            >
              <div className="card-body p-0 d-flex">
                <i className="feather-bookmark text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                  Save Link{" "}
                  <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                    Add this to your saved items
                  </span>
                </h4>
              </div>
              <div className="card-body p-0 d-flex mt-2">
                <i className="feather-alert-circle text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                  Hide Post{" "}
                  <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                    Save to your saved items
                  </span>
                </h4>
              </div>
              <div className="card-body p-0 d-flex mt-2">
                <i className="feather-alert-octagon text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                  Hide all from Group{" "}
                  <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                    Save to your saved items
                  </span>
                </h4>
              </div>
              <div className="card-body p-0 d-flex mt-2">
                <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-0">
                  Unfollow Group{" "}
                  <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                    Save to your saved items
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
          <ul
            className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4"
            id="pills-tab"
            role="tablist"
          >
            <li className="active list-inline-item me-5">
              <a
                className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                  section === "about" ? "active" : ""
                }`}
                onClick={() => setSection("about")}
                style={{
                  cursor: "pointer",
                }}
              >
                About
              </a>
            </li>
            <li className="list-inline-item me-5">
              <a
                className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                  section === "course" ? "active" : ""
                }`}
                onClick={() => setSection("course")}
                style={{
                  cursor: "pointer",
                }}
              >
                Course
              </a>
            </li>
            <li className="list-inline-item me-5">
              <a
                className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${
                  section === "caddie" ? "active" : ""
                }`}
                onClick={() => setSection("caddie")}
                style={{
                  cursor: "pointer",
                }}
              >
                Caddie
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default CourseDetailHeader;
